import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Challenge } from "../../../../models/challenge.model";
import { FloatingCloseButton } from "../../../../components/FloatingCloseButton/FloatingCloseButton";
import { LoadingWrapper } from "../../../../components/LoadingWrapper/LoadingWrapper";
import { ChallengeData } from "./ChallengeData";
import {
    pageTransitions,
    pageVariants,
} from "../../../../animations/transitions";
import { motion } from "framer-motion";
// import { ModalEditName } from '../../../components/ModalEditName/ModalEditName'

export type Props = {
    loading: boolean;
    challenge: Challenge;
    goToProgress: () => void;
    goToParticipants: () => void;
    handleEdit: () => void;
    entryBioData: () => void;
    handleDelete: () => void;
};

export const EditorOptions = ({
    loading,
    challenge,
    goToProgress,
    goToParticipants,
    handleEdit,
    entryBioData,
    handleDelete,
}: Props) => {
    return (
        <motion.div
            initial="initial"
            exit="out"
            animate="in"
            transition={pageTransitions}
            variants={pageVariants}
        >
            <FloatingCloseButton />
            <LoadingWrapper isLoading={loading} type="fullscreen">
                <Grid container direction="column" className={"mt-2"}>
                    <ChallengeData challenge={challenge} />

                    <Grid
                        item
                        className={"mt-2"}
                        container
                        spacing={2}
                        direction="column"
                    >
                        <Grid item>
                            <Button
                                fullWidth
                                className="secondary-button"
                                onClick={goToProgress}
                            >
                                PROGRESS
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                fullWidth
                                className="secondary-button"
                                onClick={goToParticipants}
                            >
                                SEE PARTICIPANTS
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                fullWidth
                                className="main-button"
                                onClick={entryBioData}
                            >
                                ENTRY DATA
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                fullWidth
                                className="main-button"
                                onClick={handleEdit}
                            >
                                EDIT
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                fullWidth
                                className="secondary-button"
                                onClick={handleDelete}
                            >
                                DELETE CHALLENGE
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </LoadingWrapper>
        </motion.div>
    );
};
