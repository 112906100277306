import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Challenge } from "../../../../models/challenge.model";
import { NavigationBar } from "../../../../components/NavigationBar/NavigationBar";

interface Props {
    challenge: Challenge;
}

export const ChallengeData = ({ challenge }: Props) => {
    const getChallengeAmount = () => {
        let quantity = Number(challenge.quantity.toFixed(2));
        let challengeAmount = Number(quantity.toFixed(2)).toString();
        let pending = Number(challenge.pendingAmount.toFixed(2));
        let interval = challenge.interval;
        let unit = "";
        if (challenge.isCooperative) {
            switch (challenge.challengeActivity.id) {
                case "stop-smoking":
                case "stop-drinking":
                case "vegan":
                case "vegetarian":
                case "keto":
                case "carnivor":
                    challengeAmount = `${pending * -1} ${
                        pending === 1 ? "day" : "days"
                    } so far (whole team)`;
                    break;
                default:
                    challengeAmount = `${Number(
                        (quantity - pending).toFixed(2)
                    )} of ${quantity}`;
                    break;
            }
        }

        if (challenge.unit !== "none" && challenge.unit) unit = challenge.unit;

        return `${challengeAmount} ${unit} ${interval}`;
    };
    return (
        <>
            <NavigationBar title={challenge.name} showBackButton />
            <Grid item>
                <Typography variant={"caption"}>Name</Typography>
                <Grid item container direction="row" justify={"space-between"}>
                    <Grid item>
                        <Typography variant="body2" className={"ml-1"}>
                            {challenge.name}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={"mt-2"}>
                <Typography variant={"caption"}>Category</Typography>
                <Typography variant="body2" className={"ml-1"}>
                    {challenge?.challengeActivity?.name}
                </Typography>
            </Grid>
            <Grid item className={"mt-2"}>
                <Typography variant={"caption"}>Challenge</Typography>
                <Typography variant="body2" className={"ml-1"}>
                    {getChallengeAmount()}
                </Typography>
            </Grid>
            <Grid item className={"mt-2"}>
                <Typography variant={"caption"}>Duration</Typography>
                <Typography variant="body2" className={"ml-1"}>
                    {challenge.getChallangeTime(true)}
                </Typography>
                <Typography variant="body2" className={"ml-1"}>
                    {challenge.getEndStartRelativeTime(true)}
                </Typography>
            </Grid>
        </>
    );
};
