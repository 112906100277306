import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(  {
    root:{

    },
    logo: {
        height: '109px',
        
    }, 
    phrase: {
        marginTop: '24px',
        padding: '24px',
    }    
} );
