import { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import {
    RootState,
    useAppDispatch,
    useAppSelector,
} from "../redux/store/store";
import { LoginScreen } from "../Screens/LoginScreen/LoginScreen";
import { RegisterScreen } from "../Screens/RegisterScreen/RegisterScreen";
import { LoadingScreen } from "../Screens/LoadingScreen/LoadingScreen";
import { DashboardScreen } from "../Screens/DashboardScreen/DashboardScreen";
import { OnboardingScreen } from "../Screens/OnboardingScreen/OnboardingScreen";
import { Logout } from "../components/Logout/Logout";
import { ProfileScreen } from "../Screens/ProfileScreen/ProfileScreen";
import { ProfileEditScreen } from "../Screens/ProfileEditScreen/ProfileEditScreen";
import { MyCommunityScreen } from "../Screens/Community/MyCommunityScreen/MyCommunityScreen";
import { NewPostScreen } from "../Screens/Community/NewPostScreen/NewPostScreen";
import { ViewPostScreen } from "../Screens/Community/ViewPostScreen/ViewPostScreen";
import { NewChallengeScreen } from "../Screens/Challenges/NewChallengeScreen/NewChallengeScreen";
import { NewChallengeInvite } from "../Screens/Challenges/NewChallengeScreen/components/NewChallengeInvite";
import { ChallengeScreen } from "../Screens/Challenges/ChallengeScreen/ChallengeScreen";
import { ChallengeParticipantsScreen } from "../Screens/Challenges/ChallengeParticipantsScreen/ChallengeParticipantsScreen";
import { MyFriendsScreen } from "../Screens/Community/MyFriendsScreen/MyFriendsScreen";
import { NotificationsScreen } from "../Screens/NotificationsScreen/NotificationsScreen";
import { Notification } from "../models/notification.model";
import { ChallengeListScreen } from "../Screens/Challenges/ChallengeListScreen/ChallengeListScreen";
import { TasksScreen } from "../Screens/TasksScreen/TasksScreen";
import { ChallengeProgressScreen } from "../Screens/Challenges/ChallengeProgressScreen/ChallengeProgressScreen";
import { AchievementsScreen } from "../Screens/AchievementsScreen/AchievementsScreen";
import { MenuBar } from "../components/MenuBar/MenuBar";
import { routes } from "./routes";
import { onAuthStateChanged } from "firebase/auth";
import { DatabaseReference, off, onValue, ref } from "firebase/database";
import { auth, rtdb } from "../apis/firebase.config";
import { setUser, useSignInQuery } from "../redux/slices/auth/auth.slice";
import { addNotifications } from "../redux/slices/notifications/notifications.slice";
import { setWaitingForAuth } from "../redux/slices/ui/ui.slice";
import { modal } from "../components/modal/modal";
import { User } from "../models/user.model";
import { Journal } from "../Screens/Journal/Journal";
import { JournalNewEntry } from "../Screens/Journal/JournalNewEntry";
import { Stopwatch } from "../Screens/Stopwatch/Stopwatch";
import { CoopChallengesScreen } from "../Screens/Challenges/CoopChallengesScreen/CoopChallengesScreen";
import { CoopChallengeProgressScreen } from "../Screens/Challenges/CoopChallengeProgressScreen/CoopChallengeProgressScreen";
import { AddActivityScreen } from "../Screens/AddActivityScreen/AddActivityScreen";
import { ActivityScreen } from "../Screens/ActivityScreen/ActivityScreen";
import { ChooseBiodataType } from "../Screens/Biodata/ChooseBiodataType";
import { EntryBiodata } from "../Screens/Biodata/EntryBiodata";
import { ListBiodata } from "../Screens/Biodata/ListBiodata";
import { NotificationsController } from "../controllers/notifications.controller";

export const AppRouter = () => {
    const dispatch = useAppDispatch();
    const [firebasetoken, setFirebaseToken] = useState<string>("");
    const {
        isLoading: isFetchingUser,
        data: fetchedUser,
        isError,
        error,
    } = useSignInQuery({ firebasetoken }, { skip: !firebasetoken });

    const waitingForAuth = useAppSelector(
        (state: RootState) => state.ui.waitingForAuth
    );

    useEffect(() => {
        if (isFetchingUser) return;
        if (isError) {
            dispatch(setWaitingForAuth(false));
            modal(
                "error",
                "",
                "Not able to connect to the server. Please try again later."
            );
        }
        dispatch(setUser(new User(fetchedUser)));
        dispatch(setWaitingForAuth(false));
    }, [isFetchingUser, fetchedUser, isError, error, dispatch]);

    useEffect(() => {
        const notificationsRef: DatabaseReference = null;
        dispatch(setWaitingForAuth(true));
        onAuthStateChanged(auth, async (user) => {
            if (user?.uid) {
                const fbToken = await user.getIdToken();
                setFirebaseToken(fbToken);

                const notificationsRef = ref(rtdb, `/users/${user?.uid}`);

                notificationsRef && off(notificationsRef); // if it was already subscribe then unsubscribe
                onValue(notificationsRef, (snapshot) => {
                    const data: {
                        notifications: { [key: string]: Notification };
                    } = snapshot.val();
                    let notifications: Notification[] = [];

                    if (!data?.notifications)
                        return dispatch(addNotifications([]));

                    Object.values(data.notifications).forEach(
                        (n: Notification) => {
                            notifications.push(new Notification(n));
                        }
                    );
                    NotificationsController.handleNewNotifications(notifications)
                });
            } else {
                dispatch(setWaitingForAuth(false));
            }
        });

        return () => {
            console.log("Cleaning router...");
            notificationsRef && off(notificationsRef);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (waitingForAuth) {
        return <LoadingScreen />;
    }
    console.log("re rendering router");

    return (
        <Router>
            <AnimatePresence>
                <Switch>
                    <PrivateRoute
                        path={routes.ONBOARDING}
                        component={OnboardingScreen}
                    />
                    <PublicRoute
                        exact
                        path="/register"
                        component={RegisterScreen}
                    />
                    <PublicRoute exact path="/login" component={LoginScreen} />
                    <PrivateRoute path="/lo" component={Logout} />

                    <PrivateRoute
                        exact
                        path="/challenges/new/:step"
                        component={NewChallengeScreen}
                    />
                    <PrivateRoute
                        exact
                        path="/challenges/new"
                        component={NewChallengeScreen}
                    />

                    <PrivateRoute
                        exact
                        path="/challenge/invite-participants"
                        component={NewChallengeInvite}
                    />
                    <PrivateRoute
                        exact
                        path="/challenge/participants"
                        component={ChallengeParticipantsScreen}
                    />
                    <PrivateRoute
                        exact
                        path="/challenge/:id/participants"
                        component={ChallengeParticipantsScreen}
                    />
                    <PrivateRoute
                        exact
                        path="/challenge/:id/progress"
                        component={ChallengeProgressScreen}
                    />
                    <PrivateRoute
                        exact
                        path="/challenge/:id/coop-progress"
                        component={CoopChallengeProgressScreen}
                    />
                    <PrivateRoute
                        exact
                        path="/challenge/:id"
                        component={ChallengeScreen}
                    />

                    <PrivateRoute
                        exact
                        path="/profile/edit"
                        component={ProfileEditScreen}
                    />
                    <PrivateRoute
                        exact
                        path="/profile/:id"
                        component={ProfileScreen}
                    />
                    <PrivateRoute
                        exact
                        path="/profile"
                        component={ProfileScreen}
                    />

                    <PrivateRoute
                        exact
                        path="/my-community/post/new"
                        component={NewPostScreen}
                    />
                    <PrivateRoute
                        exact
                        path="/my-community/posts/:id"
                        component={ViewPostScreen}
                    />
                    <PrivateRoute
                        exact
                        path="/my-community"
                        component={MyCommunityScreen}
                    />

                    <PrivateRoute
                        exact
                        path="/notifications"
                        component={NotificationsScreen}
                    />
                    <PrivateRoute
                        exact
                        path="/achievements"
                        component={AchievementsScreen}
                    />

                    <PrivateRoute
                        exact
                        path={routes.ADD_ACTIVITY}
                        component={AddActivityScreen}
                    />
                    <PrivateRoute
                        exact
                        path={routes.LIST_ACTIVITY}
                        component={ActivityScreen}
                    />
                    <PrivateRoute
                        exact
                        path={routes.NEW_BIODATA.CHOOSE_TYPE}
                        component={ChooseBiodataType}
                    />
                    <PrivateRoute
                        exact
                        path={routes.NEW_BIODATA.ENTRY_DATA}
                        component={EntryBiodata}
                    />
                    <PrivateRoute
                        exact
                        path={routes.LIST_BIODATA}
                        component={ListBiodata}
                    />
                    <PrivateRoute
                        exact
                        path={routes.JOURNAL.VIEW}
                        component={Journal}
                    />
                    <PrivateRoute
                        exact
                        path={routes.JOURNAL.NEW}
                        component={JournalNewEntry}
                    />
                    <PrivateRoute
                        exact
                        path={routes.STOPWATCH}
                        component={Stopwatch}
                    />

                    <PrivateRoute
                        exact
                        path={routes.MAIN.DASHBOARD}
                        component={DashboardScreen}
                    />
                    <PrivateRoute
                        exact
                        path={routes.MAIN.FRIENDS}
                        component={MyFriendsScreen}
                    />
                    <PrivateRoute
                        exact
                        path={routes.TASKS}
                        component={TasksScreen}
                    />
                    <PrivateRoute
                        exact
                        path={routes.MAIN.CHALLENGES_LIST}
                        component={ChallengeListScreen}
                    />
                    <PrivateRoute
                        exact
                        path={routes.COOP_CHALLENGES}
                        component={CoopChallengesScreen}
                    />
                 
                    <Route path="/">
                        <Redirect to={routes.MAIN.DASHBOARD} />
                    </Route>
                </Switch>
                <MenuBar />
            </AnimatePresence>
        </Router>
    );
};
