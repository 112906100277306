import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Challenge } from '../../../../models/challenge.model'
import { RootState } from '../../../../redux/store/store'
import { useStyles } from './NewChallengeInvite.styles'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../routers/routes'


export const NewChallengeInvite = () => {

    const challenge: Challenge = useSelector( ( state: RootState ) => new Challenge(state.challenge.challenge) )

    const history = useHistory()

    const classes = useStyles()
    const handleLater = () => {
        history.replace(routes.MAIN.DASHBOARD)
    }
    const handleInvitePeople = () => {
        history.replace(`/challange/${challenge.id}/participants`)
    }
    
    return (
        <>
            <Grid container direction="row" justify="center" className={'mt-5'}>
                <Typography variant={'h1'}>
                    Invite People
                </Typography>
            </Grid>
            <Grid container direction="row" justify="center" className={'mt-5 p-3'}>
                <Typography variant={'h6'} align="center">
                    ... and remember: you are much stronger than you think you are.
                </Typography>
                <Typography variant={'h6'} align="center">
                    Belive in Yourself
                </Typography>
            </Grid>
            <Grid container direction="column" className={'mt-10'}>
                <Button className={'main-button'} onClick={handleInvitePeople} >Invite People</Button>
            </Grid>
            <Grid container justify="center" direction="row" className={classes.bottomNavbar}>
                <Button fullWidth className="secondary-button" onClick={handleLater}>Later</Button>
            </Grid>
        </>

    )
}
