import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BiodataInput } from '../../../models/biodataEntry.model'

interface BiodataState {
    entryInput: BiodataInput
}

const initialState: BiodataState = {
    entryInput: {
        biodataType: null,
        title: '',
        label: '',
        prop: null,
        label2: '',
        prop2: null,
        type: 'messure-date'
    }
}

//Reducer
const biodataSlice = createSlice({
    name: 'biodata',
    initialState,
    reducers: {
        setEntryInput(state, action: PayloadAction<BiodataInput>) {
            state.entryInput = action.payload
        },
        clearEntryInput(state) {
            state.entryInput = initialState.entryInput
        },

    }
})


export const {
    setEntryInput,
    clearEntryInput,

} = biodataSlice.actions

export default biodataSlice.reducer