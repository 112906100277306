import React from 'react'
import { useStyles } from './SelectTextCards.style'
import { ButtonBase, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useEffect } from 'react';


interface CardOptions { text: string, selected: boolean }

export const SelectTextCards = ( { cards, handleChange }: { cards: CardOptions[], handleChange: Function } ) => {

    const classes = useStyles()
    const [ currentCards, setCurrentCards ] = useState<CardOptions[]>( cards )

    const handleClick = ( index: number ) => {

        setCurrentCards( state => {
            state[ index ].selected = !state[ index ].selected
            return [ ...state ]
        } )
    }

    useEffect( () => {

        handleChange( currentCards )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentCards ] )

    return (
        <Grid container direction="column" className={classes.root}>
            {currentCards.map( ( c, i: number ) => {
                return (
                    <ButtonBase key={i} >
                        <Grid
                            onClick={() => { handleClick( i ) }}
                            item
                            className={clsx( classes.card, c.selected && classes.cardActive )}
                            container
                            direction="row"
                            justify={'space-between'}
                            alignContent="center"
                        >
                            <Typography variant={'body2'}>
                                {c.text}
                            </Typography>
                            {c.selected && <FontAwesomeIcon className={classes.cardIcon} icon={faCheckCircle} />}
                        </Grid>
                    </ButtonBase>
                )
            } )}
        </Grid>
    )
}
