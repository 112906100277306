import React from 'react'
import { Grid } from '@material-ui/core'
import ClipLoader from "react-spinners/ClipLoader";
import theme from '../../theme';
import { motion } from 'framer-motion';
import { pageTransitions, pageVariants } from '../../animations/transitions';

interface Props {
    isLoading?: boolean,
    children: JSX.Element,
    type?: '' | 'fullwidth' | 'fullscreen'

}

export const LoadingWrapper = ({ isLoading, children, type = '' }: Props) => {

    let classes: string = '';
    switch (type) {
        case 'fullscreen': classes = 'mt-6'; break;
        case 'fullwidth':
        default:
            break;
    }

    return (

        <>
            {
                isLoading ? (
                    <Grid container direction='column' justify="center" alignItems="center" className={classes} >
                        <ClipLoader color={theme.palette.primary.main} speedMultiplier={0.5} size={40} />
                    </Grid>
                ) : (
                    <motion.div
                        initial="initial"
                        exit="out"
                        animate="in"
                        variants={pageVariants}
                        transition={pageTransitions}
                    >
                        {children}
                    </motion.div>
                )
            }
        </>
    )
}
