import { Notification } from "../models/notification.model";
import { NotificationStatusType } from "../models/types";
import { User } from "../models/user.model";
import store from "../redux/store/store";
import { A3Server } from "../utils/fetch";
import { addDataRTDB } from "./firebase.config";

export class NotificationsApi {
    
    static createNotification = async (
        notification: Notification
    ): Promise<Notification> => {
        if (!notification?.to?.id) {
            console.error("No notification to userId...", notification);
            throw new Error("No notification to userId...");
        }
        const user: User = store.getState().auth.user;

        if (!notification?.from?.id)
            notification.from = new User(user).getPublicData();

        const a3Server = new A3Server();
        const newNotification = await a3Server.post<Notification>(
            `v1/users/${notification.to.id}/notifications`,
            notification
        );

        const path = `/users/${notification.to.id}/notifications/${newNotification.id}`;
        addDataRTDB(path, newNotification);

        return newNotification;
    };
    static deleteNotification = async (
        notification: Notification
    ): Promise<Notification> => {
        if (!notification?.to?.id) {
            console.error("No notification TO userId...", notification);
            throw new Error("No notification TO userId...");
        }
        const a3Server = new A3Server();
        await a3Server.delete<void>(
            `v1/users/${notification.to.id}/notifications/${notification.id}`
        );

        const path = `/users/${notification.to.id}/notifications/${notification.id}`;
        await addDataRTDB(path, null);

        return notification;
    };
    
    static markAsRead = async (
        notification: Notification
    ): Promise<Notification> => {
        if (!notification?.to?.id) {
            console.error("No notification TO userId...", notification);
            throw new Error("No notification TO userId...");
        }

        const a3Server = new A3Server();
        await a3Server.put<void>(
            `v1/users/${notification.from.id}/notifications/${notification.id}`,
            { status: NotificationStatusType.READ }
        );
        notification.status = NotificationStatusType.READ;

        return notification;
    };
}
