import React from "react";
import { Button, Typography } from "@material-ui/core";
import theme from "../../theme";
import ClipLoader from "react-spinners/ClipLoader";
import clsx from 'clsx';
interface Props {
    isLoading?: boolean;
    disabled?: boolean;
    children?: any;
    [x: string]: any;
}

export const A3Button = (props: Props) => {
    const { isLoading = false } = props;

    return (
        <Button
            {...props}
            disabled={props.disabled || isLoading}
            className={clsx({"loading-button": isLoading}, props.className)}
        >
            {isLoading ? (
                <>
                    <ClipLoader
                        color={theme.palette.primary.main}
                        speedMultiplier={0.6}
                        size={20}
                    />
                    <Typography variant={"button"} className={"pl-1"}>
                        Loading...
                    </Typography>
                </>
            ) : (
                props.children || <></>
            )}
        </Button>
    );
};
