import React from 'react';
import './App.css';
import { Container, CssBaseline } from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core';
import theme from './theme';
import { AppRouter } from './routers/AppRouter';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

function App() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={'xs'}>
        <AppRouter />
      </Container>
    </ThemeProvider>
    </MuiPickersUtilsProvider>

  );
}

export default App;
