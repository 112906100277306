import React, { Fragment, useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { User } from "../../../models/user.model";
import { useAppSelector } from "../../../redux/store/store";
import { RewardsApi } from "../../../apis/rewardsApi";
import { A3RewardBadge } from "../../../components/A3RewardBadge/A3RewardBadge";
import { BadgeActivityType, BadgeColor } from "../../../models/types";
import { ChallengesApi } from "../../../apis/challengesApi";

type BadgeType = {
    activityName: string;
    name: string;
    type: BadgeActivityType;
    color: BadgeColor;
    disable?: boolean;
};

export const BadgesList = () => {
    const user: User = useAppSelector((state) => state.auth.user);
    const [badges, setBadges] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);

    const fetchRewards = async () => {
        setIsLoading(true);
        try {
            const rewardsDB = await RewardsApi.getRewards(user.id);
            const rewardedGoalIds = rewardsDB.map((r) => r.goalId);
            const goalsDB = await RewardsApi.getGoals();
            const badgesTemp: any = {};
            goalsDB
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .forEach((g) => {
                    const activity = ChallengesApi.getActivity(
                        g.categoryId,
                        g.activityId
                    );

                    if (!badgesTemp[activity.name])
                        badgesTemp[activity.name] = [];
                    badgesTemp[activity.name].push({
                        color: g.awardIcon,
                        type: activity.id,
                        activityName: activity.name,
                        name: g.awardName,
                        disable: !rewardedGoalIds.includes(g.id),
                    } as BadgeType);
                });
            setBadges(badgesTemp);
        } catch (e) {
            console.error(e);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchRewards();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignContent="center"
                alignItems="center"
                className="mt-1"
            >
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    wrap="wrap"
                >
                    {isLoading ? (
                        <CircularProgress />
                    ) : Object.keys(badges).length === 0 ? (
                        <Typography variant="body1" align="center">
                            There aren't any badges set yet
                        </Typography>
                    ) : (
                        Object.keys(badges).map((badgeType, i) => (
                            <React.Fragment key={i+badgeType}>
                                <Typography variant="h2" className="mt-3 mb-2">
                                    {badgeType}
                                </Typography>
                                <Grid key={i} container justify="flex-start">
                                    {badges?.[badgeType].map(
                                        (b: BadgeType, index: number) => (
                                            <Grid
                                                key={index}
                                                item
                                                style={{ width: "20%" }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justify="center"
                                                    alignItems="center"
                                                    className="pr-0"
                                                >
                                                    <A3RewardBadge
                                                        size={50}
                                                        badgeType={b.type}
                                                        color={b.color}
                                                        disabled={b.disable}
                                                    />
                                                    <Typography variant="h6">
                                                        {b.activityName}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {b.name}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {b.color}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </React.Fragment>
                        ))
                    )}
                </Grid>
            </Grid>
        </>
    );
};
