import { Button, Typography } from "@material-ui/core";
import { useState, useEffect, useRef, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TasksApi } from "../../apis/tasksApi";
import { A3DatePicker } from "../../components/DatePicker/DatePicker";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { Task } from "../../models/task.model";
import { useAppSelector } from "../../redux/store/store";
import clsx from "clsx";
import { useStyles } from "./Stopwatch.styles";
import { StopwatchCounter } from "./components/StopwatchCounter";
import moment from "moment";
import { modal } from "../../components/modal/modal";
import { useAudio } from "../../utils/playAudio";
import { TimersApi } from "../../apis/timersApi";
import { Timer } from "../../models/timer.model";
import { LoadingWrapper } from "../../components/LoadingWrapper/LoadingWrapper";

interface RouteParams {
    type: "fasting" | "meditation";
    taskId: string;
    challengeId: string;
}
export const Stopwatch = () => {
    const classes = useStyles();
    const currentUser = useAppSelector((s) => s.auth.user);
    const [start, setStart] = useState(false);
    const [hasStarted, setHasStarted] = useState(false);
    const [counter, setCounter] = useState(0);
    const [currentTimer, setCurrentTimer] = useState<Timer>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [task, setTask] = useState<Task>(null);
    const history = useHistory();
    const mounted = useRef(false);
    const { play } = useAudio("meditation-finish");

    const {
        type = "meditation",
        taskId = "",
        challengeId = "",
    }: RouteParams = useParams();
    const timePicker = useRef(null);
    const getTaskQuantity = () => {
        if (!task?.excpectedQuantity) return 0;

        return task.excpectedQuantity * 60;
    };
    const selectStartingTime = () => {
        timePicker.current.click();
    };
    const startFasting = useCallback(
        async (e: any) => {
            if (currentTimer?.id) {
                const response = await modal(
                    "question",
                    "Timer",
                    "You already have a ongoing timer for another task, you may only have one timer at the time. Do you want to cancel the other one and start this one instead?"
                );
                if (response === "cancel") return;
            }
            const startedAt = moment(e.target.value);

            TimersApi.createTimer(
                new Timer({
                    id: task.id,
                    taskId: task.id,
                    challengeId: task.challengeId,
                    startTime: startedAt.toDate().getTime(),
                })
            );
            const secondsFasting = startedAt.diff(moment(), "seconds") * -1;
            setCounter(secondsFasting || 5);
            setHasStarted(true);
            setStart(true);
        },
        [task, currentTimer]
    );
    const startMeditating = () => {
        setCounter(getTaskQuantity() || 5);
        setHasStarted(true);
        setStart(true);
    };
    const playSound = () => {
        play();
    };
    const stopMeditating = async () => {
        if (!start) return;
        setStart(false);
        const currentCounter = counter - 1; // -1 to compensate for the next cycle
        if (currentCounter === 0) playSound();
        const totalSeconds = getTaskQuantity() - currentCounter;
        const minutes = Number((totalSeconds / 60).toFixed(2));
        const roundDownMinutes = Math.floor(minutes);
        if (currentCounter > 1) {
            const answer = await modal(
                "question",
                "",
                `Finish meditation with ${roundDownMinutes}:${(
                    "00" + (totalSeconds - roundDownMinutes * 60).toString()
                ).slice(-2)} minutes of progress?`
            );
            if (answer === "cancel") {
                setStart(true);
                return;
            }
        } else {
            await modal(
                "alert",
                "You made it!",
                `You've finish your ${roundDownMinutes}:${(
                    "00" + (totalSeconds - roundDownMinutes * 60).toString()
                ).slice(-2)} minutes meditation!`
            );
        }
        await TasksApi.updateSingleTask(task, Number(minutes), 0, true);

        history.replace("/main/tasks");
    };
    const stopFasting = async () => {
        if (!start) return;
        setStart(false);
        const currentCounter = counter - 1; // -1 to compensate for the next cycle
        const totalSeconds = getTaskQuantity() - currentCounter;
        const fastingHours = Number((totalSeconds / 3600).toFixed(2));

        const answer = await modal(
            "question",
            "",
            `Do you want to stop and register your fasting time?`
        );
        if (answer === "cancel") {
            setStart(true);
            return;
        }
        setCurrentTimer(null);

        await TasksApi.updateSingleTask(task, Number(fastingHours), 0, true);

        history.replace("/main/tasks");
    };
    const restart = async () => {
        setStart(false);
        const answer = await modal(
            "question",
            "Cancel",
            `Do you want to cancel`
        );
        if (answer === "cancel") {
            setStart(true);
            return;
        }
        setTimeout(() => {
            if (!mounted.current) return;
            if (type === "fasting") {
                if (taskId) TimersApi.removeTimer(taskId);
                setCurrentTimer(null);
                setCounter(0);
            }
            if (type === "meditation") setCounter(getTaskQuantity() || 5);
        }, 700);
        setStart(false);
        setHasStarted(false);
    };

    const getTask = useCallback(async () => {
        if (!taskId || !challengeId || !currentUser.id) return;
        const taskDB = await TasksApi.getTask(
            taskId,
            challengeId,
            currentUser.id
        );

        setTask(taskDB);

        switch (type) {
            case "fasting":
                const timer = await TimersApi.getCurrentTimer();
                if (timer?.id) setCurrentTimer(timer);
                if (timer?.taskId === taskDB.id) {
                    // Has a timer in the server.
                    const startedAt = moment(timer.startTime);
                    const secondsFasting = startedAt.diff(moment(), "s") * -1;
                    setCounter(secondsFasting || 5);
                    setHasStarted(true);
                    setStart(true);
                } else {
                    // Starts a new timer
                    setCounter(0);
                }
                setIsLoading(false);
                break;
            case "meditation":
                setCounter(taskDB.excpectedQuantity * 60 || 5);
                setIsLoading(false);
                break;
            default:
                setIsLoading(false);
                history.replace("/");
                throw new Error(`${type} timer not defined...`);
        }
    }, [taskId, challengeId, type, currentUser, history]);

    useEffect(() => {
        if (!start || counter <= 0) return;
        setTimeout(() => {
            if (!mounted.current) return;
            setCounter((t) => {
                if (!start) return t;
                if (type === "fasting") {
                    return t + 1;
                }
                if (type === "meditation") {
                    const newCounter = t - 1;
                    if (newCounter === 0) {
                        stopMeditating();
                        return 0;
                    }
                    return newCounter;
                }
            });
        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter, start]);

    useEffect(() => {
        if (task?.id) return;
        getTask();
    }, [getTask, task]);
    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    return (
        <LoadingWrapper isLoading={isLoading} type="fullscreen">
            <>
                <NavigationBar title={type.toUpperCase()} />
                <Typography
                    variant="h1"
                    align="center"
                    className={clsx("mt-2", "mb-4", classes.title)}
                >
                    Hey {currentUser.firstName}!, ready to start your {type}?
                </Typography>

                {/* <Typography variant="h1">{counter}</Typography> */}
                <StopwatchCounter seconds={counter} />
                {type === "fasting" &&
                    (!hasStarted ? (
                        <Button
                            fullWidth
                            className="main-button mt-3"
                            onClick={selectStartingTime}
                        >
                            Start Fasting
                        </Button>
                    ) : (
                        <>
                            <Button
                                fullWidth
                                className="main-button mt-3"
                                onClick={stopFasting}
                            >
                                Stop Fasting
                            </Button>
                            <Button
                                fullWidth
                                className="secondary-button mt-3"
                                onClick={restart}
                            >
                                Cancel
                            </Button>
                        </>
                    ))}
                {type === "meditation" &&
                    (!hasStarted ? (
                        <Button
                            fullWidth
                            className="main-button mt-3"
                            onClick={startMeditating}
                        >
                            Start Meditating
                        </Button>
                    ) : (
                        <>
                            <Button
                                fullWidth
                                className="main-button mt-3"
                                onClick={stopMeditating}
                            >
                                Stop Meditating
                            </Button>
                            <Button
                                fullWidth
                                className="secondary-button mt-3"
                                onClick={restart}
                            >
                                Cancel
                            </Button>
                        </>
                    ))}
                <A3DatePicker
                    className="hidden"
                    ref={timePicker}
                    format="MM/DD/YYYY h:mm a"
                    label="Select Timer"
                    name="timer"
                    openTo="hours"
                    headerFormat="date-time"
                    disableFuture
                    views={["year", "month", "date", "hours", "minutes"]}
                    dateChange={(e) => {
                        startFasting(e);
                    }}
                    date={new Date()}
                />
            </>
        </LoadingWrapper>
    );
};
