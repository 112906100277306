import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = ( props = {} ) => {
    //const matches = useMediaQuery('(min-width:450px)');
    const styles = makeStyles( ( theme: Theme ) =>
        createStyles( {
            root: {
                // zIndex: 1,
                width: '100%',
                height: '100px',
                margin: '16px 0',
                padding: '16px',
                borderRadius: theme.spacing(2),
                backgroundColor: '#303030',
                color: '#fff'
            },
            cardTitle: {
                fontSize: '18px',
                fontWeight: 600,
                color: '#fff',
                height: 'calc(100px - 29px)'
            },
            cardIndicator: {
                position: 'relative',
                top: '-10px',
                // right: '45px',
                fontSize: '48px',
                fontWeight: 600,
                color: '#fff'
            },
            footer: {
                position: 'relative',
                top: '-10px',
                left: '-16px',
                backgroundColor: theme.palette.primary.dark,
                fontSize: '12px',
                fontWeight: 600,
                textTransform: 'uppercase',
                padding: ' 4px 32px 0 0',
                minHeight: '27px',
                minWidth: '115%',
                height: '27px',
                width: '115%',
            },
            
        } )
    );
    return styles();
};
