import { modal } from "../components/modal/modal"
import { signOutFirebase } from "../providers/firebase"
import { logout } from "../redux/slices/auth/auth.slice"
import store from "../redux/store/store"
import { getToken } from "./auth"
import { logError } from "./error"

type httpMethods = 'GET' | 'POST' | 'PUT' | 'DELETE'

interface A3ServerOptions {
    method?: httpMethods
    body?: object
}

export class A3Server {

    private headers: { [key: string]: any } = {}

    public setHeaders(headers: { [key: string]: any }) {
        this.headers = { ...this.headers, ...headers }
        return this
    }

    upload = async <T>(endpoint: string, data: FormData): Promise<T> => {
        return this.setHeaders({
            'Content-Type': 'multipart/form-data; charset=utf-8; boundary=------------------a3a3a3a3a3a3a3a3',
        }).post(endpoint, data)
    }

    delete = async <T>(endpoint: string, body?: object): Promise<T> => {
        return this.fetchA3Server<T>(endpoint, { method: 'DELETE', body })
    }

    get = async <T>(endpoint: string): Promise<T> => {
        return this.fetchA3Server<T>(endpoint)
    }

    post = async <T>(endpoint: string, body: object): Promise<T> => {
        return this.fetchA3Server<T>(endpoint, {
            method: 'POST',
            body
        })
    }
    put = async <T>(endpoint: string, body: object): Promise<T> => {
        return this.fetchA3Server<T>(endpoint, {
            method: 'PUT',
            body
        })
    }

    fetchA3Server = async <T>(endpoint: string, options: A3ServerOptions = {}): Promise<T> => {
        // store.dispatch(ui.loading(true))

        const { method = 'GET', body = null } = options

        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    authorization: getToken(),
                    ...this.headers,
                },
                body: body && JSON.stringify(body),
            })

            if (response.status === 401 || response.status === 403) {
                store.dispatch(logout())
                signOutFirebase()
                modal('error', '', 'Your session has expired. Please sign in again.')
                return;
            }

            const jsonResponse = await response.json()
            if (response.status >= 400) {
                await modal('error', '', jsonResponse?.messageDetail || 'Error occured connecting with A3 Servers. Please come back later.')
                return
            }
            return jsonResponse

        } catch (e) {
            logError('trying to fetch from server.', { endpoint, options }, e)
            throw e
        }
    }
}

