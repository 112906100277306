import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { useStyles } from "./ActiveTimerIcon.styles";

export const ActiveTimerIcon = () => {
    const classes = useStyles();

    const goToTimer = () => {
        //TODO: open timer 
    }

    return (
        <Box className={classes.root}>
            <FontAwesomeIcon onClick={goToTimer} icon={faClock} size={'1x'} /> Active Timer
        </Box>
    );
};
