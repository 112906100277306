import { Box, Card, Fab, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Chart, Line } from "react-chartjs-2";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { routes } from "../../routers/routes";
import annotationPlugin from "chartjs-plugin-annotation";
import { useGetJournalQuery } from "../../redux/slices/journal/journal.slice";
import { useAppSelector } from "../../redux/store/store";
import { User } from "../../models/user.model";
import { LoadingWrapper } from "../../components/LoadingWrapper/LoadingWrapper";
import { modal } from "../../components/modal/modal";
import { logError } from "../../utils/error";
import { JournalEntry } from "../../models/journalEntry.model";
import moment from "moment";
import { motion } from "framer-motion";
import { pageTransitions, pageVariants } from "../../animations/transitions";
import { JournalEntryCard } from "./components/JournalEntryCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

Chart.register(annotationPlugin);

export const Journal = () => {
    const history = useHistory();
    const [barData, setBarData] = useState<any>({});
    const { id: userId } = useAppSelector((s) => new User(s.auth.user));
    const {
        data: journal = [],
        isLoading,
        isError,
        error: journalError,
    } = useGetJournalQuery({ userId });

    const chartOptions = {
        scales: {
            y: {
                ticks: {
                    callback: function (value: any) {
                        // with this we remove the decimals in the chart
                        if (Number(value.toFixed(0)) === Number(value))
                            return value;
                    },
                },
            },
        },
        maintainAspectRatio: true,
        plugins: {
            autocolors: false,
        },
    };
    const getLabels = (
        userJournal: JournalEntry[]
    ): {
        labels: string[];
        data: number[];
    } => {
        const labels: string[] = [];
        const data: number[] = [];
        if (!userJournal?.length) return { labels, data };
        userJournal
            .slice()
            .sort((a, b) => {
                return a.date - b.date;
            })
            .forEach((j) => {
                labels.push(moment(j.date).format("DD-MMM"));
                data.push(j.emotion);
            });

        return { labels, data };
    };

    const newJournal = () => {
        history.push(routes.JOURNAL.NEW);
    };

    const setJournal = useCallback(() => {
        if (isLoading) return;
        if (isError) {
            modal(
                "error",
                "",
                "Looks like you don't have a good connection, close the app and try again later."
            );
            logError("Fetching Journal", journalError);
            return;
        }
        const { labels, data } = getLabels(journal);

        setBarData({
            labels,
            datasets: [
                {
                    label: "Mood",
                    data,
                    backgroundColor: new Array(2).fill("#78D008"),
                    borderColor: new Array(2).fill("#78D008"),
                    borderWidth: 1,
                },
            ],
        });
    }, [isLoading, journal, isError, journalError]);

    useEffect(() => {
        setJournal();
    }, [setJournal]);

    return (
        <Box width={"100%"} mb={5}>
            <motion.div
                initial="initial"
                exit="out"
                animate="in"
                variants={pageVariants}
                transition={pageTransitions}
            >
                <NavigationBar
                    title="My Journal"
                    backArrowRoute={routes.MAIN.DASHBOARD}
                    showBackButton
                />
            </motion.div>
            <LoadingWrapper isLoading={isLoading}>
                <motion.div
                    initial="initial"
                    exit="out"
                    animate="in"
                    variants={pageVariants}
                    transition={pageTransitions}
                >
                    {!!journal.length && (
                        <>
                            <Card className="curved">
                                <Typography
                                    variant="body1"
                                    align="center"
                                    className={"mt-1 mb-2"}
                                >
                                    How do I feel
                                </Typography>
                                <Line
                                    data={barData}
                                    width={100}
                                    height={100}
                                    plugins={[annotationPlugin]}
                                    options={chartOptions}
                                />
                            </Card>

                            <Typography
                                className="mt-3 mb-3"
                                variant={"h2"}
                                align="center"
                            >
                                Latest Entries
                            </Typography>

                            {journal.map((je) => (
                                <JournalEntryCard
                                    key={je.id}
                                    journalEntry={je}
                                />
                            ))}
                        </>
                    )}
                    {!journal.length && (
                        <Typography
                            className="mt-5"
                            variant={"h3"}
                            color="error"
                            align="center"
                        >
                            Looks like you haven't wrote anything in your
                            journal yet, tap the + sign at the bottom right to begin
                        </Typography>
                    )}
                </motion.div>
            </LoadingWrapper>
            <Box position="fixed" bottom="24px" right="24px">
                <Fab color="primary" onClick={newJournal}>
                    <FontAwesomeIcon size="lg" icon={faPlus} />
                </Fab>
            </Box>
        </Box>
    );
};
