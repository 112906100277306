import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { Contribution } from "../../../../models/contributor";
import clsx from 'clsx'
type Props = {
    index: number;
    contribution: Contribution;
    deleteContribution: (contribution: Contribution) => void,
    className: string
};

export const ContributionCard = ({
    index,
    contribution,
    deleteContribution,
    className
}: Props) => {
    const deleteEntry = async () => {
        deleteContribution(contribution);
    };

    return (
        <Card className={clsx("curved mt-2", className)}>
            <Grid
                item
                container
                direction="row"
                justify={"space-between"}
                alignContent="flex-start"
                alignItems="flex-start"
            >
                <Grid
                    item
                    xs={10}
                    container
                    direction="column"
                    alignItems="flex-start"
                    justify="flex-start"
                >
                    <Grid container direction="row">
                        <Typography
                            variant={"subtitle1"}
                            align={"left"}
                            className="mt-1"
                        >
                            {moment(contribution.date).format("ddd, MMM DD YY")}
                        </Typography>
                    </Grid>

                    <Typography
                        variant="body2"
                        align={"left"}
                        className="mt-1"
                    >
                        {`Contribution: ${contribution.amount} ${contribution.unit.replace('none', 'days')}`}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={2}
                    container
                    direction="column"
                    justify="center"
                    className={"mt-1 p-2"}
                >
                    <Box position="relative" top={-24} left={16}>
                        <Typography
                            variant="body2"
                            onClick={() => {
                                deleteEntry();
                            }}
                        >
                            {<FontAwesomeIcon icon={faTrash} />}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};
