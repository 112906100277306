import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store/store';

interface PublicRouteParameters extends RouteProps {
    component: any;
}

export const PublicRoute = ({
    component: Component,
    ...rest
}: PublicRouteParameters) => {
    const { isAuthenticated } = useSelector((state: RootState) => state.auth);

    return (
        <Route
            exact
            {...rest}
            component={(props: any) =>
                !isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};

PublicRoute.propTypes = {
    component: PropTypes.func.isRequired,
};
