import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@material-ui/core";
import { Emotion, JournalEntry } from "../../../models/journalEntry.model";
import { palette } from "../../../theme";
import clsx from 'clsx'
interface Props {
    emotion: Emotion
    setEmotion: (emotion: Emotion) => void
    className?: string
}

export const EmotionSelector = ({emotion,setEmotion, className}: Props) => {
    
    
    return (
        <Grid container direction="row" justify="space-between" wrap="nowrap" className={clsx(className)}>
                            
            <Grid container item direction="column" alignItems="center">
            <FontAwesomeIcon
                color={emotion === Emotion.sad ? palette.secondary.contrastText : palette.secondary.light}
                size="2x"
                icon={JournalEntry.getIcon(Emotion.sad)}
                onClick={()=>{setEmotion(Emotion.sad)}}
            />
            <Typography variant="caption" className="mt-1">Sad</Typography>
            </Grid>
            <Grid container item direction="column" alignItems="center">
            <FontAwesomeIcon
                color={emotion === Emotion.not_good ? palette.secondary.contrastText : palette.secondary.light}
                size="2x"
                icon={JournalEntry.getIcon(Emotion.not_good)}
                onClick={()=>{setEmotion(Emotion.not_good)}}
            />
            <Typography variant="caption" className="mt-1">Not Good</Typography>
            </Grid>
            <Grid container item direction="column" alignItems="center">
            <FontAwesomeIcon    
                color={emotion === Emotion.normal ? palette.secondary.contrastText : palette.secondary.light}
                size="2x"
                icon={JournalEntry.getIcon(Emotion.normal)}
                onClick={()=>{setEmotion(Emotion.normal)}}
            />
            <Typography variant="caption" className="mt-1">Normal</Typography>
            </Grid>
            <Grid container item direction="column" alignItems="center">
            <FontAwesomeIcon
                color={emotion === Emotion.great ? palette.secondary.contrastText : palette.secondary.light}
                size="2x"
                icon={JournalEntry.getIcon(Emotion.great)}
                onClick={()=>{setEmotion(Emotion.great)}}
            />
            <Typography variant="caption" className="mt-1">Great</Typography>
            </Grid>
            <Grid container item direction="column" alignItems="center">
            <FontAwesomeIcon
                color={emotion === Emotion.awesome ? palette.secondary.contrastText : palette.secondary.light}
                size="2x"
                icon={JournalEntry.getIcon(Emotion.awesome)}
                onClick={()=>{setEmotion(Emotion.awesome)}}
            />
            <Typography variant="caption" className="mt-1">Awesome</Typography>
            </Grid>
        </Grid>
    );
};
