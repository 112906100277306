import { Typography } from "@material-ui/core";
import React, { memo, useEffect } from "react";
import { ChallengesApi } from "../../apis/challengesApi";
import { ChallengeActivity } from "../../models/challengeActivity.model";
import { ChallengeCategory } from "../../models/challengeCategory.model";
import { useStyles } from "./ImageCard.style";

interface Props {
    category_activity: ChallengeCategory | ChallengeActivity;
    text?: string | number;
    isSelected?: boolean;
    onclick?: Function;
}

export const ImageCard = memo(({
    category_activity,
    text,
    isSelected = false,
    onclick,
}: Props) => {
    const classes = useStyles(category_activity.image)();

    const handleClick = () => {
        onclick && onclick(!isSelected ? category_activity : null);
    };

    useEffect(() => {

        if (category_activity instanceof ChallengeCategory) {
            category_activity.image =
                ChallengesApi.getChallengesCatregories().filter(
                    (c) => c.id === category_activity.id
                )[0].image;
        } else {
            category_activity.image =
                ChallengesApi.getChallengesCategoriesActivities(
                    category_activity.categoryId
                ).filter((a) => a.id === category_activity.id)[0]?.image || "";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root} onClick={handleClick}>
            <div
                className={classes.image + " " + (isSelected ? "selected" : "")}
            ></div>
            {text && (
                <Typography variant="body1" align="center">
                    {text}
                </Typography>
            )}
            <Typography variant="body2" align="center">
                {(category_activity as ChallengeActivity).categoryId !==
                    "fasting" ||
                window.location.pathname.indexOf("challenges/new") > -1
                    ? category_activity.name
                    : "Fasting"}
            </Typography>
        </div>
    );
})
