import { Button, ButtonBase, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { Task } from "../../models/task.model";
import { User } from "../../models/user.model";
import { RootState } from "../../redux/store/store";
import clsx from "clsx";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStyles } from "./TasksScreen.style";
import { modal } from "../../components/modal/modal";
import { LoadingWrapper } from "../../components/LoadingWrapper/LoadingWrapper";
import { motion } from "framer-motion";
import { pageTransitions, pageVariants } from "../../animations/transitions";
import { TasksApi } from "../../apis/tasksApi";
import { useHistory } from "react-router-dom";
import { ActiveTimerIcon } from "./components/ActiveTimerIcon";

export const TasksScreen = () => {
    const classes = useStyles();

    const user: User = useSelector((state: RootState) => state.auth.user);
    const history = useHistory();
    const [doneTasks, setDoneTasks] = useState<Task[]>([]);
    const [pendingTasks, setPendingTasks] = useState<Task[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState<"pending" | "completed">(
        "pending"
    );
    const fetchTasks = async () => {
        setIsLoading(true);
        const tempTasks = await TasksApi.getTasks(user.id);
        const tempPendingTasks: Task[] = [];
        const tempDoneTasks: Task[] = [];
        const tempFutureTasks: Task[] = [];
        const today = moment().endOf("day");
        tempTasks.forEach((task) => {
            if (moment(task.date).isSameOrBefore(today)) {
                if (task.done) {
                    tempDoneTasks.push(task);
                } else {
                    tempPendingTasks.push(task);
                }
            } else {
                tempFutureTasks.push(task);
            }
        });

        tempPendingTasks.sort((a, b) => {
            let isAfter = moment(a.date).isAfter(b.date);
            return isAfter ? 1 : -1;
        });

        setDoneTasks(tempDoneTasks);
        setPendingTasks(tempPendingTasks);
        setIsLoading(false);
    };

    const handleClick = async (task: Task) => {
        let actualQuantity;
        const categoryId = task.challenge.challengeCategory.id;

        if (task.unit === "none") {
            // is binary?
            actualQuantity = await modal(
                "modal-edit-boolean",
                "",
                "How did it go today ?"
            );
        } else {

            switch (true) {
                case ["fasting", "meditation"].includes(categoryId):
                    actualQuantity = await modal(
                        "modal-edit-track",
                        "",
                        "How did it go today ?"
                    );
                    break;
                case task.isProgressive && !task.done:
                    actualQuantity = await modal(
                        "modal-edit-progressive",
                        "",
                        "How did it go today ?"
                    );
                    break;
                default:
                    actualQuantity = await modal("modal-edit", "", "How did it go today ?");
                    break;
            }
        }
        if (actualQuantity === "cancel") return;

        if (actualQuantity === "track") {
            history.push(
                `/main/stopwatch/${categoryId}/${task.challengeId}/${task.id}`
            );
            return;
        }

        if (actualQuantity === "reset") {
            await TasksApi.updateSingleTask(
                task,
                0,
                Number(task.actualQuantity),
                false
            );
            await fetchTasks();
            return
        }

        if (actualQuantity === "done") {
            await TasksApi.updateSingleTask(
                task,
                Number(task.actualQuantity),
                Number(task.actualQuantity),
                true
            );
            
            await fetchTasks();
            return
        }

        if (actualQuantity === 0 && !task.isProgressive) {
            const answer = await modal(
                "question",
                "",
                "Do you want to mark this task as done with 0 progress?"
            );

            if (answer === "cancel") return;

            await TasksApi.updateSingleTask(
                task,
                Number(actualQuantity),
                Number(task.actualQuantity),
                true
            );
        }

        if (task.isProgressive) {
            await TasksApi.updateSingleTask(
                task,
                Number(task.actualQuantity) + Number(actualQuantity),
                Number(task.actualQuantity),
                false
            );
        } else {
            await TasksApi.updateSingleTask(
                task,
                Number(actualQuantity),
                Number(task.actualQuantity),
                true
            );
        }

        await fetchTasks();
    };

    useEffect(() => {
        fetchTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <motion.div
                initial="initial"
                exit="out"
                animate="in"
                transition={pageTransitions}
                variants={pageVariants}
            >
                <NavigationBar title={"Tasks"} />
                <Grid container justify="center">
                    <Button
                        onClick={() => {
                            setCurrentTab("pending");
                        }}
                        className={
                            "mt-2 mb-2 " +
                            classes.tabs +
                            `${currentTab === "pending" ? " active" : ""}`
                        }
                    >
                        Pending
                    </Button>
                    <Button
                        onClick={() => {
                            setCurrentTab("completed");
                        }}
                        className={
                            "mt-2 mb-2 " +
                            classes.tabs +
                            `${currentTab === "completed" ? " active" : ""}`
                        }
                    >
                        Completed
                    </Button>
                </Grid>
            </motion.div>

            <LoadingWrapper isLoading={isLoading}>
                <motion.div
                    initial="initial"
                    exit="out"
                    animate="in"
                    variants={pageVariants}
                    transition={pageTransitions}
                >
                    <Grid container direction="column">
                        {currentTab === "pending" && (
                            <>
                                {pendingTasks &&
                                    pendingTasks.map((t: Task, i: number) => (
                                        <ButtonBase
                                            key={i}
                                            className={classes.root}
                                        >
                                            <Grid
                                                onClick={() => {
                                                    handleClick(t);
                                                }}
                                                item
                                                className={clsx(
                                                    classes.card,
                                                    t.done && classes.cardActive
                                                )}
                                                container
                                                direction="row"
                                                justify={"space-between"}
                                                alignContent="flex-start"
                                            >
                                                <Grid
                                                    item
                                                    xs={10}
                                                    container
                                                    direction="column"
                                                    alignItems="flex-start"
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        direction="row"
                                                        justify="space-between"
                                                        wrap="nowrap"
                                                    >
                                                        <Typography
                                                            variant={
                                                                "subtitle1"
                                                            }
                                                            align={"left"}
                                                            className="mt-1"
                                                        >
                                                            {moment(
                                                                t.date
                                                            ).format(
                                                                "ddd, MMM DD YY"
                                                            )}
                                                        </Typography>
                                                        {t.timerId && (
                                                            <Grid
                                                                item
                                                                className={
                                                                    "mt-1"
                                                                }
                                                            >
                                                                <ActiveTimerIcon />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    <Typography
                                                        variant="body2"
                                                        align={"left"}
                                                        className="mt-1"
                                                    >
                                                        {
                                                            t.challenge
                                                                .challengeActivity
                                                                ?.name
                                                        }{" "}
                                                        -
                                                        {`
                                            ${t.excpectedQuantity}
                                            ${
                                                t.challenge.unit !== "none" &&
                                                t.challenge.unit
                                                    ? t.challenge.unit
                                                    : " "
                                            }
                                            ${t.challenge.interval}
                                        `}
                                                    </Typography>
                                                    {t.isProgressive && (
                                                        <Typography
                                                            variant="body2"
                                                            align={"left"}
                                                            className={"mt-0"}
                                                        >
                                                            Current Progress:{" "}
                                                            {t.actualQuantity}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    container
                                                    direction="column"
                                                    justify="center"
                                                    className={"mt-1 p-2"}
                                                >
                                                    {t.done && (
                                                        <FontAwesomeIcon
                                                            className={
                                                                classes.cardIcon
                                                            }
                                                            icon={faCheckCircle}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </ButtonBase>
                                    ))}
                                {pendingTasks.length === 0 && (
                                    <Typography
                                        variant="body1"
                                        className={classes.noElements}
                                        align="center"
                                    >
                                        ...Nothing here...
                                    </Typography>
                                )}
                            </>
                        )}
                        {currentTab === "completed" && (
                            <>
                                {doneTasks &&
                                    doneTasks.map((t: Task, i: number) => (
                                        <ButtonBase
                                            key={i}
                                            className={classes.root}
                                        >
                                            <Grid
                                                onClick={() => {
                                                    handleClick(t);
                                                }}
                                                item
                                                className={clsx(
                                                    classes.card,
                                                    t.done && classes.cardActive
                                                )}
                                                container
                                                direction="row"
                                                justify={"space-between"}
                                                alignContent="flex-start"
                                            >
                                                <Grid
                                                    item
                                                    xs={10}
                                                    container
                                                    direction="column"
                                                    alignItems="flex-start"
                                                >
                                                    <Typography
                                                        variant={"subtitle1"}
                                                        align={"left"}
                                                        className="mt-1"
                                                    >
                                                        {moment(t.date).format(
                                                            "ddd, MMM DD YY"
                                                        )}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        align={"left"}
                                                        className="mt-1"
                                                    >
                                                        {
                                                            t.challenge
                                                                .challengeActivity
                                                                ?.name
                                                        }{" "}
                                                        -
                                                        {`
                                            ${t.excpectedQuantity}
                                            ${
                                                t.challenge.unit !== "none" &&
                                                t.challenge.unit
                                                    ? t.challenge.unit
                                                    : " "
                                            }
                                            ${t.challenge.interval}
                                        `}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        align={"left"}
                                                    >
                                                        {t.unit !== "none" &&
                                                            `${t.actualQuantity} of ${t.excpectedQuantity}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    container
                                                    direction="column"
                                                    justify="center"
                                                    className={"mt-1 p-2"}
                                                >
                                                    {t.done && (
                                                        <FontAwesomeIcon
                                                            className={
                                                                classes.cardIcon
                                                            }
                                                            icon={faCheckCircle}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </ButtonBase>
                                    ))}

                                {doneTasks.length === 0 && (
                                    <Typography
                                        variant="body1"
                                        className={classes.noElements}
                                        align="center"
                                    >
                                        ...Nothing here...
                                    </Typography>
                                )}
                            </>
                        )}
                    </Grid>
                </motion.div>
            </LoadingWrapper>
        </>
    );
};
