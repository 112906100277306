import React from 'react'
import { Card, Grid, Typography } from '@material-ui/core'
import { UserAvatar } from '../UserAvatar/UserAvatar'
import { User } from '../../models/user.model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUserSlash } from '@fortawesome/free-solid-svg-icons'
import { ChallengeParticipant } from '../../models/challengeParticipant.model'
import { ParticipantStatusType } from '../../models/types'
import { useStyles } from './UserCard.styles'
import clsx from 'clsx'

interface Props {
    user: Partial<ChallengeParticipant>,
    resendFunction?: ( user: User ) => void
    removeFunction?: ( user: User ) => void
    actionVisible?: boolean
    className?: string
}

export const UserCard = ( {
    user,
    resendFunction,
    removeFunction,
    actionVisible = true,
    className = ''

}: Props ) => {

    if ( !user?.id ) user = new User( { firstName: 'Participant not loaded', lastName: '1' } )

    const classes = useStyles()
    const action: 'remove' | 'resend' = user?.status === ParticipantStatusType.joined ? 'remove' : 'resend'

    const handleResendFunction = () => {
        resendFunction && resendFunction( new User( user ) )

    }

    const handleRemoveFunction = () => {
        removeFunction && removeFunction( new User( user ) )

    }

    return (
        <Card className={clsx(className, classes.root)}>
            <Grid container direction="row" justify={'space-between'} alignItems="center">
                <Grid item xs={8} container direction="row" justify="flex-start" alignItems="center">
                    <Grid item>
                        <UserAvatar user={user} />
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" >
                            <Grid item>
                                <Typography variant={'body2'} className={'ml-1'}>
                                    {user.firstName} {user.lastName}
                                </Typography>
                                <Typography variant={'overline'} className={'ml-1'}>
                                    @{user.username}
                                </Typography>
                            </Grid>
                            {actionVisible &&
                                <Grid item>
                                    <Typography variant={'caption'} className={'ml-1'}>
                                        {action === 'remove' ? 'Active' : ''}
                                        {action === 'resend' ? 'Pending' : ''}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} container direction="row" justify={'flex-end'} alignItems="center">
                    {actionVisible && action === 'resend' && (
                        <Grid container direction='column' alignItems="center" onClick={handleResendFunction}>
                            <Grid item>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </Grid>
                            <Grid item>
                                <Typography variant={'caption'} >
                                    re-send
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={2} container direction="row" justify={'flex-end'} alignItems="center">
                    {actionVisible && (
                        <Grid container direction='column' alignItems="center" onClick={handleRemoveFunction}>
                            <Grid item>
                                <FontAwesomeIcon icon={faUserSlash} />
                            </Grid>
                            <Grid item>
                                <Typography variant={'caption'} >
                                    remove
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Card >
    )
}
