import { CategoryIntervalsTypes, ChallengeCategoryTypes } from "./types";

export class ChallengeActivity {

    public id: string = '';
    public image: string = '';
    public name: string = '';
    public units: string[] = [];
    public intervals: CategoryIntervalsTypes[] = [];
    public categoryId: ChallengeCategoryTypes = '';
    constructor( image:string = '', id: string='', name: string='', intervals: CategoryIntervalsTypes[] = [], units: string[] = [], categoryId: ChallengeCategoryTypes = '') {
        this.image = image
        this.id = id
        this.name = name
        this.units = units
        this.intervals = intervals
        this.categoryId = categoryId
    }

    toPlainObject(): any {
        const plainObj: any = JSON.parse( JSON.stringify( this ) );
        return plainObj;
    }
}