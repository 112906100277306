import React, { useState } from 'react'
import { Button, Card, Grid, TextField, Typography, } from '@material-ui/core'
import { useStyles } from './PostCommentsComponent.style'
import { useForm } from '../../hooks/useForm'
import { useEffect } from 'react'
import { Post } from '../../models/post.model'
import { PostComments } from '../../models/post-comments.model'
import { PostComment } from '../../models/post-comment.model'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store/store'
import { UserAvatar } from '../UserAvatar/UserAvatar'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { LoadingWrapper } from '../LoadingWrapper/LoadingWrapper'
import { PostApi } from '../../apis/postApi'
import { modal } from '../modal/modal'

interface Props {
    post: Post
}

export const PostCommentsComponent = ({ post }: Props) => {

    const classes = useStyles()
    const currentUser = useSelector((state: RootState) => state.auth.user)
    const [postComments, setPostComments] = useState<PostComments>(new PostComments({ postId: post.id }))
    const [isLoading, setIsLoading] = useState(false)
    const [{ message }, handleChange, reset] = useForm({
        message: ''
    })

    const fetchComments = async () => {
        setIsLoading(true)
        const commentsDB = await PostApi.getComments(post.id)
        const postCommentDB = new PostComments({comments:commentsDB})
        postCommentDB.sortCommentsByDate()

        setPostComments(postCommentDB)
        setIsLoading(false)

    }


    const handleDetele = async (comment: PostComment) => {
        try {
            
            await PostApi.deleteComment(comment);
        } catch (error) {

            modal('error', 'Error', 'This comment could not be deleted.')

        }
        
        fetchComments()

    }
    const handleSendComment = async () => {
        let comment2bSend = new PostComment({
            comment: message,
            postId: post.id,
            user: currentUser
        })
        reset()
        try {
            
            await PostApi.postComment(post.id, comment2bSend)
        } catch (error) {
            modal('error', 'Error', 'This comment could not be sent.')
        }

        fetchComments()


    }

    useEffect(() => {

        if (post.id) fetchComments()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post.id])

    return (
        <Grid container direction="row" className={classes.root} spacing={1}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    name={'message'}
                    value={message}
                    onChange={handleChange}
                    className={'mt-3'}
                    variant={'outlined'}
                    placeholder={'Share Something'}
                    label={'Share Something'}
                />
            </Grid>
            <Grid item xs={12} container direction="row" justify={'flex-end'}>
                <Button
                    className={'secondary-button'}
                    disabled={message.length < 2}
                    onClick={handleSendComment}
                >
                    Post Comment
                </Button>
            </Grid>
            <Grid
                item
                xs={12}
                container
                direction="column"
                justify="flex-start"
            >
                {postComments.comments && postComments.comments.map((comment, index) => (
                    <Card key={index} className={classes.commentCard + ' mb-2 p-2'}>
                        <LoadingWrapper isLoading={isLoading}>
                            <>
                                <Grid container direction="row" >
                                    <Grid item xs={10} container direction="row">
                                        <Grid item>
                                            {comment?.user?.id && <UserAvatar user={comment.user} />}
                                        </Grid>
                                        <Grid item className={'ml-1'}>
                                            <Grid container direction="column">
                                                <Typography variant={'body2'}>{comment?.user?.firstName} {comment?.user?.lastName}</Typography>
                                                <Typography variant={'overline'}>@{comment?.user?.username} - {moment(comment?.createdAt).format('DD MMM yyyy HH:mm')}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}  >
                                        <Grid container justify={'flex-end'} >
                                            {currentUser.id === comment.user.id && <FontAwesomeIcon icon={faTrashAlt} onClick={() => { handleDetele(comment) }} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" >
                                    <Typography variant={'subtitle1'} className={'mt-2 m'}>
                                        {comment.comment}
                                    </Typography>
                                </Grid>
                            </>
                        </LoadingWrapper>
                    </Card>
                ))}
            </Grid>

        </Grid>
    )
}
