import React from 'react'
import { Box } from '@material-ui/core'
import { useStyles } from './PostCardImage.style'
import { Post } from '../../models/post.model'

interface Props {
    post: Post
    className?: string,
}

export const PostCardImage = ( { className, post }: Props ) => {
    const classes = useStyles( post.mediaFileURL )
    return (
        <Box className={classes.root + ' ' + className}>
            {/* <img src={'assets/img/test.jpg'} width={'100%'} className={classes.image + ' ' + className} alt="A3 Logo" /> */}
        </Box>
    )
}
