import {  Grid } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { UserAvatar } from "../UserAvatar/UserAvatar";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";

export const NotificationBar = () => {
    const user = useSelector((state: RootState) => state.auth.user);
    const notifications = useSelector(
        (state: RootState) => state.notifications.notifications
    );
    const history = useHistory();

    const goToNotifications = () => {
        history.push("/notifications");
    };
    const goToProfile = () => {
        history.push("/profile");
    };
    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className="mt-1"
        >
            <Grid item>
                <UserAvatar
                    user={user}
                    onClick={goToProfile}
                    className={" ml-1"}
                />
            </Grid>

            <Grid item>
                <Grid
                    item
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    onClick={goToNotifications}
                >
                    <Badge
                        className="mr-1"
                        overlap="circle"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        badgeContent={notifications.length}
                        color="primary"
                    >
                        <FontAwesomeIcon
                            color="#fff"
                            size="2x"
                            icon={faBell}
                            className="mr-1"
                        />
                    </Badge>
                </Grid>
            </Grid>
        </Grid>
    );
};
