import { PostComment } from "./post-comment.model";

export class PostComments {

    public postId: string = '';
    public comments: PostComment[] = [];
    constructor( obj?: Partial<PostComments> ) {
        this.postId = obj?.postId || this.postId
        this.comments = obj?.comments || this.comments
    }

    sortCommentsByDate() {
        const comments = this.comments.sort( ( a, b ) => {
            return a.createdAt.getTime() > b.createdAt.getTime() ? -1 : 1
        } )
        this.comments = []
        this.comments = comments
    }

    toPlainObject(): any {
        const plainObj: any = JSON.parse( JSON.stringify( this ) );
        return plainObj;
    }
}