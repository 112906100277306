import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = () => {
    const styles = makeStyles( ( theme: Theme ) =>
        createStyles( {
            root: {
                borderRadius: theme.spacing(2),
            },
            cardIcon: {


            },

        } )
    );
    return styles();
};
