import React, { useCallback, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDumbbell,
    faRunning,
    faAngleDoubleUp,
    faSpa,
    faBook,
    faFistRaised,
    faFish,
    faBiking,
    faUpload,
    faDiagnoses,
    faSnowplow,
} from "@fortawesome/free-solid-svg-icons";
import { useStyles } from "./A3RewardBadge.style";
import { Box } from "@material-ui/core";
import { BadgeColor, BadgeActivityType } from "../../models/types";


type Props = {
    badgeType: BadgeActivityType;

    color?: BadgeColor;
    size?: number;
    disabled?: boolean;
};

/**
 * @size size in pixels
 * @reward
 */
export const A3RewardBadge = ({
    badgeType,
    color = "green",
    size = 200,
    disabled = false,
}: Props) => {
    const classes = useStyles(size, color, disabled)();
    const [icon, setIcon] = useState(faDumbbell);
    const transparency = disabled ? "20" : "";
    const randomId = (Math.random() * 10000).toFixed(0);
// Lo reviso y te aviso gracias 🙏🏼
// meditacion podriamos usar esta "thought-bubble" //not free
// Pull ups este "hand-fist" // 
// Reading books este "book"
// Reading pages "book-open-cover"
// Running "rabbit-running"
// Swimming "fish"
// Biking "bicycle"
// Push ups "dragon"  (no encontré una lagartica"
// Yoga "spa"
// general workout "dumbbell
    const selectIcon = useCallback(() => {
        switch (badgeType) {
            case "general-workout":
                setIcon(faDumbbell);
                break;
            case "pushups":
                setIcon(faAngleDoubleUp);
                break;
            case "running":
                setIcon(faRunning);
                break;
            case "pullups":
                setIcon(faFistRaised);
                break;
            case "squats":
                setIcon(faSnowplow);
                break;
            case "reading":
                setIcon(faBook);
                break;
            case "abs":
                setIcon(faUpload );
                break;
            case "bike":
                setIcon(faBiking);
                break;
            case "swim":
                setIcon(faFish);
                break;
            case "meditate":
                setIcon(faDiagnoses);
                break;
            case "yoga":
                setIcon(faSpa);
                break;
            // case "top-contributor":
            //     setIcon();
            //     break;
            default:
                setIcon(faDumbbell);
                break;
        }
    }, [badgeType]);

    useEffect(() => {
        selectIcon();
    }, [selectIcon]);

    return (
        <Box className={classes.container}>
            <svg
                fill="#FFF"
                height={`${size}px`}
                width={`${size}px`}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 489.327 489.327"
                stroke="#000"
                strokeWidth="0"
            >
                <linearGradient
                    id={`green${randomId}`}
                    gradientTransform="rotate(30)"
                >
                    <stop stopColor={`#1ae44e${transparency}`} offset="40%" />
                    <stop stopColor={`#82f371${transparency}`} offset="100%" />
                </linearGradient>
                <linearGradient
                    id={`blue${randomId}`}
                    gradientTransform="rotate(30)"
                >
                    <stop stopColor={`#1a42e4${transparency}`} offset="40%" />
                    <stop stopColor={`#01e1fe${transparency}`} offset="100%" />
                </linearGradient>
                <linearGradient
                    id={`gold${randomId}`}
                    gradientTransform="rotate(30)"
                >
                    <stop stopColor={`#FFFFAC${transparency}`} offset="8%" />
                    <stop stopColor={`#D1B464${transparency}`} offset="25%" />
                    <stop stopColor={`#5d4a1f${transparency}`} offset="70.5%" />
                    <stop stopColor={`#D1B464${transparency}`} offset="100%" />
                </linearGradient>
                <linearGradient
                    id={`silver${randomId}`}
                    gradientTransform="rotate(30)"
                >
                    <stop stopColor={`#dedede${transparency}`} offset="8%" />
                    <stop stopColor={`#ffffff${transparency}`} offset="25%" />
                    <stop stopColor={`#454545${transparency}`} offset="70.5%" />
                    <stop stopColor={`#dedede${transparency}`} offset="100%" />
                </linearGradient>
                <linearGradient
                    id={`bronze${randomId}`}
                    gradientTransform="rotate(30)"
                >
                    <stop stopColor={`#ca7345${transparency}`} offset="30%" />
                    <stop stopColor={`#ffdeca${transparency}`} offset="55%" />
                    <stop stopColor={`#ca7345${transparency}`} offset="80.5%" />
                    <stop stopColor={`#ffdeca${transparency}`} offset="100%" />
                </linearGradient>
                <linearGradient
                    id={`platinum${randomId}`}
                    gradientTransform="rotate(30)"
                >
                    <stop stopColor={`#fff${transparency}`} offset="0%" />
                    <stop stopColor={`#dedeff${transparency}`} offset="55%" />
                    <stop stopColor={`#555564${transparency}`} offset="80.5%" />
                    <stop stopColor={`#dedeff${transparency}`} offset="100%" />
                </linearGradient>
                <g strokeWidth="0"></g>
                <path
                    fill={`url(#${color}${randomId})`}
                    d="M476.616,273.213l-9.1-12.6c-7.6-10.4-7.7-24.5-0.3-35.1l8.9-12.7c10.4-14.8,5.5-35.3-10.3-43.9l-13.6-7.4 c-11.3-6.1-17.5-18.8-15.3-31.5l2.6-15.4c3-17.8-10.2-34.3-28.2-35l-15.4-0.7c-12.8-0.5-23.8-9.3-27.4-21.7l-4.3-15 c-5-17.4-24-26.4-40.4-19.2l-14.2,6.2c-11.8,5.1-25.5,2.1-34-7.6l-10.3-11.6c-12-13.5-33-13.3-44.7,0.3l-10.1,11.8 c-8.4,9.7-22,13-33.9,8.1l-14.3-5.9c-16.6-6.9-35.4,2.4-40.2,19.9l-4.1,15c-3.5,12.6-14.4,21.5-27.1,22.2l-15.4,0.9 c-17.9,1-30.9,17.7-27.6,35.4l2.8,15.3c2.3,12.7-3.7,25.5-14.9,31.7l-13.5,7.6c-15.7,8.8-20.2,29.4-9.6,44l9.1,12.6 c7.6,10.4,7.7,24.5,0.3,35.1l-9.1,12.8c-10.4,14.8-5.5,35.3,10.3,43.9l13.6,7.4c11.3,6.1,17.5,18.8,15.3,31.5l-2.5,15.2 c-3,17.8,10.2,34.3,28.2,35l15.4,0.7c12.8,0.5,23.8,9.3,27.4,21.7l4.3,15c5,17.4,24,26.4,40.4,19.2l14.2-6.2 c11.8-5.1,25.5-2.1,34,7.5l10.3,11.6c12,13.5,33,13.3,44.7-0.3l10.1-11.8c8.4-9.7,22-13,33.9-8.1l14.3,5.9 c16.6,6.9,35.4-2.4,40.2-19.9l4.1-15c3.4-12.4,14.3-21.3,27.1-22.1l15.4-0.9c17.9-1,30.9-17.7,27.6-35.5l-2.8-15.3 c-2.3-12.7,3.7-25.5,14.9-31.7l13.5-7.6C482.716,308.513,487.216,287.813,476.616,273.213z M244.616,393.513 c-82.4,0-149.2-67.3-149.2-150.3s66.8-150.4,149.2-150.4s149.2,67.3,149.2,150.3S327.016,393.513,244.616,393.513z"
                ></path>
            </svg>
            <FontAwesomeIcon icon={icon} className={classes.icon} />
        </Box>
    );
};
