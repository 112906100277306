export class IndividualActivity { 
    id: string
    date: number
    categoryId: string
    activityId: string
    quantity: number
    unit: string

    constructor(ia: Partial<IndividualActivity>) {
        
        this.id = ia.id || this.id
        this.date = ia.date || this.date
        this.categoryId = ia.categoryId || this.categoryId
        this.activityId = ia.activityId || this.activityId
        this.quantity = ia.quantity || this.quantity
        this.unit = ia.unit || this.unit
    }

}