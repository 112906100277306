import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@material-ui/core'
import { useStyles } from './FloatingCloseButton.styles'
import { useHistory } from 'react-router-dom'
import { routes } from '../../routers/routes'


interface Props {
    onClose?: Function
}
export const FloatingCloseButton = ( { onClose }: Props ) => {
    
    const classes = useStyles()
    const history = useHistory()

    const handleClose = () => {
        if ( onClose ) {
            onClose()
        } else {
            history.replace(routes.MAIN.DASHBOARD)
        }
    }

    return (
        <>
            <Button className={classes.root} onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} />
            </Button>
        </>
    )
}
