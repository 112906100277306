import { Friend } from '../models/friend.model';
import { IndividualActivity } from '../models/individualActivity';
import { User } from '../models/user.model';
import { uploadFileFirebase } from '../providers/firebase';
import { logError } from '../utils/error';
import { A3Server } from '../utils/fetch';



export class UsersApi {

    static updateUser = async (userId: string, userData: Partial<User>): Promise<User> => {
        if (!userId) {
            console.error('No user Id...', userData);
            throw new Error('No user Id...');
        }
        try {
            const a3Server = new A3Server()

            const updatedUser = await a3Server.put<User>(`v1/users/${userId}`, userData)

            return new User(updatedUser)

        } catch (error) {
            logError('UsersApi:updateUser', error)
            throw error;
        }
    };

    static getUserFriends = async (userId: string): Promise<User[]> => {
        if (!userId) {
            console.error('No user Id...', userId);
            throw new Error('No user Id...');
        }
        try {

            console.log('Review that friends are users or change implemenation')

            const a3Server = new A3Server()

            const addedFriend = await a3Server.get<Friend[]>(`v1/users/${userId}/friends`)

            return addedFriend.map(f => new User(f))

        } catch (error) {
            logError('UsersApi:getUserFriends', error)
            throw error;
        }
    };
    static getLatestActivity = async (userId: string) => {
        if (!userId) {
            console.error('No user Id...', userId);
            throw new Error('No user Id...');
        }

        try {

            const a3Server = new A3Server()
            const activity = await a3Server.get<IndividualActivity[]>(`v1/users/${userId}/individual-activities`)
            return activity

        } catch (error) {
            logError('UsersApi:getUserFriends', error)
            throw error;
        }
    };

    static isUserFollowing = async (userId: string, friendId: string): Promise<Friend> => {
        if (!userId) {
            console.error('No user Id...', userId);
            throw new Error('No user Id...');
        }
        if (!friendId) {
            console.error('No userFollowId...', friendId);
            throw new Error('No userFollowId...');
        }
        try {

            const a3Server = new A3Server()

            const friend = await a3Server.get<Friend>(`v1/users/${userId}/friends/${friendId}`)

            return new Friend(friend)

        } catch (error) {
            logError('UsersApi:isUserFollowing', error)
            throw error;
        }
    };
    static followUser = async (userId: string, friendId: string) => {
        if (!userId) {
            console.error('No user Id...', userId);
            throw new Error('No user Id...');
        }
        if (!friendId) {
            console.error('No friendId...', friendId);
            throw new Error('No friendId...');
        }
        try {

            const a3Server = new A3Server()

            await a3Server.post<Friend>(`v1/users/${userId}/friends`, { friendId })

        } catch (error) {
            logError('UsersApi:followUser', error)
            throw error;
        }
    };

    static unfollowUser = async (userId: string, friendId: string) => {
        if (!userId) {
            console.error('No user Id...', userId);
            throw new Error('No user Id...');
        }


        try {
            const a3Server = new A3Server()

            await a3Server.delete<Friend>(`v1/users/${userId}/friends/${friendId}`)

        } catch (error) {
            logError('UsersApi:unfollowUser', error)
            throw error;
        }
    };
    static addIndividualActivity = async (userId: string, individualActivity: IndividualActivity) => {
        if (!userId) {
            console.error('No user Id...', userId);
            throw new Error('No user Id...');
        }
        if (!individualActivity.activityId) {
            console.error('No individualActivity...', individualActivity);
            throw new Error('No individualActivity...');
        }
        try {

            const a3Server = new A3Server()

            await a3Server.post<Friend>(`v1/users/${userId}/individual-activities`, individualActivity)

        } catch (error) {
            logError('UsersApi:addIndividualActivity', error)
            throw error;
        }
    };

    static removeIndividualActivity = async (userId: string, individualActivityId: string) => {
        if (!userId) {
            console.error('No user Id...', userId);
            throw new Error('No user Id...');
        }


        try {
            const a3Server = new A3Server()

            await a3Server.delete<Friend>(`v1/users/${userId}/individual-activities/${individualActivityId}`)

        } catch (error) {
            logError('UsersApi:unfollowUser', error)
            throw error;
        }
    };

    static getUser = async (userId: string): Promise<User> => {
        if (!userId) {
            console.error('No user Id...', userId);
            throw new Error('No user Id...');
        }
        try {
            const a3Server = new A3Server()

            const userDB = await a3Server.get<User>(`v1/users/${userId}`)

            return new User(userDB)
        } catch (error) {
            logError('UsersApi:getUser', error)
            throw error;
        }
    };

    static getUserByUsername = async (username: string): Promise<User> => {
        if (!username) {
            console.error('No username...', username);
            throw new Error('No username...');
        }
        try {

            const a3Server = new A3Server()

            const userDB = await a3Server.get<User[]>(`v1/users?username=${username.replace('@', '')}`)
            if (!userDB || userDB.length === 0) return null
            return new User(userDB[0])
        } catch (error) {
            logError('UsersApi:getUserByUsername', error)
            throw error;
        }
    };

    static uploadUserMedia = async (profilePicture: File, userId: string): Promise<string> => {

        if (!userId) throw new Error('No post Id...');
        if (!profilePicture) throw new Error('No post Media...');

        try {
            const imgUrl = await uploadFileFirebase(`v1/users/${userId}/profile-picture`, profilePicture)
            await UsersApi.updateUser(userId, { imgUrl })

            return imgUrl;

        } catch (e) {
            logError('UsersApi:uploadUserMedia', e)
            throw e
        }

    };


}
