import React from 'react'
import { Post } from '../../models/post.model'
import { useStyles } from './PostMedia.style'

interface Props {
    className?: string
    post: Post
}

export const PostMedia = ( { className='', post }: Props ) => {

    const classes = useStyles()

    return (
        <>
            <img src={post.mediaFileURL} width={'100%'} className={classes.image + ' ' + className} alt={`Post of ${post?.user?.username}`} />
        </>
    )
}
