import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    isMenuOpen: false,
    changePage: 'string',
    isModalOpen: false,
    showNavbar: true,
    waitingForAuth: true,
};

//Reducer
const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setChangePage(state, action: PayloadAction<string>) {
            // CHANGE_PAGE
            state.changePage = action.payload

        },
        startLoading(state, action: PayloadAction<void>) {
            // START_LOADING
            state.loading = true;

        },
        stopLoading(state, action: PayloadAction<void>) {
            // STOP_LOADING
            state.loading = false;

        },
        toggleLoading(state, action: PayloadAction<void>) {
            // TOGGLE_LOADING
            state.loading = !state.loading;

        },
        toggleMenu(state, action: PayloadAction<void>) {
            // TOGGLE_MENU
            state.isMenuOpen = !state.isMenuOpen

        },
        modalOpen(state, action: PayloadAction<boolean>) {
            // MODAL_OPEN
            state.isModalOpen = action.payload

        },
        setWaitingForAuth(state, action: PayloadAction<boolean>) {
            // SET_WAITING_FOR_AUTH
            state.waitingForAuth = action.payload;

        },
        isNavbarVisible(state, action: PayloadAction<boolean>) {
            // HIDE_SHOW_NAVBAR
            state.showNavbar = action.payload;

        },
    }
})


export const {
    setChangePage,
    startLoading,
    stopLoading,
    toggleLoading,
    toggleMenu,
    modalOpen,
    setWaitingForAuth,
    isNavbarVisible,
} = uiSlice.actions

export default uiSlice.reducer