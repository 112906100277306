import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = ( imageURL = {} ) => {
    //const matches = useMediaQuery('(min-width:450px)');
    const styles = makeStyles( ( theme: Theme ) =>
        createStyles( {
            root: {
                width: '100%',
                height: 'calc(100px - 16px)',
                marginRight: theme.spacing(1),
                borderRadius: theme.spacing(1),
                background: `url(${imageURL}) no-repeat`, 
                '-webkit-background-size': '100% 100%',
                '-moz-background-size': '100% 100%',
                '-o-background-size': '100% 100%',
                backgroundSize: '100% 100%',
                // backgroundRepeat: 'no-repeat',
                // backgroundAttachment: 'fixed',
                // backgroundPosition: 'center',
            },
            image: {
                width: 'calc(100% - 18px)',
                height: 'calc(100% - 18px)',
            },
            
        } )
    );
    return styles();
};
