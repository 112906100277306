import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, signInWithPopup, sendPasswordResetEmail, } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { register } from "../redux/slices/auth/auth.slice";
import { User } from "../models/user.model";
import { modal } from "../components/modal/modal";
import { googleAuthProvider, facebookAuthProvider, auth, firebaseStorage } from "../apis/firebase.config";
import store from "../redux/store/store";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";


export const signOutFirebase = async () => {
    await signOut(auth)
}

export const createUserFirebase = async (email: string, password: string) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        const user = userCredential.user;
        const firebasetoken = await user.getIdToken()

        const newUser: Partial<User> = {
            id: user.uid,
            email,
            firstName: user.displayName,
        }
        store.dispatch(register({ firebasetoken, user: newUser }))


    } catch (error) {
        if (error instanceof FirebaseError) {
            const errorCode = error.code
            const errorMessage = error.message
            modal("error", errorCode || "Error Registering", errorMessage, undefined, 5000);
            return
        }
        throw error
    }
}
export const signInUserFirebase = async (email: string, password: string) => {
    if (!email || !password) {
        modal("error", "Login Error", 'Provide email and password', undefined);
        return
    }
    try {
        await signInWithEmailAndPassword(auth, email, password)
        // const user = userCredential.user;

    } catch (error) {
        if (error instanceof FirebaseError) {
            const errorCode = error.code
            let errorMessage = error.message
            switch (errorCode) {
                case "auth/network-request-failed":
                    errorMessage = "Looks like you're offline."
                    break;
                case "auth/invalid-email":
                case "auth/wrong-password":
                    errorMessage = "Couldn't find an account."
                    break;
            }

            modal("error", "Login Error", errorMessage, undefined);
            return
        }
        throw error
    }
}
export const startGoogleLogin = async () => {
    try {
        await signInWithPopup(
            auth,
            googleAuthProvider
        );

    } catch (error) {
        if (error instanceof FirebaseError) {
            const errorCode = error.code
            const errorMessage = error.message
            modal("error", errorCode || "Error Registering", errorMessage, undefined, 5000);
            return
        }
        throw error
    }
};
export const startFacebookLogin = async () => {
    try {
        await signInWithPopup(
            auth,
            facebookAuthProvider
        );

    } catch (error) {
        if (error instanceof FirebaseError) {
            const errorCode = error.code
            const errorMessage = error.message
            modal("error", errorCode || "Error Registering", errorMessage, undefined, 5000);
            return
        }
        throw error
    }
};
export const uploadFileFirebase = async (route: string, fileToUpload: File): Promise<string> => {
    const bucketPath = `${route}/${fileToUpload.name}`
    const fileRef = ref(firebaseStorage, bucketPath);
    await uploadBytes(fileRef, fileToUpload)
    const url = await getDownloadURL(fileRef)

    return url
}
export const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(auth, email);
    modal(
        "alert",
        "Done!",
        "If the email you provide is correct, you should've recieve instructions to restore you password in your inbox."
    );
};


