import { makeStyles } from "@material-ui/core";
import { BadgeColor } from "../../models/types";

export const useStyles = (size: number, color: BadgeColor, disabled: boolean) => {
    let iconColor = "#1ae44e";
    switch (color) {
        case "blue":
            iconColor = "#01e1fe";
            break;
        case "gold":
            iconColor = "#D1B464";
            break;
        case "silver":
            iconColor = "#dedede";
            break;
        case "platinum":
            iconColor = "#dedeff";
            break;
        case "bronze":
            iconColor = "#ca7345";
            break;
        default:
            iconColor = "#1ae44e";
            break;
    }

    if (disabled) iconColor += "50";
    const iconSize = size / 3;
    return makeStyles((theme: any) => ({
        container: {
            // marginLeft: `${iconSize}px`
            width: `${size}px`,
            height: `${size}px`,
            overflow: "hidden",
        },
        icon: {
            color: iconColor,
            position: "relative",
            fontSize: `${iconSize}px`,
            top: `-${size/2}px`,
            left: `${size/2}px`,
            transform: `translate(-50%, -75%)`
        },
    }));
};
