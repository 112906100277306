import React, { useEffect, useState } from "react";
import { useStyles } from "./MenuBar.style";
import AppBar from "@material-ui/core/AppBar";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckDouble,
    faTh,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "../../routers/routes";

export const MenuBar = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [currentPath, setCurrentPath] = useState("");

    const goTo = (route: string) => {
        history.push(route);
    };
    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location]);
    const visibleOnRoutes = [
        routes.MAIN.DASHBOARD,
        routes.MAIN.CHALLENGES_LIST,
        routes.MAIN.FRIENDS,
    ];

    if (!visibleOnRoutes.includes(window.location.pathname)) return null;
    return (
        <>
            <AppBar
                position="fixed"
                color="secondary"
                className={classes.appBarBottom}
            >
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="center"
                            alignContent="center"
                        >
                            <Grid item>
                                <IconButton
                                    onClick={() => {
                                        goTo(routes.MAIN.DASHBOARD);
                                    }}
                                    className={
                                        classes.button +
                                        `${
                                            currentPath ===
                                                routes.MAIN.DASHBOARD ||
                                            currentPath === "/"
                                                ? "active"
                                                : ""
                                        }`
                                    }
                                    color="inherit"
                                >
                                    <FontAwesomeIcon icon={faTh} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography variant={"subtitle1"}>
                                    Dashboard
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                        >
                            <Grid item>
                                <IconButton
                                    onClick={() => {
                                        goTo(routes.MAIN.FRIENDS);
                                    }}
                                    className={
                                        classes.button +
                                        `${
                                            currentPath === routes.MAIN.FRIENDS
                                                ? "active"
                                                : ""
                                        }`
                                    }
                                    color="inherit"
                                >
                                    <FontAwesomeIcon icon={faUsers} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography variant={"subtitle1"}>
                                    Friends
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                        >
                            <Grid item>
                                <IconButton
                                    onClick={() => {
                                        goTo(routes.MAIN.CHALLENGES_LIST);
                                    }}
                                    className={
                                        classes.button +
                                        `${
                                            currentPath ===
                                            routes.MAIN.CHALLENGES_LIST
                                                ? "active"
                                                : ""
                                        }`
                                    }
                                    color="inherit"
                                >
                                    <FontAwesomeIcon icon={faCheckDouble} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography variant={"subtitle1"}>
                                    Challenges
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppBar>
            <div className={classes.spacer}></div>
        </>
    );
};
