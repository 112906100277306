import { Grid } from "@material-ui/core";
import { LoadingWrapper } from "../../components/LoadingWrapper/LoadingWrapper";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { pageTransitions, pageVariants } from "../../animations/transitions";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { IndividualActivity } from "../../models/individualActivity";
import { ActivityCard } from "./components/ActivityCard";
import { UsersApi } from "../../apis/userApi";
import { useAppSelector } from "../../redux/store/store";
import { User } from "../../models/user.model";

export const ActivityScreen = () => {
    const currentUser: User = useAppSelector((s) => s.auth.user);
    const [activities, setActivities] = useState<IndividualActivity[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const removeActivity = async (individualActivity: IndividualActivity) => {
        try {
            setIsLoading(true);
            await UsersApi.removeIndividualActivity(
                currentUser.id,
                individualActivity.id
            );
            fetchActivities();
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };
    const fetchActivities = useCallback(async () => {
        try {
            setIsLoading(true);
            const actTemp = await UsersApi.getLatestActivity(currentUser.id);
            actTemp.sort((a,b)=> a.date > b.date ? -1 : 1)
            setActivities(actTemp);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }, [currentUser.id]);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities]);
    return (
        <motion.div
            initial="initial"
            exit="out"
            animate="in"
            transition={pageTransitions}
            variants={pageVariants}
        >
            <LoadingWrapper isLoading={isLoading} type="fullscreen">
                <Grid container direction="column">
                    <NavigationBar title="Latest Activity" />
                    {activities.map((a, i) => (
                        <ActivityCard
                            key={i}
                            index={i}
                            deleteIndividualActivity={removeActivity}
                            individualActivity={a}
                        />
                    ))}
                </Grid>
            </LoadingWrapper>
        </motion.div>
    );
};
