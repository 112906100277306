export class Timer {
    id: string = ''
    userId: string = ''
    taskId: string = ''
    challengeId: string = ''
    startTime: number = new Date().getTime()
    

    constructor(timer: Partial<Timer>) {
        this.id = timer.id || this.id
        this.userId = timer.userId || this.userId
        this.taskId = timer.taskId || this.taskId
        this.challengeId = timer.challengeId || this.challengeId
        this.startTime = timer.startTime || this.startTime
    }
}