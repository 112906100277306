import React from 'react'
import { useStyles } from './NavigationBar.style'
import AppBar from '@material-ui/core/AppBar';
import { Box, Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

interface NavigationBarProps {
    title: string
    showBackButton?: boolean
    backArrowRoute?: string
}

export const NavigationBar = ( { title, backArrowRoute,showBackButton = true }: NavigationBarProps ) => {

    const classes = useStyles()
    const history = useHistory()

    const goBack = () => {
        if ( backArrowRoute ) {
            history.replace( backArrowRoute )
        } else {
            if(history.length === 1) history.replace('/')
            history.goBack()
        }
    }
    return (
        <>
            <AppBar color="transparent" className={classes.appbar}>
                <Grid container direction="row" justify="space-between" alignItems="center" className={'mt-1'}>
                    {showBackButton && <Grid item className={classes.iconContainer}>
                        <FontAwesomeIcon onClick={goBack} icon={faArrowLeft} size={'lg'} className={classes.icon + ' ml-2'} />
                    </Grid>}
                    <Box className={classes.title}>
                        {title &&
                            <Typography variant={'h3'}>
                                {title}
                            </Typography>
                        }
                    </Box>
                </Grid>
            </AppBar>
            <div className={classes.separator}></div>
        </>
    )
}
