import { A3Server } from "../utils/fetch";


export class AbuseReportApi {

    static sendReport = async (postId: string, report:string) => {
        try {
            const a3Server = new A3Server()
            await a3Server.post<void>(`v1/abuseReport`, { postId, report })


        } catch (error) {
            throw error
        }

    }

}