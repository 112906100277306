import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles( ( theme: Theme ) => ( {
    root: {
        display: 'flex',
        height: '100vh',
        width: '100wh',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        
        zIndex: 1,
        WebkitAnimation: 'breathing 5s ease-out infinite normal',
        animation: 'breathing 5s ease-out infinite normal',
    },
} ) )
