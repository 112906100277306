import React from "react";
import {
    AppBar,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useForm } from "../../hooks/useForm";
import { RootState, useAppDispatch } from "../../redux/store/store";
import { User } from "../../models/user.model";
import { A3DatePicker } from "../../components/DatePicker/DatePicker";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { useHistory } from "react-router-dom";
import { FloatingCloseButton } from "../../components/FloatingCloseButton/FloatingCloseButton";
import { updateUserData } from "../../redux/slices/auth/auth.slice";
import { UsersApi } from "../../apis/userApi";
export const ProfileEditScreen = () => {
    const dispatch = useAppDispatch();
    const user: User = useSelector((state: RootState) => state.auth.user);
    const history = useHistory();

    const [{ firstName, lastName, birthday, pronouns }, handleInputChange] =
        useForm({
            firstName: user.firstName,
            lastName: user.lastName,
            birthday: user.birthday,
            pronouns: user.pronouns,
        });

    const handleSave = (e: any) => {
        dispatch(
            updateUserData(
                new User({ ...user, firstName, lastName, birthday, pronouns })
            )
        );
        history.replace("/profile");
        UsersApi.updateUser(user.id, {
            firstName,
            lastName,
            birthday,
            pronouns,
        });
    };

    return (
        <>
            <FloatingCloseButton />

            <NavigationBar title={"Edit Profile"} backArrowRoute="/profile" />

            <Grid
                className={"mt-2"}
                container
                justify="center"
                direction="column"
                spacing={3}
            >
                <Grid item>
                    <Typography variant="h3" align="center" className="ml-1">
                        General
                    </Typography>

                    <TextField
                        type="text"
                        fullWidth
                        name="username"
                        value={user.username}
                        label="Username"
                        onChange={handleInputChange}
                        placeholder="Write your first name"
                        variant="outlined"
                        disabled
                        className={" mt-2"}
                    />
                    <Typography variant="caption" className="ml-1">
                        Username cannot be changed
                    </Typography>

                    <TextField
                        type="text"
                        fullWidth
                        name="firstName"
                        value={firstName}
                        label="First Name"
                        onChange={handleInputChange}
                        placeholder="Write your first name"
                        variant="outlined"
                        className={" mt-2"}
                    />
                    <TextField
                        type="text"
                        fullWidth
                        name="lastName"
                        value={lastName}
                        label="Last Name"
                        onChange={handleInputChange}
                        placeholder="Write your last name"
                        variant="outlined"
                        className={" mt-2"}
                    />
                    <A3DatePicker
                        name="birthday"
                        openTo={"year"}
                        label={"Date of Birth"}
                        date={birthday}
                        dateChange={handleInputChange}
                    />
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={"mt-2"}
                    >
                        <InputLabel id="for-pronouns">Your pronouns</InputLabel>
                        <Select
                            labelId="for-pronouns"
                            label="Pronouns"
                            value={pronouns}
                            name="pronouns"
                            onChange={handleInputChange}
                        >
                            <MenuItem value={""} selected={pronouns === ""}>
                                <em>I prefer not to say</em>
                            </MenuItem>
                            <MenuItem
                                value={"She/Her/Hers"}
                                selected={pronouns === "She/Her/Hers"}
                            >
                                She/Her/Hers
                            </MenuItem>
                            <MenuItem
                                value={"He/Him/His"}
                                selected={pronouns === "He/Him/His"}
                            >
                                He/Him/His
                            </MenuItem>
                            <MenuItem
                                value={"They/Them/Their"}
                                selected={pronouns === "They/Them/Their"}
                            >
                                They/Them/Their
                            </MenuItem>
                            <MenuItem
                                value={"Ze/Hir/Hirs"}
                                selected={pronouns === "Ze/Hir/Hirs"}
                            >
                                Ze/Hir/Hirs
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Typography
                        variant="h3"
                        align="center"
                        className="ml-1"
                    ></Typography>
                </Grid>
            </Grid>
            <AppBar position="fixed" className={"bottom"}>
                <Grid container justify="center" direction="row">
                    <Button
                        fullWidth
                        className="main-button"
                        onClick={handleSave}
                    >
                        Save profile
                    </Button>
                </Grid>
            </AppBar>
        </>
    );
};
