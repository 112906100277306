import React, { useState } from "react";
import { AppBar, Box, Grid, TextField } from "@material-ui/core";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { useForm } from "../../../hooks/useForm";
import { Challenge } from "../../../models/challenge.model";
import { A3Select } from "../../../components/A3Select/A3Select";
import { RootState } from "../../../redux/store/store";
import { useSelector } from "react-redux";
import { User } from "../../../models/user.model";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStyles } from "./NewPostScreen.styles";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Post } from "../../../models/post.model";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { A3Button } from "../../../components/A3Button/A3Button";
import { routes } from "../../../routers/routes";
import { ChallengesApi } from "../../../apis/challengesApi";
import { PostApi } from "../../../apis/postApi";

export const NewPostScreen = () => {
    const classes = useStyles();
    const user: User = useSelector((state: RootState) => state.auth.user);
    const history = useHistory();

    const [challenges, setChallenges]: any = useState<Challenge[]>([]);
    const [isPosting, setIsPosting]: any = useState<boolean>(false);
    
    const [{ challenge, message }, handleInputChange]: any = useForm({
        challenge: new Challenge(),
        message: "",
        media: "",
    });

    const fileRef = useRef<any>();
    const [formError, setFormError]: any = useState("");

    const [selectedFile, setSelectedFile] = useState<any>();
    const [isFileSelected, setIsFileSelected] = useState(false);
    const fileChangeHandler = async (event: any) => {
        if (event?.target?.files?.length) {
            setSelectedFile(event.target.files[0]);
            setIsFileSelected(true);
        } else {
            setSelectedFile(null);
            setIsFileSelected(false);
        }
    };

    const changeHandler = async () => {
        fileRef.current.click();
    };

    const validatePost = () => {
        setFormError("");
        if (!challenge.name) {
            setFormError("You have to select a challenge.");
            setIsPosting(false);
            return false;
        }
        if (!message) {
            setFormError("You have to share a message.");
            setIsPosting(false);
            return false;
        }

        setTimeout(() => {
            setFormError("");
        }, 5000);

        return true;
    };
    const fetchChallenges = async () => {
        setChallenges(await ChallengesApi.getChallengesList(user.id, 'joined'));
    };

    const handlePublishPost = async () => {
        setIsPosting(true);
        if (!validatePost()) return;
        await PostApi.createPost(
            new Post({ challenge, message, user }),
            selectedFile
        );

        setIsPosting(false);
        setSelectedFile(null);
        setIsFileSelected(false);
        history.replace(routes.MAIN.DASHBOARD);
    };

    useEffect(() => {
        fetchChallenges();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <NavigationBar
                title={"New Post"}
                backArrowRoute={routes.MAIN.DASHBOARD}
            />
            <Grid>
                <A3Select
                    fullWidth
                    name="challenge"
                    label="Select Challenge"
                    classes="mt-2"
                    objectArray={challenges}
                    objectIdProp="id"
                    objectValueProp="name"
                    defaultSelectedObject={challenge}
                    handleChange={handleInputChange}
                />
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    name={"message"}
                    value={message}
                    onChange={handleInputChange}
                    className={"mt-3"}
                    variant={"outlined"}
                    placeholder={"Share Something"}
                    label={"Share Something"}
                />
                <AppBar position="fixed" className={"bottom"}>
                    <Grid container justify="center" direction="row">
                        {formError && (
                            <Grid
                                container
                                justify="center"
                                direction="row"
                            >
                                <Box
                                    className={`${classes.error} mb-2 animate__animated animate__bounce animate__delay-2s`}
                                >
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                        className={classes.errorIcon}
                                    />{" "}
                                    {formError}
                                </Box>
                            </Grid>
                        )}
                        <input
                            ref={fileRef}
                            type="file"
                            name="file"
                            accept="image/*"
                            onChange={fileChangeHandler}
                            hidden
                        />
                        <A3Button
                            fullWidth
                            className="secondary-button mb-1"
                            onClick={changeHandler}
                        >
                            {isFileSelected ? "Change" : "Add"} Picture
                        </A3Button>
                        <A3Button
                            fullWidth
                            className="main-button mt-2 mb-2"
                            onClick={handlePublishPost}
                            isLoading={isPosting}
                            disabled={isPosting}
                        >
                            Publish
                        </A3Button>
                    </Grid>
                </AppBar>
            </Grid>
        </>
    );
};
