import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export const useStyles = (image: string) => makeStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(0.5),
        overflowWrap: 'break-word',
        color: 'green',
        width: '100%',
        minWidth: '100%',
        maxWidth: '100%',
        backgroundColor: '#8f8f8f',
        borderRadius: theme.spacing(2),



    },
    image: {
        width: '95px',
        height: '85px',
        borderRadius: theme.spacing(2, 0, 0, 2),
        backgroundColor: '#fff',
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/activities/${image})`,
        backgroundPosition: '0 0, center',
        backgroundSize: '95px 95px',
    }
}));