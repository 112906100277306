import { Card, Typography } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import annotationPlugin from "chartjs-plugin-annotation";
import { Chart, Doughnut } from "react-chartjs-2";
import { useHistory, useParams } from "react-router";
import { LoadingWrapper } from "../../../components/LoadingWrapper/LoadingWrapper";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { Challenge } from "../../../models/challenge.model";
import { Task } from "../../../models/task.model";
import { User } from "../../../models/user.model";
import {
    RootState,
    useAppDispatch,
    useAppSelector,
} from "../../../redux/store/store";
import { FloatingCloseButton } from "../../../components/FloatingCloseButton/FloatingCloseButton";
import { ChallengesApi } from "../../../apis/challengesApi";
import { Contribution, Contributor } from "../../../models/contributor";
import { ContributionCard } from "./components/ContributionCard";
import { modal } from "../../../components/modal/modal";
import { setChallenge } from "../../../redux/slices/challenges/challenges.slice";

Chart.register(annotationPlugin);

export const CoopChallengeProgressScreen = () => {
    const currentUser: User = useAppSelector(
        (state: RootState) => state.auth.user
    );
    const challenge: Challenge = useAppSelector(
        (state: RootState) => new Challenge(state.challenge.challenge)
    );
    const dispatch = useAppDispatch();
    const { id: challengeId } = useParams<{ id: string }>();

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState("Progress");
    const [contributor, setContributor] = useState<Contributor>(null);
    const [eventsToShow, setEventsToShow] = useState<number>(7);
    const [doughnutData, setDoughnutData] = useState<any>({});
    const [totals, setTotals] = useState({
        totalChallenge: 0,
        totalProgress: 0,
        totalProgressPercentage: 0,
        remainingProgress: 0,
    });

    const isBoolean = [
        "stop-drinking",
        "stop-smoking",
        "vegan",
        "vegetarian",
        "keto",
        "carnivor",
    ].includes(challenge.challengeActivity.id);
    const fetchProgress = async () => {
        const challengeDb = await ChallengesApi.getChallenge(challengeId);
        dispatch(setChallenge(challengeDb));
        const contributorDb = await ChallengesApi.getContributor(
            challengeDb.id,
            currentUser.id
        );
        setContributor(contributorDb);
        let totalChallenge = challengeDb.quantity;
        let totalProgress = challengeDb.quantity - challengeDb.pendingAmount;
        let remainingProgress = challengeDb.pendingAmount;
        let totalProgressPercentage = Number(
            ((totalProgress * 100) / (totalChallenge || 1)).toFixed(0)
        );
        if (isBoolean) {
            totalChallenge = challenge.getChallangeTime(false, "days");
            totalProgress = moment(challenge.endsAt).diff(moment(), "days") + 1;
            remainingProgress = challengeDb.pendingAmount;
            totalProgressPercentage = Number(
                ((totalProgress * 100) / (totalChallenge || 1)).toFixed(0)
            );
        }
        setTotals({
            totalChallenge,
            totalProgress,
            totalProgressPercentage,
            remainingProgress,
        });
        let tempDoneTasks: Task[] = [];

        contributorDb.contributions.sort((a, b) => {
            let isAfter = moment(a.date).isAfter(b.date);
            return isAfter ? 1 : -1;
        });

        const starts =
            tempDoneTasks.length >= eventsToShow
                ? tempDoneTasks.length - eventsToShow
                : 0;
        const ends = tempDoneTasks.length;
        tempDoneTasks = tempDoneTasks.slice(starts, ends);

        if (tempDoneTasks.length < eventsToShow)
            setEventsToShow(tempDoneTasks.length);

        if (!tempDoneTasks.length) {
            setLoading(false);
        }
        const labels: string[] = [];
        const actualData: number[] = [];
        tempDoneTasks.forEach((t) => {
            labels.push(moment(t.date).format("DD-MMM"));
            actualData.push(t.actualQuantity);
        });

        setDoughnutData({
            labels: ["Progress", "Remaining"],
            datasets: [
                {
                    label: challengeDb.challengeActivity.name,
                    data: [totalProgress, remainingProgress],
                    backgroundColor: ["#78D008", "#303030"],
                    borderColor: ["#78D008", "#78D008"],
                    borderWidth: 1,
                },
            ],
        });
        setLoading(false);
    };

    const removeContribution = async (contribution: Contribution) => {
        const answer = await modal(
            "question",
            "",
            `Are you sure you want to remove this contribution?.`
        );
        if (answer !== "ok") return;

        await ChallengesApi.removeContribution(
            challenge.id,
            currentUser.id,
            contribution
        );
        fetchProgress();
    };

    useEffect(() => {
        if (challenge.id !== challengeId)
            history.replace("/challenge/" + challengeId);

        fetchProgress();
        setTitle(`${challenge.name}`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <FloatingCloseButton />
            <NavigationBar
                title={title}
                backArrowRoute={"/challenge/" + challengeId}
            />
            <LoadingWrapper isLoading={loading} type="fullscreen">
                <>
                    <Card className={"curved mt-2 mb-2"}>
                        <Typography
                            variant="body1"
                            align="center"
                            className="mt-1"
                        >
                            Total Progress {totals.totalProgressPercentage} %
                        </Typography>
                        <Typography
                            variant="body1"
                            align="center"
                            className={"mb-2"}
                        >
                            {totals.totalProgress} of {totals.totalChallenge}{" "}
                            {isBoolean && 'days left of the challenge'}
                        </Typography>

                        <Doughnut
                            data={doughnutData}
                            width={100}
                            height={100}
                        />
                    </Card>
                    <Typography variant="body1" className={"mt-1 mb-2"}>
                        Your total contribution: {contributor?.total}{" "}
                        {contributor?.contributions?.length
                            ? contributor?.contributions[0].unit.replace(
                                  "none",
                                  "days"
                              )
                            : ""}
                    </Typography>
                    {contributor?.contributions.map((c, index) => {
                        return (
                            <ContributionCard
                                index={index}
                                key={index}
                                className={"curved mt-2 mb-2"}
                                contribution={c}
                                deleteContribution={removeContribution}
                            />
                        );
                    })}
                </>
            </LoadingWrapper>
        </>
    );
};
