import { faFrown, faLaughBeam, faMeh, faSadTear, faSmileBeam } from "@fortawesome/free-solid-svg-icons";

export enum Emotion {
    awesome = 5,
    great = 4,
    normal = 3,
    not_good = 2,
    sad = 1,
}

export class JournalEntry {

    id: string = '';
    userId: string = '';
    emotion: Emotion = Emotion.normal
    entry: string = ''
    date: number = new Date().getTime()

    constructor(obj?: Partial<JournalEntry>) {
        this.id = obj?.id || this.id
        this.userId = obj?.userId || this.userId
        this.emotion = obj?.emotion || this.emotion
        this.entry = obj?.entry || this.entry
        this.date = obj?.date || this.date
    }

    static getIcon(emotion: Emotion) {
        switch (emotion) {
            case Emotion.sad:
                return faSadTear
            case Emotion.not_good:
                return faFrown
            case Emotion.normal:
                return faMeh
                case Emotion.great:
                return faSmileBeam
            case Emotion.awesome:
                return faLaughBeam
        }
    }



}