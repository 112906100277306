import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Challenge } from "../../../models/challenge.model";
import { ChallengesApi } from "../../../apis/challengesApi";
import { baseApi } from "../baseApi";
import { ChallengeActivity } from "../../../models/challengeActivity.model";


let initialState = { challenge: Challenge.createEmpty() };
const localChallenge = localStorage.getItem('challenge')
if (localChallenge) {
    initialState = { challenge: JSON.parse(localChallenge) }
}

//Reducer
const challengesSlice = createSlice({
    name: 'challenge',
    initialState,
    reducers: {
        setChallenge(state, action: PayloadAction<Partial<Challenge>>) {
            state.challenge = { ...state.challenge, ...action.payload } as Challenge;
            localStorage.setItem('challenge', JSON.stringify(state.challenge))
        },
        setActivity(state, action: PayloadAction<ChallengeActivity>) {
            state.challenge.challengeActivity = action.payload
            localStorage.setItem('challenge', JSON.stringify(state.challenge))
        },
        clearChallenge(state) {
            state.challenge = Challenge.createEmpty();
            localStorage.setItem('challenge', JSON.stringify(state.challenge))
        },
        setProperty(state, action: PayloadAction<{ key: string, value: any }>) {
            state.challenge = {
                ...state.challenge,
                [action.payload.key]: action.payload.value
            } as Challenge;
            localStorage.setItem('challenge', JSON.stringify(state.challenge))
        },
    },
})

// API
export const challengesApiSlice = baseApi.injectEndpoints({
    endpoints(builder) {
        return {
            addParticipantToChallenge: builder.mutation<Challenge, { challengeId: string, userId: string, directJoin: boolean }>({
                queryFn: async ({ challengeId, userId, directJoin = false }) => {
                    const newChallenge = await ChallengesApi.addParticipantToChallenge(challengeId, userId, directJoin)
                    return { data: newChallenge }
                },
            }),
            removeParticipantFromChallenge: builder.mutation<Challenge, { challengeId: string, userId: string }>({
                queryFn: async ({ challengeId, userId }) => {
                    const newChallenge = await ChallengesApi.removeParticipantFromChallenge(challengeId, userId)
                    return { data: newChallenge }
                }
            }),
            updateChallenge: builder.mutation<Challenge, { challenge: Partial<Challenge> }>({
                queryFn: async ({ challenge }) => {
                    const newChallenge = await ChallengesApi.updateChallenge(challenge)
                    return { data: newChallenge }
                }
            }),
            deleteChallenge: builder.mutation<void, { idChallenge: string }>({
                queryFn: async ({ idChallenge }) => {
                    await ChallengesApi.deleteChallenge(idChallenge)
                    return { data: null }
                }
            }),
            createChallenge: builder.mutation<Challenge, { challenge: Partial<Challenge> }>({
                queryFn: async ({ challenge }) => {
                    const newChallenge = await ChallengesApi.createChallenge(challenge)
                    return { data: newChallenge }
                }
            }),
            getChallenge: builder.query<Challenge, { challengeId: string }>({
                queryFn: async ({ challengeId }) => {
                    const challenge = await ChallengesApi.getChallenge(challengeId)
                    return { data: challenge }
                }
            }),
            getChallenges: builder.query<Challenge[], { userId: string }>({
                queryFn: async ({ userId }) => {
                    const challenges = await ChallengesApi.getChallenges(userId)
                    return { data: challenges }
                }
            }),
            getChallengesList: builder.query<Challenge[], { userId: string, type?: 'ongoing' | 'public' | 'joined' | 'invited' }>({
                queryFn: async ({ userId, type }) => {
                    const challenges = await ChallengesApi.getChallengesList(userId, type)
                    return { data: challenges }
                }
            }),
        }
    }
})

export const {
    useAddParticipantToChallengeMutation,
    useRemoveParticipantFromChallengeMutation,
    useUpdateChallengeMutation,
    useDeleteChallengeMutation,
    useCreateChallengeMutation,
    useGetChallengeQuery,
    useGetChallengesQuery,
    useGetChallengesListQuery,

} = challengesApiSlice
export const { clearChallenge, setChallenge, setActivity } = challengesSlice.actions
export default challengesSlice.reducer