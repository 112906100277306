import React, { useLayoutEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { RootState, useAppSelector } from "../../../../redux/store/store";
import { User } from "../../../../models/user.model";
import { SelectTextCards } from "../../../../components/SelectTextCards/SelectTextCards";
import { useState } from "react";
import { updateUserData } from "../../../../redux/slices/auth/auth.slice";
import { useDispatch } from "react-redux";

export const UserPreferences = () => {
    const user: User = useAppSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()
    const userPreferencesInitial = [
        { text: "Be the best version of myself", selected: false },
        { text: "Mental clarity and acuity", selected: false },
        { text: "More Energy", selected: false },
        { text: "Wellness", selected: false },
        { text: "Step outside of my comfort zone", selected: false },
    ];
    const readInitialPreferences = () => {
        userPreferencesInitial.forEach((p: any, i: number) => {
            if (user.userPreferences.includes(p.text))
                userPreferencesInitial[i].selected = true;
        });

        return userPreferencesInitial;
    };
    const [userPreferences, setUserPreferences] = useState(
        readInitialPreferences()
    );

    const userPreferences2StringArray = () => {
        const preferences: string[] = [];
        userPreferences.forEach((p) => {
            if (p.selected) preferences.push(p.text);
        });
        return preferences;
    };

    useLayoutEffect(() => {

        const userPreferencesArray = userPreferences2StringArray()
        dispatch(updateUserData({ userPreferences: userPreferencesArray }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPreferences]);

    return (
        <Grid container justify="center" direction="column" spacing={3}>
            <Grid item>
                <Typography variant={"h1"} align="center" className={"mt-2"}>
                    {user.firstName}!
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={"h2"} align="left">
                    How can A3 community help you?
                </Typography>
            </Grid>

            <SelectTextCards
                cards={userPreferences}
                handleChange={setUserPreferences}
            />
        </Grid>
    );
};
