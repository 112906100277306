import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JournalApi } from "../../../apis/journalApi";

import { JournalEntry } from '../../../models/journalEntry.model'
import { baseApi } from "../baseApi";

interface JournalState {
    journalEntry: JournalEntry
}

const initialState: JournalState = {
    journalEntry: null,
};

//Reducer
const journalSlice = createSlice({
    name: 'journal',
    initialState,
    reducers: {
        setJournal(state, action: PayloadAction<JournalEntry>) {
            state.journalEntry = action.payload

        },

    }
})

export const journalApiSlice = baseApi.injectEndpoints({
    endpoints(builder) {
        return {
            getJournal: builder.query<JournalEntry[], { userId: string }>({
                queryFn: async ({ userId }) => {
                    const jorunal = await JournalApi.getJournal(userId)
                    return { data: jorunal }
                },
                providesTags: ['journal'],
                keepUnusedDataFor: 5 * 60 * 60
            }),
            saveJournalEntry: builder.mutation<JournalEntry, { entry: Partial<JournalEntry> }>({
                queryFn: async ({ entry }) => {
                    const entryDB = await JournalApi.postEntry(entry)
                    return { data: entryDB }
                },
                invalidatesTags: ['journal']
            }),
            removeJournalEntry: builder.mutation<void, { entryId: string }>({
                queryFn: async ({ entryId }) => {
                    await JournalApi.removeJournalEntry(entryId)
                    return { data: null }
                },
                invalidatesTags: ['journal']
            }),
        }
    }
})

export const {
    useGetJournalQuery,
    useRemoveJournalEntryMutation,
    useSaveJournalEntryMutation
} = journalApiSlice


export const {
    setJournal,

} = journalSlice.actions

export default journalSlice.reducer