import React, { useState } from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid } from "@material-ui/core";
import moment from "moment";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { modal } from "../../../components/modal/modal";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { StepDots } from "../../../components/StepDots/StepDots";
import { Challenge } from "../../../models/challenge.model";
import store from "../../../redux/store/store";
import { NewChallengeActivity } from "./components/NewChallengeActivity";
import { NewChallengeCategory } from "./components/NewChallengeCategory";
import { NewChallengeDates } from "./components/NewChallengeDates";
import { NewChallengeName } from "./components/NewChallengeName";
import { NewChallengeQuantity } from "./components/NewChallengeQuantity";
import { NewChallengeSummary } from "./components/NewChallengeSummary";
import { useStyles } from "./NewChallengeScreen.styles";
import { FloatingCloseButton } from "../../../components/FloatingCloseButton/FloatingCloseButton";
import { A3Button } from "../../../components/A3Button/A3Button";
import {
    useCreateChallengeMutation,
    useUpdateChallengeMutation,
} from "../../../redux/slices/challenges/challenges.slice";

export const NewChallengeScreen = () => {
    const classes = useStyles();

    const [createChallenge] = useCreateChallengeMutation();
    const [updateChallenge] = useUpdateChallengeMutation();

    const history = useHistory();
    const { step } = useParams<any>();
    const stepURL = Number(step);

    const [formError, setFormError]: any = useState("");

    const [title, setTitle] = useState<string>("New Challenge");
    const [steps] = useState<number>(7);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const name = (challenge: Challenge) => {
        if (!challenge.name) {
            setFormError("You must write a name for your challenge");
            return false;
        }
        return true;
    };
    const Category = (challenge: Challenge) => {
        if (!challenge.challengeCategory.id) {
            setFormError("You must select a category for your challenge");
            return false;
        }
        return true;
    };
    const Activity = (challenge: Challenge) => {
        if (!challenge.challengeActivity.id) {
            setFormError("You must select an activity for your challenge");
            return false;
        }
        return true;
    };
    const Quantity = (challenge: Challenge) => {
        if (!challenge.unit || !challenge.interval || !challenge.quantity) {
            setFormError("You must select a unit, a interval and a quantity");
            return false;
        }
        if (
            isNaN(Number(challenge.quantity)) ||
            Number(challenge.quantity) <= 0
        ) {
            setFormError("The quantity should be greater that zero.");
            return false;
        }
        return true;
    };
    const Dates = (challenge: Challenge) => {
        const from = moment(challenge.startsAt);
        const to = moment(challenge.endsAt);

        if (from.diff(to) >= 0) {
            setFormError("The start date should be after the end date.");
            return false;
        }
        if (to.diff(from, "days") > 90) {
            setFormError(
                "At the moment the maximun a challenge can last is 90 days tops."
            );
            return false;
        }

        return true;
    };

    const isValid = (stepToCheck: number) => {
        const challenge = store.getState().challenge.challenge;
        switch (stepToCheck) {
            case 1:
                return name(challenge);
            case 2:
                return Category(challenge);
            case 3:
                return Activity(challenge);
            case 4:
                return Quantity(challenge);
            case 5:
                return Dates(challenge);
            default:
                break;
        }
        return true;
    };
    const handleBack = () => {
        localStorage.setItem("direction", "back");
        history.push(`/challenges/new/${stepURL - 1}`);
    };

    const handleNext = async () => {
        const challenge = store.getState().challenge.challenge;

        localStorage.setItem("direction", "next");

        if (!isValid(stepURL)) return;

        if (stepURL === 6) {
            setIsSaving(true);
            const answer = await modal(
                "question",
                "",
                challenge.id
                    ? `Update the challenge?`
                    : `Save the new challenge?`
            );
            if (answer === "ok") {
                try {
                    const { id: challengeId } = challenge.id
                        ? await updateChallenge({ challenge }).unwrap()
                        : await createChallenge({ challenge }).unwrap();

                    history.push(`/challenge/${challengeId}/participants`);
                    setIsSaving(false);
                } catch (error) {
                    console.log(error)
                    setIsSaving(false);
                }
            }

            setIsSaving(false);

            return;
        }

        history.push(`/challenges/new/${stepURL + 1}`);
    };
    useEffect(() => {
        if (isNaN(stepURL) || stepURL < 1 || stepURL > steps)
            history.push(`/challenges/new/1`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const challenge = store.getState().challenge.challenge;

        switch (stepURL) {
            case 1:
                setTitle("New Challenge");
                break;
            case 2:
                setTitle("Category");
                break;
            case 3:
                setTitle("Activity");
                break;
            case 4:
                setTitle(challenge.challengeActivity.name || "Quantity");
                break;
            case 5:
                setTitle("Duration");
                break;
            case 6:
                setTitle("Summary");
                break;
            case 7:
                setTitle("Invite People");
                break;

            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepURL]);

    return (
        <>
            <NavigationBar title={title} showBackButton={false} />
            {!isSaving && <FloatingCloseButton />}
            {stepURL === 1 && <NewChallengeName />}
            {stepURL === 2 && <NewChallengeCategory />}
            {stepURL === 3 && <NewChallengeActivity />}
            {stepURL === 4 && <NewChallengeQuantity />}
            {stepURL === 5 && <NewChallengeDates />}
            {stepURL === 6 && <NewChallengeSummary />}
            <Box className={classes.bottomNavbar}>
                {formError && (
                    <Grid
                        container
                        justify="center"
                        direction="row"
                        className={"mt-1 mb-1"}
                    >
                        <Box
                            className={`${classes.error} animate__animated animate__bounce animate__delay-2s`}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className={classes.errorIcon}
                            />{" "}
                            {formError}
                        </Box>
                    </Grid>
                )}
                <Grid
                    container
                    justify="center"
                    direction="row"
                    className={"mb-2"}
                >
                    <StepDots amount={steps} current={stepURL - 1} />
                </Grid>
                <Grid container justify="center" direction="row">
                    <Grid item xs={6} className={"pr-1"}>
                        {stepURL > 1 && (
                            <Button
                                fullWidth
                                className="secondary-button"
                                onClick={handleBack}
                            >
                                back
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={6} className={"pl-1"}>
                        <A3Button
                            fullWidth
                            className="main-button"
                            onClick={handleNext}
                            isLoading={isSaving}
                            disabled={isSaving}
                        >
                            {stepURL !== 6 ? "next" : "SAVE"}
                        </A3Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
