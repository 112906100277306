import { Grid, TextField, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useForm } from "../../../../hooks/useForm";
import { updateUserData } from "../../../../redux/slices/auth/auth.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/store";


export const EmployeeCode = () => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector((s) => s.auth.user);
    const initialState = {
        employeeCode: currentUser?.employeeCode,
        companyId: currentUser?.companyId,
    };
    const [{ employeeCode, companyId }, handleInputChange] =
        useForm(initialState);

    useEffect(() => {
        dispatch(
            updateUserData({
                employeeCode,
                companyId,
            })
        );
    }, [employeeCode, companyId, dispatch]);

    return (
        <Grid container justify="center" direction="column" spacing={3}>
            <Grid item className="mt-4">
                <Typography variant="h2" align="center">
                    Are you in a company?
                </Typography>
            </Grid>
            <Grid item className="mt-1">
                <Typography variant="body1" align="center">
                    If your employer gave you a code to register please add it in this step, otherwise leave it empty and tap on the skip option. 
                </Typography>
            </Grid>
            <Grid item>
                <TextField
                    type="text"
                    fullWidth
                    name="companyId"
                    value={companyId}
                    label="Company Code"
                    onChange={handleInputChange}
                    placeholder="Company Code"
                    variant="outlined"
                    className="mt-2"
                    tabIndex={1}
                />
            </Grid>
            <Grid item>
                <TextField
                    type="text"
                    fullWidth
                    name="employeeCode"
                    value={employeeCode}
                    label="Employee Number"
                    onChange={handleInputChange}
                    placeholder="Type your employee number"
                    variant="outlined"
                    className="mt-2"
                    tabIndex={2}
                />
            </Grid>
        </Grid>
    );
};
