import { JournalEntry } from "../models/journalEntry.model";
import store from "../redux/store/store";
import { A3Server } from "../utils/fetch";

export class JournalApi {

    static postEntry = async (entry: Partial<JournalEntry>): Promise<JournalEntry> => {

        if (!entry) {
            console.error("No entry...", entry);
            throw new Error("No entry...");
        }
        if (!entry.userId) entry.userId = store.getState().auth.user.id


        try {
            const a3Server = new A3Server();

            const journalEntry = await a3Server.post<JournalEntry>(
                `v1/journals/${entry.userId}/entries`,
                { entry }
            );

            return journalEntry

        } catch (e) {
            console.log(e);
            throw e;
        }
    }
    static getJournal = async (userId: string): Promise<JournalEntry[]> => {
        try {

            const a3Server = new A3Server()
            const journal = await a3Server.get<JournalEntry[]>(`v1/journals/${userId}`)

            return journal

        } catch (error) {
            throw error
        }

    }
    static removeJournalEntry = async (entryId: string): Promise<void> => {
        try {

            const userId = store.getState().auth?.user?.id

            const a3Server = new A3Server()
            await a3Server.delete<JournalEntry[]>(`v1/journals/${userId}/entries/${entryId}`)

            

        } catch (error) {
            throw error
        }

    }

}