import { AppBar } from '@material-ui/core'
import React from 'react'
import { useStyles } from './A3AppBar.style'

interface Props {
    className?: string
    children: JSX.Element
}

export const A3AppBar = ({children, className}:Props) => {
    const classes = useStyles()
    return (
        <>
        <AppBar position="fixed" className={'bottom ' + className}>
        <div className={classes.background}></div>
            {children}
        </AppBar>
            
        </>
    )
}
