import React, { MouseEvent, useState } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Logo } from "../../components/Logo/Logo";
import { useStyles } from "./LoginScreen.styles";
import { SocialMediaButton } from "../../components/SocialMediaButton/SocialMediaButton";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useHistory } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import validator from "validator";
import { modal } from "../../components/modal/modal";
import {
    resetPassword,
    signInUserFirebase,
    startFacebookLogin,
    startGoogleLogin,
} from "../../providers/firebase";
import { A3Button } from "../../components/A3Button/A3Button";

export const LoginScreen = () => {
    const classes = useStyles();

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [{ email, password }, handleInputChange, , setFormValues] = useForm({
        email: window.location.href.includes('localhost') ? "alexis.otsa@gmail.com" : '',
        password: window.location.href.includes('localhost') ? "Oasa2306" : '',
    });

    const handleKeydown = (event: any) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            handleLogin();
        }
    };
    const googleLogin = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
        startGoogleLogin();
    };
    const facebookLogin = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
        startFacebookLogin();
    };
    const handleResetPassword = () => {
        if (!validator.isEmail(email)) {
            modal(
                "error",
                "Error",
                "Please input your email and we will send you instructions to reset you password."
            );
            return;
        }
        resetPassword(email);
        setFormValues("email", "");
    };
    const handleRegister = () => {
        history.replace("/register");
    };
    const handleLogin = () => {
        setIsLoading(true)
        signInUserFirebase(email, password);
        setTimeout(() => {
            
            setIsLoading(false)
        }, 5000);
    };

    return (
        <Grid container justify="center" direction="column" spacing={2}>
            <Grid container justify="center" direction="row">
                <Logo className={classes.logo + " mt-8 mb-4"} />
            </Grid>
            <Grid item>
                <TextField
                    type="email"
                    name="email"
                    value={email}
                    label="Email"
                    onChange={handleInputChange}
                    placeholder="Write your email"
                    variant="outlined"
                    className={classes.input + " mt-2"}
                />
                <TextField
                    type="password"
                    name="password"
                    value={password}
                    label="Password"
                    onChange={handleInputChange}
                    placeholder="Your password"
                    variant="outlined"
                    className={classes.input + " mt-2"}
                    onKeyPress={(e) => {
                        handleKeydown(e);
                    }}
                />
            </Grid>
            <Grid item container justify={"flex-end"}>
                <Button
                    className={classes.resetButton}
                    onClick={handleResetPassword}
                >
                    I don't remember my password
                </Button>
            </Grid>
            <Grid item>
                <A3Button isLoading={isLoading} fullWidth className="main-button" onClick={handleLogin}>
                    Login
                </A3Button>
            </Grid>
            <Grid
                item
                container
                justify="center"
                direction="row"
                spacing={2}
            >
                <Grid item>
                    <SocialMediaButton
                        handleClick={googleLogin}
                        icon={faGoogle}
                    />
                </Grid>
                <Grid item>
                    <SocialMediaButton
                        handleClick={facebookLogin}
                        icon={faFacebook}
                    />
                </Grid>
            </Grid>
            <div className={classes.createAccount}>
                <Grid
                    container
                    justify="center"
                    direction="row"
                    className={"mb-1"}
                >
                    <Typography variant="subtitle2">Are you new?</Typography>
                </Grid>
                <Grid container justify="center" direction="row">
                    <Button
                        fullWidth
                        className="secondary-button"
                        onClick={handleRegister}
                    >
                        CREATE NEW ACCOUNT
                    </Button>
                </Grid>
                <div className={classes.version}>v 0.0.18</div>
            </div>
        </Grid>
    );
};
