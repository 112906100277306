import { Button } from "@material-ui/core";
import { type } from "os";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/store/store";

type Props = {
    handleNext: () => void;
    step: number;
};

export const NextButton = ({ step, handleNext }: Props) => {
    const currentUser = useAppSelector(s=> s.auth.user)
    const [text, setText] = useState("Next");
    
    useEffect(() => {
        switch (step) {
            case 2: 
                if(!currentUser.companyId) {
                    setText("Skip") 
                    break;
                } 
                setText("Verify Company") 
                break;
            case 3: setText("finish"); break;
        
            default:
                setText("Next")
                break;
        }
    }, [step, currentUser.companyId]);

    return (
        <Button fullWidth className="main-button" onClick={handleNext}>
            {text}
        </Button>
    );
};
