import React, { useState } from "react";
import { useStyles } from "./DatePicker.style";
import { DateTimePicker } from "@material-ui/pickers";
import { Grid, Toolbar, Typography } from "@material-ui/core";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Moment } from "moment";
import { modal } from "../modal/modal";

interface A3DatePickerProps {
    date: Date;
    onBlur?: Function;
    dateChange: (data: {
        target: {
            name: string;
            value: string | number;
        };
    }) => void;
    name: string;
    label: string;
    headerFormat?: "date" | "date-time" | "time";
    disableFuture?: boolean;
    disablePast?: boolean;
    openTo?: "year" | "date" | "month" | "hours" | "minutes";
    orientation?: "portrait" | "landscape";
    format?: string;
    className?: string;
    views?: Array<"year" | "date" | "month" | "hours" | "minutes">;
}

export const A3DatePicker = React.forwardRef(
    (
        {
            date,
            dateChange,
            onBlur,
            name,
            label,
            disableFuture = false,
            disablePast = false,
            headerFormat = "date",
            openTo = "month",
            orientation = "portrait",
            format = "MM/DD/yyyy",
            className,
            views = ["date"],
        }: A3DatePickerProps,
        ref
    ) => {
        const classes = useStyles();
        const [selectedDate, SetSelectedDate] = useState<number>(
            new Date(date).getTime()
        );
        
        const handleDateChange = (e: Moment) => {
            if (!e) return;
            let timeStamp = e.toDate().getTime();
          
            if (disableFuture && timeStamp > new Date().getTime()) {
                modal(
                    "error",
                    "Invalid Date",
                    "You can't select a time in the future."
                );
                return;
            }
            
            dateChange({
                target: {
                    name,
                    value: timeStamp,
                },
            });
            SetSelectedDate(timeStamp);
        };

        const handleOnBlur = (
            e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
            onBlur && onBlur(e);
        };

        const getSubHeaderFormat = () => {
            switch (headerFormat) {
                case "date":
                    return "ddd, MMM DD";
                case "date-time":
                    return "HH:mm";
                case "time":
                    return "HH:mma";
                default:
                    return "ddd, MMM DD";
            }
        };
        const getHeaderFormat = () => {
            switch (headerFormat) {
                case "date":
                    return "YYYY";
                case "date-time":
                    return "MMM DD, YYYY";
                case "time":
                    return "";
                default:
                    return "YYYY";
            }
        };

        // const toggleAMPM = () => {
        //     const current = moment(selectedDate).format("a");
        //     let newDate = moment(selectedDate);
        //     if (current === "am") newDate = newDate.add(12, "hours");
        //     if (current === "pm") newDate = newDate.add(-12, "hours");
            
        //     SetSelectedDate(newDate.toDate().getTime());
        // };

        return (
            <DateTimePicker
                ampm={false}
                inputRef={ref}
                disableFuture={disableFuture}
                disablePast={disablePast}
                openTo={openTo}
                className={classes.input + " mt-2 " + className}
                inputVariant={"outlined"}
                format={format}
                label={label}
                views={views}
                value={selectedDate}
                minutesStep={5}
                showTodayButton
                todayLabel="now"
                onChangeCapture={(e) => {
                    console.log(e);
                }}
                onChange={(e) => {
                    handleDateChange(e);
                }}
                onBlur={(e) => {
                    handleOnBlur(e);
                }}
                rightArrowIcon={<FontAwesomeIcon icon={faAngleRight} />}
                leftArrowIcon={<FontAwesomeIcon icon={faAngleLeft} />}
                ToolbarComponent={(e) => (
                    <Toolbar className={classes.datePickerToolbar}>
                        <Grid container direction="column">
                            <Typography
                                variant={"h2"}
                                className={classes.year + " mt-2"}
                                onClick={() => {
                                    e.setOpenView("year");
                                }}
                            >
                                {e.date?.format(getHeaderFormat())}
                            </Typography>
                            <Grid
                                item
                                container
                                direction="row"
                                wrap="nowrap"
                                justify="space-between"
                            >
                                <Typography
                                    variant={"h1"}
                                    className="mt-2 mb-2"
                                    onClick={() => {
                                        e.setOpenView("month");
                                    }}
                                >
                                    {e.date?.format(getSubHeaderFormat())}
                                </Typography>
                                {/* {headerFormat.includes("time") && (
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        wrap="nowrap"
                                        justify="flex-end"
                                    >
                                        <Typography
                                            variant={"h1"}
                                            color={
                                                selectedAMPM === "PM"
                                                    ? "textSecondary"
                                                    : "initial"
                                            }
                                            className="mt-2 mb-2 pr-2"
                                            onClick={() => {
                                                toggleAMPM();
                                            }}
                                        >
                                            AM
                                        </Typography>
                                        <Typography
                                            variant={"h1"}
                                            color={
                                                selectedAMPM === "AM"
                                                    ? "textSecondary"
                                                    : "initial"
                                            }
                                            className="mt-2 mb-2"
                                            onClick={() => {
                                                toggleAMPM();
                                            }}
                                        >
                                            PM
                                        </Typography>
                                    </Grid>
                                )} */}
                            </Grid>
                        </Grid>
                    </Toolbar>
                )}
            />
        );
    }
);
