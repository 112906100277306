import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = ( props = {} ) => {
    //const matches = useMediaQuery('(min-width:450px)');
    const styles = makeStyles( ( theme: any ) =>
        createStyles( {
            root: {

            },
            input: {
                color: 'white',
                marginTop: '16px',
                width: '100%'
            },
            year: {
                color: theme.palette.secondary.contrastText
            },
            datePickerToolbar: {
                color: 'white',
                backgroundColor: theme.palette.secondary.light
            },
        } )
    );
    return styles();
};
