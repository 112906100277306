import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles( ( theme: Theme ) => ( {
    root: {
        position: 'fixed',
        top: theme.spacing( 0 ),
        right: theme.spacing( 0 ),
        color: 'white',
        padding: theme.spacing( 1 ),
        fontSize: '20px',
        zIndex: 100000,
    },
} ) );