import React, { useState } from "react";
import { useStyles } from "./PostCard.style";
import { Post } from "../../models/post.model";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import { UserAvatar } from "../UserAvatar/UserAvatar";
import moment from "moment";
import { PostCardImage } from "./PostCardImage";
import { PostActions } from "../PostActions/PostActions";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { modal } from "../modal/modal";
import { RootState, useAppSelector } from "../../redux/store/store";
import { useUtils } from "../../utils/useUtils";
import { PostApi } from "../../apis/postApi";
import clsx from 'clsx'
import { ReportAbuseModal } from "../ReportAbuseModal/ReportAbuseModal";
import { AbuseReportApi } from "../../apis/abuseReportApi";
interface Props {
    post: Post;
    notifyPostDelete: () => void;
    className: string;
}

export const PostCard = ({ post, className, notifyPostDelete }: Props) => {
    const currentUser = useAppSelector((state: RootState) => state.auth.user);
    const [openReportAbuseModal, setOpenReportAbuseModal] = useState(false);
    const isOwn = post.user.id === currentUser.id;
    const { cutLongText } = useUtils();
    const classes = useStyles();
    const history = useHistory();

    const reportAbuse = async (response: string) => {
        
        setOpenReportAbuseModal(false)
        if(!response) return

        await AbuseReportApi.sendReport(post.id, response)

        modal('alert','Abuse Report Sent', 'Thank you for submitting the report it help up build the best community, our staff will review it and take action on it. If you wish to do a follow up on this contact moderator@a3challanges.com')
        localStorage.setItem('abuse-report', moment().format('DD-MM-YYYY'))
    }
    const handleDelete = async () => {
        const answer = await modal(
            "question",
            "Delete",
            "Are you sure you want to delete this post? This action can't be undone"
        );
        if (answer !== "ok") return;

        await PostApi.deletePost(post);
        notifyPostDelete();
    };
    const goToChallenge = () => {
        history.push(`/challenge/${post.challenge.id}`);
    };
    const handleClick = () => {
        history.push(`/my-community/posts/${post.id}`);
    };

    return (
        <>
        <Grid container direction="column" className={clsx(classes.container, className)}>
            <Grid container direction="row">
                <Grid item xs={9}>
                    <Grid container direction="row">
                        <Grid item>
                            <UserAvatar user={post.user} />
                        </Grid>
                        <Grid item className={"ml-1"}>
                            <Grid container direction="column">
                                <Typography variant="body2">
                                    {post?.user?.firstName}{" "}
                                    {post?.user?.lastName}
                                </Typography>
                                <Typography variant={"overline"}>
                                    @{post?.user?.username} -{" "}
                                    {moment(post?.createdAt).format(
                                        "DD MMM yyyy HH:mm"
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {isOwn && (
                    <Grid
                        item
                        xs={3}
                        container
                        direction="row"
                        justify={"flex-end"}
                        className={"pr-2"}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            className={classes.icon}
                            onClick={handleDelete}
                        />
                    </Grid>
                )}
                 {!isOwn && (
                    <Grid item xs={3}>
                        <Button
                           onClick={()=>setOpenReportAbuseModal(true)}
                            className={clsx('text-button-subtitle')}
                        >
                            Report Abuse
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid item>
                <Card className={classes.card} onClick={handleClick}>
                    <Grid container direction="row" spacing={1}>
                        {post.mediaFileURL ? (
                            <>
                                <Grid item xs={4}>
                                    <PostCardImage post={post} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant={"subtitle2"}>
                                        {post.message}
                                    </Typography>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <Typography variant={"subtitle2"}>
                                    {post.message}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Card>
            </Grid>
            <Grid item container justify={"flex-end"}>
                <Typography
                    variant={"subtitle1"}
                    className={"mb-1"}
                    onClick={goToChallenge}
                >
                    From: {cutLongText(post.challenge.name, 40)}
                </Typography>
            </Grid>
            <Grid item className="mt-2">
                <PostActions post={post} />
            </Grid>
        </Grid>
        <ReportAbuseModal open={openReportAbuseModal} onSendReport={reportAbuse} onCancel={()=>setOpenReportAbuseModal(false)}/>
        </>
    );
};
