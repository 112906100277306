import ReactDOM from "react-dom";
import {
    Box,
    Button,
    Grid,
    Modal,
    TextField,
    ThemeProvider,
    Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import theme from "../../theme";
import { useStyles } from "./modal.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

type ModalTypes =
    | "question"
    | "alert"
    | "error"
    | "modal"
    | "modal-edit"
    | "modal-edit-boolean"
    | "modal-add-one"
    | "modal-edit-progressive"
    | "modal-add-friend"
    | "modal-edit-track";

type ModalResponse = "ok" | "cancel" | "close" | "reset" | number | string;

/**
 *
 * @param type
 * @param title
 * @param body
 * @param children
 * @returns
 */

export const modal = (
    type: ModalTypes,
    title?: string,
    body?: string,
    children?: JSX.Element,
    timeout: number = 0
): Promise<ModalResponse> => {
    if ((type === "alert" || type === "question") && !body && !title) {
        throw Error("Alert and question should have a body or a title.");
    }
    if (type === "modal" && !children) {
        throw Error("Modals should have a children (content).");
    }

    return new Promise((res, rej) => {
        try {
            const returnValueHandle = (value: number | string) => {
                ReactDOM.unmountComponentAtNode(
                    document.getElementById("modal") as Element
                );
                res(value);
            };
            const okHandle = () => {
                ReactDOM.unmountComponentAtNode(
                    document.getElementById("modal") as Element
                );
                res("ok");
            };
            const closeHandle = () => {
                ReactDOM.unmountComponentAtNode(
                    document.getElementById("modal") as Element
                );
                res("close");
            };
            const cancelHandle = (type: "cancel" | "reset" = "cancel") => {
                ReactDOM.unmountComponentAtNode(
                    document.getElementById("modal") as Element
                );
                res(type);
            };

            ReactDOM.render(
                <ThemeProvider theme={theme}>
                    <ModalComponent
                        okHandle={okHandle}
                        closeHandle={closeHandle}
                        cancelHandle={cancelHandle}
                        returnValueHandle={returnValueHandle}
                        type={type}
                        timeout={timeout}
                        title={title}
                        body={body}
                        children={children}
                    />
                </ThemeProvider>,
                document.getElementById("modal")
            );
        } catch (error) {
            rej(error);
        }
    });
};

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
interface ModalComponentPropsTypes {
    okHandle: Function;
    closeHandle: Function;
    cancelHandle: Function;
    returnValueHandle: Function;
    type: ModalTypes;
    title?: string;
    body?: string;
    timeout?: number;
    children?: JSX.Element;
}
const ModalComponent = (props: ModalComponentPropsTypes) => {
    const {
        okHandle,
        closeHandle,
        cancelHandle,
        returnValueHandle,
        type,
        timeout,
        title,
        body,
        children,
    } = props;
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(true);
    const [value, setValue] = useState<number | string>(0);

    const handleClose = (
        type?: "ok" | "cancel" | "reset" | "track" | Number
    ) => {
        setOpen(false);
        switch (type) {
            case "ok":
                okHandle && okHandle();
                break;
            case "cancel":
                cancelHandle && cancelHandle();
                break;
            case "reset":
                cancelHandle && cancelHandle("reset");
                break;
            case "track":
                cancelHandle && cancelHandle("track");
                break;
            default:
                closeHandle && closeHandle();
                break;
        }
    };

    useEffect(() => {
        let timer: any = null;
        if (timeout) {
            timer = setTimeout(() => {
                handleClose("cancel");
            }, timeout);
        }

        return () => {
            timer && clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = (
        <Grid container style={modalStyle} className={classes.root}>
            <Grid container direction="column" className={classes.container}>
                <Grid
                    item
                    container
                    direction="column"
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                >
                    {type === "error" && (
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="3x"
                            style={{ marginBottom: "8px" }}
                        />
                    )}
                    <Typography variant="h5">{title}</Typography>
                </Grid>
                <Grid item className="mt-1">
                    <Box>{body}</Box>
                </Grid>
                <Grid container direction="row" justify="flex-end">
                    {type === "question" ? (
                        <>
                            <Button
                                onClick={() => {
                                    handleClose("cancel");
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    handleClose("ok");
                                }}
                            >
                                OK
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={() => {
                                    handleClose("ok");
                                }}
                            >
                                OK
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
    switch (type) {
        case "modal":
            return (
                <div>
                    <Modal open={open}>{children ? children : <></>}</Modal>
                </div>
            );
        case "modal-add-friend":
            return (
                <div>
                    <Modal open={open}>
                        <Grid
                            container
                            style={modalStyle}
                            className={classes.root}
                        >
                            <Grid
                                container
                                direction="column"
                                className={classes.container}
                            >
                                <Grid item className={"mt-3"}>
                                    <TextField
                                        autoFocus
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        placeholder="@username"
                                        label={body}
                                        onChange={(e) => {
                                            setValue(e.target?.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justify={"flex-end"}
                                        className={classes.container}
                                    >
                                        <Grid item>
                                            <Button
                                                onClick={() => {
                                                    handleClose("cancel");
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    returnValueHandle(value);
                                                }}
                                                className={"p-1"}
                                            >
                                                ADD
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Modal>
                </div>
            );
        case "modal-edit-track":
        case "modal-edit":
            return (
                <div>
                    <Modal open={open}>
                        <Grid
                            container
                            style={modalStyle}
                            className={classes.root}
                        >
                            <Grid
                                container
                                direction="column"
                                className={classes.container}
                            >
                                <Grid item className={"mt-3"}>
                                    <TextField
                                        autoFocus
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        placeholder="0"
                                        label={body}
                                        onChange={(e) => {
                                            setValue(Number(e.target?.value));
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        wrap="nowrap"
                                        className={classes.container}
                                    >
                                        <Button
                                            onClick={() => {
                                                handleClose("reset");
                                            }}
                                        >
                                            Reset
                                        </Button>
                                        <Grid item>
                                            {type === "modal-edit-track" && (
                                                <Button
                                                    onClick={() => {
                                                        handleClose("track");
                                                    }}
                                                >
                                                    Start Now
                                                </Button>
                                            )}
                                            <Button
                                                onClick={() => {
                                                    handleClose("cancel");
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    returnValueHandle(value);
                                                }}
                                                className={"p-1"}
                                            >
                                                OK
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Modal>
                </div>
            );
        case "modal-edit-progressive":
            return (
                <div>
                    <Modal open={open}>
                        <Grid
                            container
                            style={modalStyle}
                            className={classes.root}
                        >
                            <Grid
                                container
                                direction="column"
                                className={classes.container}
                            >
                                <Grid item className={"mt-3"}>
                                    <TextField
                                        autoFocus
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        placeholder="0"
                                        label={body}
                                        onChange={(e) => {
                                            setValue(Number(e.target?.value));
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        wrap="nowrap"
                                        className={classes.container}
                                    >
                                        <Grid
                                            item
                                            xs={3}
                                            container
                                            direction="column"
                                        >
                                            <Button
                                                onClick={() => {
                                                    handleClose("reset");
                                                }}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    handleClose("cancel");
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            container
                                            direction="column"
                                        >
                                            <Button
                                                onClick={() => {
                                                    returnValueHandle("done");
                                                }}
                                                className={"p-1"}
                                            >
                                                Mark as Completed
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    returnValueHandle(
                                                        Number(value) * -1
                                                    );
                                                }}
                                                className={"p-1"}
                                            >
                                                Subtract
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    returnValueHandle(value);
                                                }}
                                                className={"p-1"}
                                            >
                                                Add
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Modal>
                </div>
            );
        case "modal-edit-boolean":
            return (
                <div>
                    <Modal open={open}>
                        <Grid
                            container
                            style={modalStyle}
                            className={classes.root}
                        >
                            <Grid
                                container
                                direction="column"
                                className={classes.container}
                            >
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        className={classes.container}
                                    >
                                        <Grid item>
                                            <Button
                                                onClick={() => {
                                                    returnValueHandle(0);
                                                }}
                                            >
                                                Mark Incomplete
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    returnValueHandle(1);
                                                }}
                                                className={"p-1"}
                                            >
                                                Mark Complete
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Modal>
                </div>
            );
        case "modal-add-one":
            return (
                <div>
                    <Modal open={open}>
                        <Grid
                            container
                            style={modalStyle}
                            className={classes.root}
                        >
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                className={classes.container}
                            >
                                <Grid item>
                                    <Typography variant="body2">
                                    {title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        alignItems="stretch"
                                        justify="space-between"
                                        className={classes.container}
                                    >
                                        <Grid item>
                                            <Button
                                                onClick={() => {
                                                    returnValueHandle(0);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    returnValueHandle(1);
                                                }}
                                                className={"p-1"}
                                            >
                                                Add One
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Modal>
                </div>
            );

        default:
            return (
                <div>
                    <Modal open={open}>{content}</Modal>
                </div>
            );
    }
};
