import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
    RootState,
    useAppDispatch,
    useAppSelector,
} from "../../../redux/store/store";
import { Challenge } from "../../../models/challenge.model";
import { modal } from "../../../components/modal/modal";
import { useState } from "react";
import { User } from "../../../models/user.model";
import { ChallengeParticipant } from "../../../models/challengeParticipant.model";
import { routes } from "../../../routers/routes";
import { setChallenge } from "../../../redux/slices/challenges/challenges.slice";
import { ChallengesApi } from "../../../apis/challengesApi";
import { EditorOptions } from "./components/EditorOptions";
import { ParticipantOptions } from "./components/ParticipantOptions";
import { Contribution } from "../../../models/contributor";

export const ChallengeScreen = () => {
    const challenge: Challenge = useAppSelector(
        (state: RootState) => new Challenge(state.challenge.challenge)
    );
    const user: User = useAppSelector((state: RootState) => state.auth.user);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { id } = useParams<any>();

    const [loading, setLoading] = useState<boolean>(true);
    const [permissionToEdit, setPermissionToEdit] = useState<boolean>(false);
    const [isParticipant, setIsParticipant] = useState<boolean>(false);
    const [currentParticipant, setCurrentParticipant] =
        useState<ChallengeParticipant>(new ChallengeParticipant());

    const fetchChallenge = async () => {
        const challengeDB = await ChallengesApi.getChallenge(id);
        dispatch(setChallenge(challengeDB));
        const isParticipantTemp = challengeDB.isParticipant(user.id);
        setIsParticipant(isParticipantTemp);
        if (user.id === challengeDB.creatorId) setPermissionToEdit(true);
        if (!isParticipantTemp && challengeDB.type === "Private") {
            modal(
                "alert",
                "",
                "Seems like you're not longer part of this challenge or the challenge has been deleted."
            );
            history.replace(routes.MAIN.DASHBOARD);
        }
        let tempParticipant = challengeDB.getParticipant(user.id);
        setCurrentParticipant(tempParticipant);
        setLoading(false);
    };

    const goToProgress = async () => {
        if (challenge.isCooperative)
            return history.push(`/challenge/${challenge.id}/coop-progress`);

        history.push(`/challenge/${challenge.id}/progress`);
    };
    const goToParticipants = async () => {
        history.push(`/challenge/${challenge.id}/participants`);
    };
    const handleJoin = async () => {
        if (challenge.getHasEnded()) return;

        if (challenge.getHasStarted()) {
            const answer = await modal(
                "question",
                "",
                `This challenge has already started. You can still join in, however the duration of your challenge will be shorter.`
            );
            if (answer !== "ok") return;
        }

        setLoading(true);
        await ChallengesApi.addParticipantToChallenge(
            challenge.id,
            user.id,
            true
        );
        fetchChallenge();
        setLoading(false);
    };
    const handleQuit = async () => {
        const answer = await modal(
            "question",
            "Are you sure?",
            `If this challenge has already started, you may join back in, however all the progress made to this point will be lost. Are you sure you want to quit? `
        );
        if (answer !== "ok") return;

        setLoading(true);
        await ChallengesApi.removeParticipantFromChallenge(
            challenge.id,
            user.id
        );
        history.replace(routes.MAIN.DASHBOARD);
    };
    const handleDelete = async () => {
        const answer = await modal(
            "question",
            "Are you sure?",
            `You won't be able to recover it, all the participant will be removed, all progress will be lost.`
        );
        if (answer !== "ok") return;

        setLoading(true);
        await ChallengesApi.deleteChallenge(challenge.id);
        dispatch(setChallenge(new Challenge()));
        setLoading(false);
        history.replace(routes.MAIN.DASHBOARD);
    };
    const handleEdit = async () => {
        if (!challenge.isEditable()) {
            notEditableModal();
            return;
        }

        dispatch(setChallenge({ ...challenge }));
        history.push("/challenges/new/1");
    };

    const notEditableModal = () => {
        modal(
            "error",
            "",
            "The challenge is not editable, only challenges that have not yet started and has no participants can be edited."
        );
    };

    const entryBioData = () => {
        history.push(routes.NEW_BIODATA.CHOOSE_TYPE, {challangeId: challenge.id})
    }

    const contribute = async () => {
        try {
            const response = await modal(
                challenge.unit === "none" ? "modal-add-one" : "modal-edit",
                "",
                "Yey! Tell your peers your contribution!"
            );

            if (response === "cancel" || response === 0) return;
            
            setLoading(true);

            const constribution: Contribution = {
                amount: Number(response),
                unit: challenge.unit,
            };
            
            await ChallengesApi.addContribution(challenge.id, constribution);
            fetchChallenge();
            setLoading(false);
            
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchChallenge();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (permissionToEdit) {
        return (
            <EditorOptions
                loading={loading}
                challenge={challenge}
                goToProgress={goToProgress}
                entryBioData={entryBioData}
                goToParticipants={goToParticipants}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        );
    } else {
        return (
            <ParticipantOptions
                loading={loading}
                challenge={challenge}
                isParticipant={isParticipant}
                currentParticipant={currentParticipant}
                goToProgress={goToProgress}
                entryBioData={entryBioData}
                goToParticipants={goToParticipants}
                handleJoin={handleJoin}
                handleQuit={handleQuit}
                contribute={contribute}
            />
        );
    }
};
