import { Grid } from "@material-ui/core";
import { LoadingWrapper } from "../../components/LoadingWrapper/LoadingWrapper";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { pageTransitions, pageVariants } from "../../animations/transitions";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { BiodataCard } from "./components/BiodataCard";
import { useAppSelector } from "../../redux/store/store";
import { User } from "../../models/user.model";
import { BiodataApi } from "../../apis/biodataApi";
import { BiodataEntry } from "../../models/biodataEntry.model";
import { routes } from "../../routers/routes";

export const ListBiodata = () => {
    const currentUser: User = useAppSelector((s) => s.auth.user);
    const [entries, setEntries] = useState<BiodataEntry[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const removeActivity = async (biodata: BiodataEntry) => {
        try {
            setIsLoading(true);
            await BiodataApi.remove(biodata.id);
            fetchEntries();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };
    const fetchEntries = useCallback(async () => {
        try {
            setIsLoading(true);
            const biodataTemp = await BiodataApi.get({
                userId: currentUser.id,
            });
            biodataTemp.sort((a,b)=> a.date > b.date ? -1 : 1)
            setEntries(biodataTemp);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }, [currentUser.id]);

    useEffect(() => {
        fetchEntries();
    }, [fetchEntries]);
    return (
        <motion.div
            initial="initial"
            exit="out"
            animate="in"
            transition={pageTransitions}
            variants={pageVariants}
        >
            <LoadingWrapper isLoading={isLoading} type="fullscreen">
                <Grid container direction="column">
                    <NavigationBar
                        title="Latest Entries"
                        backArrowRoute={routes.NEW_BIODATA.CHOOSE_TYPE}
                    />
                    {entries.map((a, i) => (
                        <BiodataCard
                            key={i}
                            index={i}
                            deleteEntry={removeActivity}
                            biodataEntry={a}
                        />
                    ))}
                </Grid>
            </LoadingWrapper>
        </motion.div>
    );
};
