/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Grid, Switch, TextField, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { A3Select } from "../../../../components/A3Select/A3Select";
import { useForm } from "../../../../hooks/useForm";
import { Challenge } from "../../../../models/challenge.model";
import { setChallenge } from "../../../../redux/slices/challenges/challenges.slice";
import { RootState } from "../../../../redux/store/store";
import { User } from "../../../../models/user.model";
import { ChallengeDifficulty } from "../../../../models/types";

export const NewChallengeName = () => {
    const challengesTypes = ["Public", "Private"];
    const challengesDifficulty: ChallengeDifficulty[] = [
        "Entry",
        "Intermediate",
        "Challenging",
    ];
    const challenge: Challenge = useSelector(
        (state: RootState) => new Challenge(state.challenge.challenge)
    );
    const { id: userId }: User = useSelector(
        (state: RootState) => new User(state.auth.user)
    );
    const [
        { challengeType, name, isProgressive, difficulty },
        handleInputChange,
        _,
        setFormValue,
    ] = useForm({
        difficulty: challenge.difficulty,
        challengeType: challenge.type,
        isProgressive: challenge.isProgressive,
        name: challenge.name,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setChallenge({
                type: challengeType,
                name,
                isProgressive,
                difficulty,
                creatorId: userId,
            })
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challengeType, name, isProgressive]);

    return (
        <div className={"mt-5"}>
            <A3Select
                fullWidth
                name="challengeType"
                label={"Select Challenge's Type"}
                classes="mt-2"
                objectArray={challengesTypes}
                defaultSelectedObject={challengeType}
                handleChange={handleInputChange}
            />
            <A3Select
                fullWidth
                name="difficulty"
                label={"Difficulty"}
                classes="mt-2"
                objectArray={challengesDifficulty}
                defaultSelectedObject={'Entry'}
                handleChange={handleInputChange}
            />
            <TextField
                fullWidth
                name={"name"}
                value={name}
                onChange={handleInputChange}
                className={"mt-3"}
                variant={"outlined"}
                placeholder={"Name of the challenge"}
                label={"Name of the challenge"}
            />

            <Grid
                item
                container
                direction="row"
                justify="space-between"
                className="mt-3"
            >
                <Typography>Is it a progressive challenge</Typography>
                <Switch
                    checked={challenge.isProgressive}
                    onChange={(e) => {
                        setFormValue("isProgressive", e.target.checked);
                    }}
                    color="primary"
                    name="isProgressive"
                    inputProps={{
                        "aria-label": "Is it a Progressive Challenge",
                    }}
                />
            </Grid>
            <Typography variant="body2">
                A progressive challenge is one that let you add and substract
                quantity on the go, useful if it is the kind of challnge that
                will require participants to record progress all day long.
            </Typography>
        </div>
    );
};
