import { Button, Card, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import annotationPlugin from "chartjs-plugin-annotation";
import { Bar, Chart, Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { LoadingWrapper } from "../../../components/LoadingWrapper/LoadingWrapper";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { Challenge } from "../../../models/challenge.model";
import { Task } from "../../../models/task.model";
import { User } from "../../../models/user.model";
import { RootState } from "../../../redux/store/store";
import { FloatingCloseButton } from "../../../components/FloatingCloseButton/FloatingCloseButton";
import { routes } from "../../../routers/routes";
import { TasksApi } from "../../../apis/tasksApi";

Chart.register(annotationPlugin);

export const ChallengeProgressScreen = () => {
    const user: User = useSelector((state: RootState) => state.auth.user);
    const challenge: Challenge = useSelector(
        (state: RootState) => new Challenge(state.challenge.challenge)
    );
    const { id: challengeId } = useParams<{ id: string }>();

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState("Progress");
    const [eventsToShow, setEventsToShow] = useState<number>(7);
    const [doneTasks, setDoneTasks] = useState<Task[]>([]);
    const [barData, setBarData] = useState<any>({});
    const [doughnutData, setDoughnutData] = useState<any>({});
    const [chartOptions, setChartOptions] = useState({});
    const [totals, setTotals] = useState({
        totalChallenge: 0,
        totalProgress: 0,
        totalProgressPercentage: 0,
        remainingProgress: 0,
    });

    const fetchProgress = async () => {
        const tempTasks = await TasksApi.getTasks(user.id, challengeId);
        const totalChallenge = tempTasks.reduce(
            (total, ct) => total + ct.excpectedQuantity,
            0
        );
        const totalProgress = tempTasks.reduce(
            (total, ct) => total + ct.actualQuantity,
            0
        );
        const remainingProgress = totalChallenge - totalProgress;
        const totalProgressPercentage = Number(
            ((totalProgress * 100) / (totalChallenge || 1)).toFixed(0)
        );
        setTotals({
            totalChallenge,
            totalProgress,
            totalProgressPercentage,
            remainingProgress,
        });
        let tempDoneTasks: Task[] = [];

        const today = moment().endOf("day");
        tempTasks.forEach((task) => {
            if (moment(task.date).isSameOrBefore(today) && task.done) {
                tempDoneTasks.push(task);
            }
        });

        tempDoneTasks.sort((a, b) => {
            let isAfter = moment(a.date).isAfter(b.date);
            return isAfter ? 1 : -1;
        });

        const starts =
            tempDoneTasks.length >= eventsToShow
                ? tempDoneTasks.length - eventsToShow
                : 0;
        const ends = tempDoneTasks.length;
        tempDoneTasks = tempDoneTasks.slice(starts, ends);

        if (tempDoneTasks.length < eventsToShow)
            setEventsToShow(tempDoneTasks.length);

        setDoneTasks(tempDoneTasks);

        if (!tempDoneTasks.length) {
            setLoading(false);
        }
        const labels: string[] = [];
        const actualData: number[] = [];
        tempDoneTasks.forEach((t) => {
            labels.push(moment(t.date).format("DD-MMM"));
            actualData.push(t.actualQuantity);
        });
        setChartOptions({
            scales: {
                y: {
                    ticks: {
                        callback: function (value: any) {
                            // with this we remove the decimals in the chart
                            if (Number(value.toFixed(0)) === Number(value))
                                return value;
                        },
                    },
                },
            },
            maintainAspectRatio: true,
            plugins: {
                autocolors: false,
                annotation: {
                    annotations: {
                        box1: {
                            type: "box",
                            xMin: -1,
                            xMax: eventsToShow,
                            yMin: challenge.quantity,
                            yMax: challenge.quantity,
                            borderColor: "#FFFDFA",
                            backgroundColor: "#FFFDFA",
                            borderWidth: 3,
                        },
                    },
                },
            },
        });
        setBarData({
            labels,
            datasets: [
                {
                    label: challenge.challengeActivity.name,
                    data: actualData,
                    backgroundColor: new Array(eventsToShow + 2).fill(
                        "#78D008"
                    ),
                    borderColor: new Array(eventsToShow + 2).fill("#78D008"),
                    borderWidth: 1,
                },
            ],
        });

        setDoughnutData({
            labels: ["Progress", "Remaining"],
            datasets: [
                {
                    label: challenge.challengeActivity.name,
                    data: [totalProgress, remainingProgress],
                    backgroundColor: ["#78D008", "#303030"],
                    borderColor: ["#78D008", "#78D008"],
                    borderWidth: 1,
                },
            ],
        });
        setLoading(false);
    };

    const goToTasks = () => {
        history.replace(routes.TASKS);
    };

    useEffect(() => {
        if (challenge.id !== challengeId)
            history.replace("/challenge/" + challengeId);
        fetchProgress();
        setTitle(`
            ${challenge.challengeActivity.name} 
            ${challenge.unit !== "none" ? challenge.quantity : ""} 
            ${challenge.unit !== "none" ? challenge.unit : ""} 
            ${challenge.interval}
        `);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <FloatingCloseButton />
            <NavigationBar
                title={title}
                backArrowRoute={"/challenge/" + challengeId}
            />
            <LoadingWrapper isLoading={loading} type="fullscreen">
                <>
                    {doneTasks.length === 0 && (
                        <>
                            <Typography variant={"h5"} align="center">
                                ...No progress yet...
                            </Typography>
                            <Typography
                                variant={"h5"}
                                align="center"
                                className="mt-1"
                            >
                                start logging your progress in the Tasks section
                            </Typography>
                            <Button
                                className={"secondary-button mt-3"}
                                fullWidth
                                onClick={goToTasks}
                            >
                                Take me to Tasks
                            </Button>
                        </>
                    )}
                    {challenge.unit !== "none" && doneTasks.length > 0 && (
                        <Card className="curved">
                            <Typography
                                variant="body1"
                                align="center"
                                className="mt-1 mb-2"
                            >
                                Showing the last {eventsToShow} tasks
                            </Typography>

                            <Bar
                                data={barData}
                                width={100}
                                height={100}
                                plugins={[annotationPlugin]}
                                options={chartOptions}
                            />
                        </Card>
                    )}
                    {doneTasks.length > 0 && (
                        <Card className={"curved mt-2 mb-6"}>
                            <Typography
                                variant="body1"
                                align="center"
                                className={"mt-1 mb-2"}
                            >
                                Total Progress {totals.totalProgressPercentage}{" "}
                                %
                            </Typography>

                            <Doughnut
                                data={doughnutData}
                                width={100}
                                height={100}
                            />
                        </Card>
                    )}
                </>
            </LoadingWrapper>
        </>
    );
};
