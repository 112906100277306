import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles( ( theme: Theme ) => ( {
    root: {
        padding: theme.spacing( 6, 0, 0, 0 ),
    },
    bottomNavbar: {
        padding: '8px',
        position: 'absolute',
        left: '0px',
        bottom: '0px',
        width: '100vw',
        minWidth: '100vw',
        backgroundColor: theme.palette.secondary.dark,
        // boxShadow: '1px 1px 1px 1px white'
    },
    error: {
        color: 'white'
    },
    errorIcon: {
        color: 'red'
    }
} ) );
