import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ImageCard } from "../../../../components/ImageCard/ImageCard";
import { ChallengeActivity } from "../../../../models/challengeActivity.model";
import { RootState } from "../../../../redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    setActivity,
    setChallenge,
} from "../../../../redux/slices/challenges/challenges.slice";
import { ChallengesApi } from "../../../../apis/challengesApi";

export const NewChallengeActivity = () => {
    const challengeCategory = useSelector(
        (state: RootState) => state.challenge.challenge.challengeCategory
    );

    const challengeActivity = useSelector(
        (state: RootState) => state.challenge.challenge.challengeActivity
    );

    // const [ activity, setActivity ] = useState<ChallengeActivity>( new ChallengeActivity() )
    const [activities, setActivities] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();
    const fetchActivities = useCallback(() => {
        console.log("fetching activities");
        return ChallengesApi.getChallengesCategoriesActivities(
            challengeCategory.id
        );
    }, [challengeCategory.id]);

    const selectActivity = (activity: ChallengeActivity) => {
        dispatch(setActivity(activity));
        // dispatch(setChallenge({challengeActivity: activity}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    useEffect(() => {
        if (!challengeCategory?.id) history.push("/challenges/new/2");
    }, [challengeCategory, history]);

    useEffect(() => {
        const a = fetchActivities();
        setActivities(a);
    }, [fetchActivities]);

    useEffect(() => {
        console.log("re- rendering2");

        if (activities.length === 1) {
            dispatch(
                setChallenge({
                    challengeActivity: activities[0],
                    interval: activities[0].intervals[0],
                })
            );
            const direction = localStorage.getItem("direction") || "";

            if (direction === "next") {
                history.replace("/challenges/new/4");
            } else {
                history.replace("/challenges/new/2");
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activities]);

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignContent="center"
            alignItems="center"
            className="mt-1"
        >
            <Grid container direction="row" justify="space-evenly">
                {activities &&
                    activities.map((c) => {
                        console.log("re-rendering");
                        return (
                            <ImageCard
                                key={c.id}
                                category_activity={c}
                                onclick={selectActivity}
                                isSelected={c.id === challengeActivity.id}
                            />
                        );
                    })}
            </Grid>
        </Grid>
    );
};
