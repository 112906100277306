import { Box, Button, Grid, Modal, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { modal } from "../modal/modal";
import moment from "moment";

type Props = {
    open: boolean;
    onCancel: () => void;
    onSendReport: (response: string) => void;
};
export const ReportAbuseModal = ({ open, onCancel, onSendReport }: Props) => {
    const [response, setResponse] = useState("");

    const cancel = () => {
        onCancel && onCancel();
        setResponse("");
    };
    const sendReport = () => {
        if (response.length < 30) {
            modal(
                "error",
                "Error",
                "It is important to us to understand the problem, please write a description of at least 30 characters."
            );
            return;
        }
        onSendReport && onSendReport(response);
        setResponse("");
    };
    useEffect(() => {
        if(!open) return
        const lastReport = localStorage.getItem("abuse-report");
        const today = moment().format("DD-MM-YYYY");
        if (lastReport === today) {
            modal('error','', `You can only submit one report per day, if you think this is urgent please send us an email to moderator@a3challenges.com`)
            cancel()
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    return (
        <>
            {open && (
                <Box
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        height: "100vh",
                        zIndex: 100,
                        width: "100vw",
                        background: "rgba(100,100,100, 0.8)",
                    }}
                />
            )}
            <Modal
                open={open}
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    height: "230px",
                    width: "calc(100% - 16px)",
                    padding: "16px",
                    margin: "16px",
                    borderRadius: "16px",
                    background: "#4f4f4f",
                    zIndex: 100,

                    transform: "translate(calc(-50% - 16px), -50%)",
                }}
            >
                <Grid container>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        name={"response"}
                        value={response}
                        onChange={(e) => {
                            setResponse(e?.target?.value || "");
                        }}
                        className={"mt-3"}
                        variant={"outlined"}
                        placeholder={"Tell us what's the problem"}
                        label={"Tell us what's the problem"}
                    />
                    <Grid container direction="row" justify="space-between">
                        <Button
                            onClick={() => cancel()}
                            className={"p-1 mt-2"}
                            style={{ color: "#a5a5a5" }}
                        >
                            cancel
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => sendReport()}
                            className={"p-1 mt-2"}
                        >
                            Send Report of Abuse
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};
