import { PostReactionTypes } from "./types";

export class PostReactions {

    public postId: string = '';
    public likes: number = 0;
    public likesCurrentUserReactedWith: boolean = false
    public likesUsers: string[] = [];
    public hearts: number = 0;
    public heartsCurrentUserReactedWith: boolean = false
    public heartsUsers: string[] = [];
    public fists: number = 0;
    public fistsCurrentUserReactedWith: boolean = false
    public fistsUsers: string[] = [];
    constructor( obj?: Partial<PostReactions> ) {
        this.postId = obj?.postId || this.postId
        this.likes = obj?.likes || this.likes
        this.likesCurrentUserReactedWith = obj?.likesCurrentUserReactedWith || this.likesCurrentUserReactedWith
        this.likesUsers = obj?.likesUsers || this.likesUsers
        this.hearts = obj?.hearts || this.hearts
        this.heartsCurrentUserReactedWith = obj?.heartsCurrentUserReactedWith || this.heartsCurrentUserReactedWith
        this.heartsUsers = obj?.heartsUsers || this.heartsUsers
        this.fists = obj?.fists || this.fists
        this.fistsCurrentUserReactedWith = obj?.fistsCurrentUserReactedWith || this.fistsCurrentUserReactedWith
        this.fistsUsers = obj?.fistsUsers || this.fistsUsers
    }

    checkCurrentUserReactions( userId: string ) {
        this.heartsCurrentUserReactedWith = this.heartsUsers.includes( userId )
        this.likesCurrentUserReactedWith = this.likesUsers.includes( userId )
        this.fistsCurrentUserReactedWith = this.fistsUsers.includes( userId )
    }

    hasUserReactedWith( reaction: PostReactionTypes ) {
        switch (reaction) {
            case 'fists': return this.fistsCurrentUserReactedWith
            case 'likes': return this.likesCurrentUserReactedWith
            case 'hearts': return this.heartsCurrentUserReactedWith
            default:
                console.error('hasUserReactedWith: Reaction not reconized');
                return false;
        }
    }

    addUserReaction(userId: string, reaction: PostReactionTypes){
        switch (reaction) {
            case 'fists':
                this.fists++
                this.fistsUsers.push(userId)
                this.fistsCurrentUserReactedWith = true
                break;
            case 'likes':
                this.likes++
                this.likesUsers.push(userId)
                this.likesCurrentUserReactedWith = true
                break;
            case 'hearts':
                this.hearts++
                this.heartsUsers.push(userId)
                this.heartsCurrentUserReactedWith = true
                break;
            default:
                console.error('addUserReaction: Reaction not reconized');
                return false;
        }
    }
    removeUserReaction(userId: string, reaction: PostReactionTypes){
        switch (reaction) {
            case 'fists':
                this.fists--
                this.fistsUsers.splice(this.fistsUsers.indexOf(userId), 1)
                this.fistsCurrentUserReactedWith = false
                break;
            case 'likes':
                this.likes--
                this.likesUsers.splice(this.likesUsers.indexOf(userId), 1)
                this.likesCurrentUserReactedWith = false
                break;
            case 'hearts':
                this.hearts--
                this.heartsUsers.splice(this.heartsUsers.indexOf(userId), 1)
                this.heartsCurrentUserReactedWith = false
                break;
            default:
                console.error('addUserReaction: Reaction not reconized');
                return false;
        }
    }



    toPlainObject(): any {
        const plainObj: any = JSON.parse( JSON.stringify( this ) );
        return plainObj;
    }
}