import React, { useEffect } from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import { A3Select } from '../../../../components/A3Select/A3Select'
import { useForm } from '../../../../hooks/useForm'
import { Challenge } from '../../../../models/challenge.model'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import { setChallenge } from '../../../../redux/slices/challenges/challenges.slice'
import { useHistory } from 'react-router-dom'
import { LoadingScreen } from '../../../LoadingScreen/LoadingScreen'
import { CategoryIntervalsTypes } from '../../../../models/types'


export const NewChallengeQuantity = () => {

    const challenge: Challenge = useSelector( ( state: RootState ) => new Challenge(state.challenge.challenge) )


    const challengesIntervals: CategoryIntervalsTypes[] = challenge.challengeActivity.intervals;
    const units = challenge.challengeActivity.units;
    const initialValues = {

        interval: challenge.interval && challengesIntervals.includes( challenge.interval )
            ? challenge.interval
            : challengesIntervals[ 0 ],

        quantity: challenge.quantity,

        unit: challenge.unit && units.includes( challenge.unit )
            ? challenge.unit
            : units[ 0 ],
    }
    const [ { interval, quantity, unit }, handleInputChange ]: any = useForm( initialValues )


    const dispatch = useDispatch()
    const history = useHistory()

    useEffect( () => {

        dispatch( setChallenge( { interval, quantity, unit } ) )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ interval, quantity, unit ] )


    useEffect( () => {

        console.log( 'challenge.unit', challenge.unit );

        if (
            challenge.challengeActivity.units[ 0 ] === 'none' &&
            challenge.challengeActivity.intervals.length === 1
        ) {
            dispatch( setChallenge( {
                interval: challenge.challengeActivity.intervals[ 0 ],
                quantity: 1,
                unit: 'none'
            } ) )
            const direction = localStorage.getItem( 'direction' ) || ''
            if ( direction === 'next' ) {
                history.replace( '/challenges/new/5' )
            } else {
                history.replace( '/challenges/new/3' )
            }

        }
        if (
            challenge.challengeActivity.units[ 0 ] === 'hours' &&
            challenge.challengeActivity.categoryId === 'fasting'
        ) {
            const hours = Number(challenge.challengeActivity.id.split('-')[0])
            dispatch( setChallenge( {
                interval: challenge.challengeActivity.intervals[ 0 ],
                quantity: hours,
                unit: 'hours'
            } ) )
            const direction = localStorage.getItem( 'direction' ) || ''
            if ( direction === 'next' ) {
                history.replace( '/challenges/new/5' )
            } else {
                history.replace( '/challenges/new/3' )
            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )

    if ( !challenge.challengeActivity.id ) {
        return <LoadingScreen />
    }
    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignContent="center"
            alignItems="center"
            className={'mt-1'}
        >

            <Grid container direction="column" alignContent={'stretch'}>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={7}>
                        <TextField
                            type="number"
                            name={'quantity'}
                            value={quantity}
                            onChange={handleInputChange}
                            className={'mt-3'}
                            variant={'outlined'}
                            placeholder={'How many'}
                            label={'How Many'}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <A3Select
                            name='unit'
                            fullWidth
                            label={'Unit'}
                            classes='mt-3'
                            objectArray={units}
                            defaultSelectedObject={unit}
                            handleChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <A3Select
                    fullWidth
                    name='interval'
                    label={'Select an Interval'}
                    classes='mt-3'
                    objectArray={challengesIntervals}
                    defaultSelectedObject={interval}
                    handleChange={handleInputChange}
                />
            </Grid>
            <Grid container direction="row" justify="center" className={'mt-5 p-3'}>
                <Typography variant={'h6'} align="center">
                    Be realistic: Set challenging yet achievable targets and build on them over time
                </Typography>
            </Grid>
        </Grid>
    )
}
