
export type PostReactionTypes = 'hearts' | 'fists' | 'likes'
export type ChallengeDifficulty = 'Entry' | 'Intermediate' | 'Challenging'

export type PostReactionActionTypes = 'decrement' | 'increment'
export type ChallengeTypes = 'Public' | 'Private' | 'Cooperative'
export type ChallengeCategoryTypes = '' | 'fitness' | 'diet' | 'fasting' | 'yoga' | 'meditation' | 'reading' | 'stop-smoking' | 'stop-drinking'
export type CategoryIntervalsTypes = '' | 'daily' | 'monthly' | 'weekly'
export const CategoryIntervals: CategoryIntervalsTypes[] = ['daily', 'monthly', 'weekly']
export type BadgeColor = "gold" | "silver" | "bronze" | "blue" | "green" | "platinum";
export type BadgeActivityType = "general-workout" | "pushups" | "running" | "pullups" | "squats" | "abs" | "bike" | "swim" | "meditate"| "reading"| "yoga" | "top-contributor"

export enum ParticipantStatusType {
    invited = 'Invited',
    rejected = 'Rejected',
    joined = 'Joined',
}
export enum FriendType {
    challenge = 'challenge',
    business = 'business',
}
export enum NotificationType {
    CHALLENGE_INVITATION = 'challengeInvitation',
    NEW_BADGE = 'new-badge'
}
export enum NotificationStatusType {
    READ = 'read',
    NOT_READ = 'not-read',
}