import { useEffect, useState } from "react";
import { Button, Grid, Typography, AppBar, Snackbar } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { UserCard } from "../../../components/UserCard/UserCard";
import { Challenge } from "../../../models/challenge.model";
import {
    RootState,
    useAppDispatch,
    useAppSelector,
} from "../../../redux/store/store";
import { User } from "../../../models/user.model";
import { modal } from "../../../components/modal/modal";
import { LoadingWrapper } from "../../../components/LoadingWrapper/LoadingWrapper";
import { FloatingCloseButton } from "../../../components/FloatingCloseButton/FloatingCloseButton";
import { routes as rutas } from "../../../routers/routes";
import { ChallengesApi } from "../../../apis/challengesApi";
import { setChallenge } from "../../../redux/slices/challenges/challenges.slice";
import { NotificationsApi } from "../../../apis/notificationsApi";
import { Notification } from "../../../models/notification.model";
import { NotificationType } from "../../../models/types";

export const ChallengeParticipantsScreen = () => {
    const challenge: Challenge = useAppSelector(
        (state: RootState) => new Challenge(state.challenge.challenge)
    );
    const currentUser: User = useAppSelector(
        (state: RootState) => new User(state.auth.user)
    );
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { id } = useParams<any>();

    const [isOwnerOfChallenge, setIsOwnerOfChallenge] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isParticipant, setIsParticipant] = useState<boolean>(false);
    const [isReSendDisabled, setIsReSendDisabled] = useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = useState<string>("");

    const handleResend = async (sendToUser: User) => {
        if (isReSendDisabled) {
            setShowSnackbar('Notification already sent');
            return;
        }

        setIsReSendDisabled(true);
        await NotificationsApi.createNotification(
            new Notification({
                to: sendToUser.getPublicData(),
                type: NotificationType.CHALLENGE_INVITATION,
                data: {
                    challengeId: challenge.id,
                    challengeName: challenge.name,
                },
            })
        );
        setShowSnackbar("Notification sent");
    };

    const handleRemove = async (user: User) => {
        if (challenge.creatorId === user.id) {
            modal("alert", "", "Can't remove the owner of the challenge.");
            return;
        }
        setIsLoading(true);
        const answer = await modal(
            "question",
            "",
            `Are you sure you want to remove. @${user.username}`
        );
        if (answer === "ok") {
            await ChallengesApi.removeParticipantFromChallenge(
                challenge.id,
                user.id
            );
            await fetchChallenge()
        }
        setIsLoading(false);
    };
    const fetchChallenge = async () => {
        const challengeDB = await ChallengesApi.getChallenge(id);
        dispatch(setChallenge(challengeDB));
        const isParticipantTemp = challengeDB.isParticipant(currentUser.id);
        if (!isParticipantTemp && challenge.type === "Private") {
            history.replace(rutas.MAIN.DASHBOARD);
        }
        setIsParticipant(isParticipantTemp);
        if (challenge.creatorId === currentUser.id) setIsOwnerOfChallenge(true);
    };

    const handleInviteMore = async () => {
        if (challenge.getHasStarted()) {
            const answer = await modal(
                "question",
                "",
                `This challenge has already started. The new participants
             can still join in, however the duration of their challenge will be shorter.`
            );
            if (answer !== "ok") return;
        }
        history.push(rutas.MAIN.FRIENDS);
    };

    useEffect(() => {
        fetchChallenge();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <FloatingCloseButton />
            <NavigationBar title={"Participants"} />
            <LoadingWrapper isLoading={isLoading}>
                <>
                    {challenge?.participants?.length ? (
                        challenge?.participants.map((p, i: number) => (
                            <UserCard
                                key={i}
                                className={
                                    "mt-2" +
                                    (i === challenge.participants.length - 1
                                        ? " mb-10"
                                        : "")
                                }
                                user={p}
                                resendFunction={handleResend}
                                removeFunction={handleRemove}
                                actionVisible={isOwnerOfChallenge}
                            />
                        ))
                    ) : (
                        <Typography
                            variant="body1"
                            align="center"
                            className={"mt-3"}
                        >
                            {" "}
                            Looks like no one has join the challenge yet..
                        </Typography>
                    )}
                    {isParticipant && challenge.type !== 'Cooperative' && (
                        <AppBar position="fixed" className={"bottom"}>
                            <Grid
                                container
                                justify="center"
                                direction="row"
                            >
                                <Button
                                    fullWidth
                                    className="main-button  mb-2"
                                    onClick={handleInviteMore}
                                >
                                    Invite More
                                </Button>
                            </Grid>
                        </AppBar>
                    )}
                </>
            </LoadingWrapper>
            <Snackbar
                className="pb-5"
                open={!!showSnackbar}
                autoHideDuration={4000}
                message={showSnackbar}
                action="Close"
                onClose={() => {
                    setShowSnackbar("");
                }}
            />
        </>
    );
};
