import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { JournalEntry } from "../../../models/journalEntry.model";
import { useRemoveJournalEntryMutation } from "../../../redux/slices/journal/journal.slice";

type Props = {
    journalEntry: JournalEntry;
};

export const JournalEntryCard = ({ journalEntry }: Props) => {

    const [removeJournalEntry] = useRemoveJournalEntryMutation()

    const deleteEntry = async ()=> {
        await removeJournalEntry({entryId:journalEntry.id}).unwrap()
    }

    return (
        <Card className="curved mt-2">
            <Grid
                item
                container
                direction="row"
                justify={"space-between"}
                alignContent="flex-start"
                alignItems="flex-start"
            >
                <Grid
                    item
                    xs={10}
                    container
                    direction="column"
                    alignItems="flex-start"
                    justify="flex-start"
                >
                    <Grid container direction="row">
                        <FontAwesomeIcon
                            size="2x"
                            icon={JournalEntry.getIcon(journalEntry.emotion)}
                        />
                        <Typography
                            variant={"subtitle1"}
                            align={"left"}
                            className={"mt-1 ml-2"}
                        >
                            {moment(journalEntry.date).format("ddd, MMM DD YY")}
                        </Typography>
                    </Grid>
                    {journalEntry.entry && (
                        <Typography
                            variant="body2"
                            align={"left"}
                            className="mt-1"
                        >
                            {journalEntry.entry}
                        </Typography>
                    )}
                    {!journalEntry.entry && (
                        <Typography
                            variant="body2"
                            color="error"
                            align={"left"}
                            className="mt-1"
                        >
                            No text entry
                        </Typography>
                    )}
                </Grid>
                <Grid
                    item
                    xs={2}
                    container
                    direction="column"
                    justify="center"
                    className={"mt-1 p-2"}
                >
                    <Box position="relative" top={-24} left={16}>
                        <Typography variant="body2" onClick={()=>{deleteEntry()}}>
                            {<FontAwesomeIcon icon={faTrash} />}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};
