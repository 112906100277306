import React, { MouseEvent, useState } from 'react'
import { AppBar, Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { Logo } from '../../components/Logo/Logo'
import { useStyles } from './RegisterScreen.styles'
import { SocialMediaButton } from '../../components/SocialMediaButton/SocialMediaButton'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { useHistory } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { createUserFirebase, startFacebookLogin, startGoogleLogin } from '../../providers/firebase'
import { A3Button } from '../../components/A3Button/A3Button'

export const RegisterScreen = () => {
    const classes = useStyles()

    const [formError, setFormError]: any = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, handleChange] = useForm({
        email: '',
        password: '',
        password2: ''
    })
    const { email, password, password2 } = formValues
    const history = useHistory()

    const googleRegister = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
        startGoogleLogin()
    }
    const facebookRegister = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
        startFacebookLogin()
    }

    const goToLogin = () => {
        history.replace('/login')
    }
    const isFormValid = () => {
        if (!email) {
            setFormError('You need to write your email.');
            return false;
        }
        if (!validator.isEmail(email)) {
            setFormError('Check you email, seems like an invalid email.');
            return false;
        }
        if (!validator.isStrongPassword(password, {
            minLength: 8,
            minNumbers: 1,
            minSymbols: 0,
            minLowercase: 1,
            minUppercase: 0
        })) {
            setFormError('Password must be: at least 8 characters long and contain at least 1 number and 1 lowercase.');
            return false;
        }
        if (password2 !== password) {
            setFormError('Passwords must match.');
            return false;
        }
        return true
    }
    const handleRegister = () => {
        setIsLoading(true)
        if (isFormValid())
            createUserFirebase(email.trim(), password.trim())
        
        setTimeout(() => {
            setIsLoading(false)
        }, 5000);
    }

    return (
        <Grid
            container
            justify="center"
            direction="column"
            spacing={2}
        >

            <Grid container justify="center" direction="row">
                <Logo className={classes.logo + ' mt-8 mb-4 animate__animated animate__bounce animate__delay-2s'} />
            </Grid>
            {formError && (
                <Grid container justify="center" direction="row">
                    <Box className={`${classes.error} animate__animated animate__bounce animate__delay-2s`} >
                        <FontAwesomeIcon icon={faExclamationTriangle} className={classes.errorIcon} /> {formError}
                    </Box>
                </Grid>
            )}
            <Grid item >
                <TextField
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="Write your email"
                    variant="outlined"
                    className={classes.input + ' mt-2'}
                    value={email}
                    onChange={handleChange}
                    required
                />
                <TextField
                    type="password"
                    label="Password"
                    name="password"
                    placeholder="Your password"
                    variant="outlined"
                    className={classes.input + ' mt-2'}
                    value={password}
                    onChange={handleChange}
                    required
                />
                <TextField
                    type="password"
                    name="password2"
                    label="Repeat Password"
                    placeholder="Your password"
                    variant="outlined"
                    className={classes.input + ' mt-2'}
                    value={password2}
                    onChange={handleChange}
                    required
                />
            </Grid>
            <Grid item>
                <A3Button isLoading={isLoading} fullWidth className="main-button" onClick={handleRegister}>Register</A3Button>
            </Grid>
            <Grid item container justify="center" direction="row" spacing={2}>
                <Grid item >
                    <SocialMediaButton handleClick={googleRegister} icon={faGoogle} />
                </Grid>
                <Grid item >
                    <SocialMediaButton handleClick={facebookRegister} icon={faFacebook} />
                </Grid>
            </Grid>
            <AppBar position="fixed" className={'bottom'}>
                <Grid container justify="center" direction="row" className={'mb-1'}>
                    <Typography variant='subtitle2'>
                        Already have an account?
                    </Typography>
                </Grid>
                <Grid container justify="center" direction="row">
                    <Button fullWidth className="secondary-button" onClick={goToLogin}>Login</Button>
                </Grid>
            </AppBar>
        </Grid>
    )
}
