import { Company } from "../models/company.model";
import { A3Server } from "../utils/fetch";


export class CompanyApi {

    static getCompanyByCode = async (companyCode: string) => {
        try {
            const a3Server = new A3Server()
            return a3Server.get<Company>(`v1/admin/companies/by-code/${companyCode}`)


        } catch (error) {
            throw error
        }

    }

}