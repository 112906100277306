import { Achievement } from "../models/achievements.model";
import { A3Server } from "../utils/fetch";


export class AchievementsApi {

    static getAchievements = async (userId: string, from?: Date, to?: Date): Promise<Achievement[]> => {
        try {
            const a3Server = new A3Server()
            const query = `?from=${(from?.getTime() || 0)}&to=${(to?.getTime() || 0)}`
            const achievements = await a3Server.get<Achievement[]>(`v1/users/${userId}/achievements${query}`)
            return achievements

        } catch (error) {
            throw error
        }

    }



}