import { createStyles, makeStyles, Theme } from '@material-ui/core';


export const useStyles = ( props = {} ) => {
    //const matches = useMediaQuery('(min-width:450px)');
    const styles = makeStyles( ( theme: Theme ) =>
        createStyles( {
            root: {
                
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1)
            },
            tabs: {
               color: theme.palette.primary.contrastText,
               width: '50%',
               padding: '12px',
               '&.active': {
                   backgroundColor: theme.palette.secondary.main,
               }
            },
            card: {
                height: '80px',
                width: '100%',
                backgroundColor: theme.palette.secondary.main,
                // marginTop: theme.spacing(1),
                // marginBottom: theme.spacing(1),
                padding: '0 8px'
            },
            cardActive:{
                border: 'solid 2px',
                color: theme.palette.secondary.light,
            },
            cardIcon:{               
                fontSize:'24px',
                color: theme.palette.primary.main,
            },
            noElements:{               
                color: theme.palette.secondary.light,
            }
        } )
    );
    return styles();
};
