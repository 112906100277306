import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Post } from "../../../models/post.model";

const initialState = { post: Post.createEmpty() };

//Reducer
const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        setPost(state, action: PayloadAction<Partial<Post>>) {
            state.post = {
                ...state,
                ...action.payload,
            } as Post;
        },

    }
})


export const {
    setPost,
} = postSlice.actions

export default postSlice.reducer