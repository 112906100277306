
import { Task } from "../models/task.model";
import store from "../redux/store/store";
import { A3Server } from "../utils/fetch";

export class TasksApi {



    static updateSingleTask = async (
        task: Task,
        newQuantity: number,
        previousQuantity: number,
        done: boolean
    ): Promise<Task> => {
        const user = store.getState().auth.user;
        try {
            const a3Server = new A3Server();

            const taskDb = await a3Server.put<Task>(
                `v1/users/${user.id}/challenges/${task.challenge.id}/tasks/${task.id}`,
                { actualQuantity: newQuantity, done }
            );

            return taskDb;
        } catch (error) {
            throw error;
        }
    };

    static getTasks = async (
        userId: string,
        challengeId?: string,
        type: "pending" | "all" = "all"
    ): Promise<Task[]> => {
        if (!userId) {
            throw new Error("To get tasks you must pass the userId");
        }
        const a3Server = new A3Server();
        let tasks: Task[] = [];

        try {
            if (challengeId) {
                // gets tasks from specifc challenge and user
                tasks = await a3Server.get(
                    `v1/users/${userId}/challenges/${challengeId}/tasks`
                );
            } else {
                // gets all the tasks of ongoing challanges
                const today = new Date().getTime();
                tasks = await a3Server.get(
                    `v1/users/${userId}/challenges/tasks?minEndsAt=${today}`
                );
            }
        } catch (error) {
            throw error;
        }
        return tasks.map(t => new Task(t));
    };
    static getTask = async (

        taskId: string,
        challengeId: string,
        userId: string,
    ): Promise<Task> => {

        const a3Server = new A3Server();
        try {

            const task = await a3Server.get<Task>(
                `v1/users/${userId}/challenges/${challengeId}/tasks/${taskId}`
            );
            return new Task(task)
        } catch (error) {
            throw error;
        }
    };

};
