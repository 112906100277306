import moment from "moment";
import { ChallengeActivity } from "./challengeActivity.model";
import { ChallengeCategory } from "./challengeCategory.model";
import { ChallengeParticipant } from "./challengeParticipant.model";
import { CategoryIntervalsTypes, ChallengeDifficulty, ChallengeTypes } from "./types";
const humanizeDuration = require("humanize-duration");

export class Challenge {

    id: string = '';
    creatorId: string = '';
    name: string = '';
    challengeCategory: ChallengeCategory = new ChallengeCategory();
    challengeActivity: ChallengeActivity = new ChallengeActivity();
    interval: CategoryIntervalsTypes = '';
    quantity: number = 0;
    unit: string = '';
    companyId: string = '';
    pendingAmount: number = 0; // for cooperative

    type: ChallengeTypes = 'Public';
    isProgressive: boolean = false
    isCooperative: boolean = false
    difficulty: ChallengeDifficulty = null

    participants: Partial<ChallengeParticipant>[] = [];
    amountParticipants: number = 0;
    startsAt: number = moment().add(1, 'day').startOf('day').toDate().getTime();
    endsAt: number = moment().add(1, 'day').add(1, 'month').endOf('day').toDate().getTime();

    constructor(obj?: Partial<Challenge>) {

        this.id = obj?.id || this.id
        this.creatorId = obj?.creatorId || this.creatorId
        this.name = obj?.name || this.name
        this.challengeCategory = obj?.challengeCategory || this.challengeCategory
        this.challengeActivity = obj?.challengeActivity || this.challengeActivity
        this.interval = obj?.interval || this.interval

        this.quantity = Number(obj?.quantity) || Number(this.quantity)
        this.unit = obj?.unit || this.unit
        this.companyId = obj?.companyId || this.companyId
        this.pendingAmount = obj?.pendingAmount || this.pendingAmount
        this.type = obj?.type || this.type
        this.isProgressive = obj?.isProgressive || this.isProgressive
        this.isCooperative = obj?.isCooperative || this.isCooperative

        this.amountParticipants = obj?.amountParticipants || this.amountParticipants
        this.participants = obj?.participants || this.participants
        this.difficulty = obj?.difficulty || this.difficulty
        this.startsAt = obj?.startsAt || this.startsAt
        this.endsAt = obj?.endsAt || this.endsAt

    }
    getCreator(): Partial<ChallengeParticipant> {
        let creator = this.participants.filter(p => p.id === this.creatorId)
        return creator[0]

    }
    isParticipant(userId: string) {
        let exists = this.participants.filter(p => p.id === userId)
        return exists.length > 0

    }
    getParticipant(userId: string) {
        let exists = this.participants.filter(p => p.id === userId)
        return new ChallengeParticipant(exists[0])

    }
    
    toPlainObject(): any {
        const plainObj: any = JSON.parse(JSON.stringify(this));
        return plainObj;
    }
    isEditable() {

        if (this.amountParticipants > 1) return false

        if (this.getHasEnded()) return false

        if (this.getHasStarted()) return false

        return true

    }

    getChallangeTime(humanize?: boolean, unit:  'milliseconds' | 'days' =  'milliseconds' ) {

        const timeDiff = (moment(this.endsAt).diff(moment(this.startsAt), unit)) + 1

        return humanize
            ? humanizeDuration(timeDiff, { units: ["d"], round: true })
            : timeDiff
    }
    getEndStartRelativeTime(withTextDescription: boolean = false) {
        let timeDiff
        let textDescription = '';
        let textDescription2 = '';

        if (this.getHasStarted()) {

            if (this.getHasEnded()) {

                timeDiff = moment().diff(moment(this.endsAt), 'milliseconds')
                textDescription = 'Ended '
                textDescription2 = ' ago'

            } else {

                timeDiff = moment(this.endsAt).diff(moment(), 'milliseconds')
                textDescription = 'Ends in '
            }

        } else {

            timeDiff = moment().diff(moment(this.startsAt), 'milliseconds')
            textDescription = 'Starts in '
        }

        if (withTextDescription) {

            return textDescription + humanizeDuration(timeDiff, { units: ["d"], round: true }) + textDescription2

        } else {

            return humanizeDuration(timeDiff, { units: ["d"], round: true })
        }
    }

    getChallengeType() {

        if (this.challengeCategory.name === this.challengeActivity?.name) return this.challengeCategory.name

        return this.challengeCategory.name + (
            this.challengeActivity?.name
                ? ' - ' + this.challengeActivity?.name
                : '')
    }

    getHasStarted() {
        return moment().isAfter(moment(this.startsAt))
    }
    getHasEnded() {
        return moment().isAfter(moment(this.endsAt))
    }

    static createEmpty(): Challenge {
        return new Challenge().toPlainObject()
    }
}