import { createStyles, makeStyles } from '@material-ui/core';


export const useStyles = ( props = {} ) => {
    //const matches = useMediaQuery('(min-width:450px)');
    const styles = makeStyles( ( theme: any ) =>
        createStyles( {
            dot: {
                color: 'white',
                borderRadius: '50%',
                margin: '0 8px',
                width: '11px',
                height: '11px',
                backgroundColor: theme.palette.secondary.light
            },
            activeDot: {
                backgroundColor: theme.palette.secondary['contrastText']
            },
        } )
    );
    return styles();
};
