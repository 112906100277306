import { createStyles, makeStyles } from '@material-ui/core';


export const useStyles = ( props = {} ) => {
    //const matches = useMediaQuery('(min-width:450px)');
    const styles = makeStyles( ( theme: any ) =>
        createStyles( {
            root: {
                padding: '0 16px'
            },
            card: {
                height: '50px',
                width: '100%',
                backgroundColor: theme.palette.secondary.main,
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                padding: '0 8px'
            },
            cardActive:{
                border: 'solid 2px',
                color: theme.palette.secondary.light,
            },
            cardIcon:{               
                fontSize:'24px',
                color: theme.palette.primary.main,
            }
        } )
    );
    return styles();
};
