import { FriendType } from "./types";
import { User } from "./user.model";

export class Friend {

    public id: string = '';
    public user: Partial<User> = new User();
    public relation:FriendType = FriendType.challenge
    public relationId:string = ''
    public date: Date = new Date();

    constructor( obj?: Partial<Friend> ) {
        this.id = obj?.id || this.id
        this.user = obj?.user || this.user
        this.relation = obj?.relation || this.relation
        this.relationId = obj?.relationId || this.relationId
        this.date = obj?.date || this.date
    }

    toPlainObject(): any {
        const plainObj: any = JSON.parse( JSON.stringify( this ) );
        return plainObj;
    }


}