import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signOutFirebase } from '../../providers/firebase';
import { logout } from '../../redux/slices/auth/auth.slice';

export const Logout = () => {
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
        signOutFirebase();
    }, [dispatch]);
    return <></>;
};
