import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {

    },
    logo: {
        height: '109px',
    },
    resetButton: {
        color: theme.palette.primary.contrastText,
        padding: '8px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '12px',
    },
    input: {
        marginTop: '16px',
        width: '100%'
    },
    createAccount: {
        padding: '8px',
        position: 'absolute',
        left: '0px',
        bottom: '0px',
        width: '100vw',
        minWidth: '100vw',

    },
    version: {
        color: '#444444',
        fontSize: '8px',
        alignSelf: 'end',
        width: '100%',
        textAlign: 'end',
        marginBottom: '-8px',
        padding: '4px',
        
    }
}))
