import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../slices/auth/auth.slice';
import uiSlice from '../slices/ui/ui.slice';
import challengesSlice from '../slices/challenges/challenges.slice';
import postsSlice from '../slices/posts/posts.slice';
import notificationsSlice from '../slices/notifications/notifications.slice';
import { preProcessorMiddleware } from '../middleware/preProcesorMiddleware';

import journalSlice from '../slices/journal/journal.slice';
import { baseApi } from '../slices/baseApi';
import biodataSlice from '../slices/biodata/biodata.slice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    post: postsSlice,
    challenge: challengesSlice,
    notifications: notificationsSlice,
    ui: uiSlice,
    biodata: biodataSlice,
    journal: journalSlice,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['post'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['post.post', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['items.dates'],
      },
    }).concat(
      preProcessorMiddleware,
      baseApi.middleware,
    )
  },
  devTools: true
});

// Types
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Aliases with types
export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useAppDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
