import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = ( props = {} ) => {

    const styles = makeStyles( ( theme: Theme ) =>
        createStyles( {
            root: {
               color: theme.palette.primary.contrastText,
               
            },
            
        } )
    );
    return styles();
};
