import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { ImageCard } from '../../../../components/ImageCard/ImageCard'
import { ChallengeCategory } from '../../../../models/challengeCategory.model'
import { useDispatch, useSelector } from 'react-redux'
import { Challenge } from '../../../../models/challenge.model'
import { RootState } from '../../../../redux/store/store'
import { setChallenge } from '../../../../redux/slices/challenges/challenges.slice'
import { ChallengesApi } from '../../../../apis/challengesApi'


export const NewChallengeCategory = () => {

    const challenge: Challenge = useSelector( ( state: RootState ) => new Challenge(state.challenge.challenge) )


    const [ category, setCategory ] = useState<ChallengeCategory>( challenge.challengeCategory )
    const [ categories ] = useState<ChallengeCategory[]>( ChallengesApi.getChallengesCatregories() )
    const dispatch = useDispatch()

    useEffect( () => {

        dispatch( setChallenge( { challengeCategory: category } ) )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ category ] )

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignContent="center"
            alignItems="center"
            className={'mt-1'}
        >
            <Grid container direction="row" justify={'space-evenly'}>
                {
                    ( categories.map( ( c, index ) => (
                        <ImageCard
                            key={index}
                            category_activity={c}
                            isSelected={c.id === category?.id}
                            onclick={setCategory}
                        />
                    ) ) )
                }
            </Grid>
            <Grid container direction="row" justify="center" className={'mt-2 p-3'}>
                <Typography variant={'h6'} align="center">
                    Challenges are what make life interesting, overcoming them is what makes life meaningful
                </Typography>
            </Grid>
        </Grid>
    )
}
