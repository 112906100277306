import { useState, useEffect } from "react";


type AudioFiles = 'meditation-finish'

export const useAudio = (audioFile: AudioFiles) => {
    const audioUrl = `${process.env.PUBLIC_URL}/assets/audio/${audioFile}.mp3`
    const [audio] = useState(new Audio(audioUrl));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);
    const play = () => setPlaying(true);
    const stop = () => setPlaying(false);

    useEffect(() => {
        if(!audio) return
        playing ? audio.play() : audio.pause();
    }, [playing, audio]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, [audio]);

    return {playing, play, stop, toggle};
};

// const Player = ({ url }) => {
//   const [playing, toggle] = useAudio(url);

//   return (
//     <div>
//       <button onClick={toggle}>{playing ? "Pause" : "Play"}</button>
//     </div>
//   );
// };

// export default Player;