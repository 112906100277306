import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { ImageCard } from "../../../components/ImageCard/ImageCard";
import { Achievement } from "../../../models/achievements.model";
import { User } from "../../../models/user.model";
import { RootState } from "../../../redux/store/store";
import clsx from "clsx";
import { AchievementsApi } from "../../../apis/achievementsApi";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import moment from "moment";

export const AchievementsList = () => {
    const user: User = useSelector((state: RootState) => state.auth.user);
    const [achievements, setAchievements] = useState<Achievement[]>([]);
    const [period, setPeriod] = useState<
        "all" | "today" | "7-days" | "1-month"
    >("all");
    const [isLoading, setIsLoading] = useState(false);
    const getFrom = () => {
        switch (period) {
            case "all":
                return null;
            case "today":
                return moment().subtract(1, "day").startOf('day').toDate();
            case "7-days":
                return moment().subtract(7, "day").startOf('day').toDate();
            case "1-month":
                return moment().subtract(30, "month").startOf('day').toDate();
            default:
                console.error("Period no recognized");
        }
    };
    const fetchAchievements = async () => {
        try {
            const from = getFrom();
            const to = moment().endOf('day').toDate();
            setIsLoading(true);
            const achievementsDB = await AchievementsApi.getAchievements(
                user.id,
                from,
                to
            );
            setAchievements(achievementsDB);
            console.log(achievementsDB);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchAchievements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    return (
        <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
            className="mt-1"
        >
            <Grid container justify="center">
                <ToggleButtonGroup
                    exclusive
                    value={period}
                    onChange={(_, value) => !!value && setPeriod(value)}
                    aria-label="Period of achievements"
                    className={clsx("mb-2")}
                >
                    <ToggleButton value="all" aria-label="all">
                        <Typography variant="body1">All</Typography>
                    </ToggleButton>
                    <ToggleButton value="today" aria-label="today">
                        <Typography variant="body1">Today</Typography>
                    </ToggleButton>
                    <ToggleButton value="7-days" aria-label="7-days">
                        <Typography variant="body1">7 days</Typography>
                    </ToggleButton>
                    <ToggleButton value="1-month" aria-label="1-month">
                        <Typography variant="body1">1 month</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item container justify="space-evenly">
                {isLoading ? (
                    <CircularProgress />
                ) : !!(achievements?.length === 0) ? (
                    <Typography variant="body1" align="center">
                        ...Nothing here...
                    </Typography>
                ) : (
                    achievements.map(
                        (a, index) =>
                            Number(a.quantity) !== 0 && (
                                <ImageCard
                                    key={index}
                                    category_activity={a.activity}
                                    text={
                                        Math.abs(Number(Number(a.quantity).toFixed(2))) +
                                        " " +
                                        (a.unit && a?.unit !== "none"
                                            ? a.unit
                                            : "days")
                                    }
                                />
                            )
                    )
                )}
            </Grid>
        </Grid>
    );
};
