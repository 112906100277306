import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = ( props = {} ) => {
    //const matches = useMediaQuery('(min-width:450px)');
    const styles = makeStyles( ( theme: Theme ) =>
        createStyles( {
            root: {

            },
            icon: {
                color: 'white',
                fontSize: '20px'

            },
            circle: {
                height: '56px',
                width: '56px',
                borderRadius: '50%',
                border: 'white solid 1px',
                backgroundColor: 'rgba(0,0,0,0)',
                '&:active': {
                    backgroundColor: theme.palette.secondary.dark
                }
            },
        } )
    );
    return styles();
};
