export const routes = {

    ONBOARDING: '/onboarding',

    MAIN: {
        DASHBOARD : '/main/dashboard',
        FRIENDS : '/main/my-friends',
        CHALLENGES_LIST : '/main/challenges',
    },
    ADD_ACTIVITY: '/main/add-activity',
    LIST_ACTIVITY: '/main/activity',
    BADGES: '/main/badges',
    TASKS: '/main/tasks',
    COOP_CHALLENGES: '/main/cooperative-challenges',
    STOPWATCH: '/main/stopwatch/:type?/:challengeId?/:taskId?',
    LIST_BIODATA: '/main/biodata-list',
    NEW_BIODATA: {
        CHOOSE_TYPE: '/main/new-biodata/1',
        ENTRY_DATA: '/main/new-biodata/2'
    },
    JOURNAL: {
        NEW: '/main/journal/new',
        VIEW: '/main/journal/view',
    },
}