import { NotificationsApi } from "../apis/notificationsApi";
import { Notification } from "../models/notification.model";
import { NotificationType } from "../models/types";
import { addNotifications } from "../redux/slices/notifications/notifications.slice";
import store from "../redux/store/store";
import { confettiAnimation } from "../utils/animations";

export class NotificationsController {

    static handleNewNotifications = (notifications: Notification[]) => {

        let hasToShowBadgeAnimation = false
        notifications.forEach((n, i) => {
            if (n.type === NotificationType.NEW_BADGE) {
                NotificationsApi.deleteNotification(n)
                notifications.splice(i, 1)
                hasToShowBadgeAnimation = true
            }
        })

        if (hasToShowBadgeAnimation) confettiAnimation(`Congrats on your new badge!`)


        // normal notifications with the bell badge in home screen
        store.dispatch(addNotifications(notifications));
    }

}