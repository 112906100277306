import {  Middleware } from "redux";
import { Notification } from "../../models/notification.model";


/**
 * Makes modifications to the state before it is dispatched to the reducer.
 * to secure non-serializable data
 * @param store 
 * @returns 
 */
export const preProcessorMiddleware: Middleware = store => next => action => {

    switch (action.type) {
        case 'notifications/addNotifications':
            action.payload = action.payload.map((n: Notification) => {
                return n.toPlainObject()
            })
            break;
        case 'auth/setUser':
        case 'challenge/setChallenge':
        case 'challenge/setActivity':
        case 'api/executeQuery/fulfilled':
            action.payload = JSON.parse( JSON.stringify( action.payload ) );
            break;
        default:
            break;
    }

    next(action);
}