import { useState } from 'react';


export const useForm = <T = any>(initialState: any = {}):[
    T, 
    ({ target }: any) => void, 
    () => void, 
    (key: string, value: unknown) => void
] => {
    const [values, setValues] = useState(initialState);

    const reset = () => {
        setValues(initialState);
    };

    const handleInputChange = ({ target }: any) => {

        setValues({
            ...values,
            [target?.name]: target?.value,
        });
    };
    const setValue = (key: string, value: unknown) => {
        setValues({
            ...values,
            [key]: value,
        });
    };

    return [values, handleInputChange, reset, setValue];
};
