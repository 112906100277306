import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersApi } from "../../../apis/userApi";
import { User } from "../../../models/user.model";
import { setToken } from "../../../utils/auth";
import store from "../../store/store";
import { baseApi } from "../baseApi";

interface RegisterUser {
    user: Partial<User>,
    firebasetoken: string
}

const initialState = {
    user: null as User,
    isAuthenticated: false,
}

//Reducer
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<User>) {
            state.isAuthenticated = action.payload?.id ? true : false;
            //TODO: [ evaluate / discard ] maybe we should combine the current user and the data that is changing, 
            // as it was before new implementation
            state.user = action.payload;
        },
        updateUserData(state, action: PayloadAction<Partial<User>>) {
          
            state.user = {...state.user, ...action.payload};
        },
        AuthUpdateProfilePicture(state, action: PayloadAction<string>) {
            state.user.imgUrl = action.payload
        },
        logout(state) {
            state.isAuthenticated = false;
            state.user = null;
        }
    },
})

interface SignInResponse {
    user: User,
    token: string
}

// API
export const authApiSlice = baseApi.injectEndpoints({
    endpoints(builder) {
        return {
            signIn: builder.query<User, { firebasetoken: string }>({
                query: ({ firebasetoken }) => ({
                    url: `/v1/auth/signin`,
                    method: 'GET',
                    headers: { firebasetoken }
                }),
                transformResponse: (response: SignInResponse) => {
                    setToken(response.token);
                    store.dispatch(setUser(response.user));
                    return response.user
                },
            }),
            register: builder.mutation<User, RegisterUser>({
                query: ({ firebasetoken, user }) => ({
                    url: `/v1/auth/email`,
                    method: 'POST',
                    body: { firebasetoken, user }
                }),
            }),
            putUserData: builder.mutation<User, Partial<User>>({
                queryFn: async (user) => {
                    const updatedUser = await UsersApi.updateUser(user.id, user)
                    return {data: updatedUser}
                },
            })
        }
    }
})

// hooks 
export const { useSignInQuery, useRegisterMutation, usePutUserDataMutation } = authApiSlice

//Endopoints for pure js 
export const signIn = authApiSlice.endpoints.signIn.initiate
export const register = authApiSlice.endpoints.register.initiate
export const putUserData = authApiSlice.endpoints.putUserData.initiate

//export Actions
export const {
    setUser,
    AuthUpdateProfilePicture,
    updateUserData,
    logout,
} = authSlice.actions
export default authSlice.reducer