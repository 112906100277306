import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { TimersApi } from "../../apis/timersApi";
import { StopwatchCounter } from "../../Screens/Stopwatch/components/StopwatchCounter";
import {useStyles} from './TimerCard.styles'

interface Props {
    title:string,
    subTitle?: string
}

export const TimerCard = ({title, subTitle}: Props) => {
    const classes = useStyles()
    const [currentTimer, setCurrentTimer] = useState(null);
    const [start, setStart] = useState(false);
    const [counter, setCounter] = useState(0);
    const mounted = useRef(false);
    const history = useHistory()

    const getTimer = async () => {
        const timer = await TimersApi.getCurrentTimer();
        if (!timer?.id) return;

        const startedAt = moment(timer.startTime);
        const secondsFasting = startedAt.diff(moment(), "s") * -1;
        setCounter(secondsFasting);
        setCurrentTimer(timer);
        setStart(true);
    };

    const openStopwatch = () => {
        history.push(`/main/stopwatch/fasting/${currentTimer.challengeId}/${currentTimer.id}`)
    }

    useEffect(() => {
        getTimer();
    }, []);

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!start || counter <= 0) return;
        setTimeout(() => {
            if (!mounted.current) return;
            setCounter((t) => {
                if (!start) return t;

                return t + 1;
            });
        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter, start]);

    if (!currentTimer?.id) return null;

    return (
        <>
            <Box width={'100%'} className={classes.root} onClick={()=>{
                openStopwatch()
            }}>
                <Grid direction="column" justify="center">
                    <Typography variant="h2" align="center">
                        {title}
                    </Typography>
                    {subTitle && <Typography variant="h2" align="center">
                        {subTitle}
                    </Typography>}
                    <StopwatchCounter seconds={counter} />
                </Grid>
            </Box>
        </>
    );
};
