import { Typography } from "@material-ui/core";
import { ChallengeCardList } from "../../../components/ChallengeCardList/ChallengeCardList";
import { MenuBar } from "../../../components/MenuBar/MenuBar";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";


export const ChallengeListScreen = () => {
    
    return (
        <>
            <NavigationBar title={"Challenges"} />

            <Typography variant={"h3"} className={"mt-2"}>
                My Challenges
            </Typography>
            <ChallengeCardList type="joined" className="mt-1" />

            <Typography variant={"h3"} className={"mt-2"}>
                Challenges Invitations
            </Typography>
            <ChallengeCardList type="invited" className="mt-1" />

            <Typography variant={"h3"} className={"mt-2"}>
                Open Challenges
            </Typography>
            <ChallengeCardList type="public" className="mt-1" />
            <MenuBar />
        </>
    );
};
