import { makeStyles, createStyles } from '@material-ui/core/styles';
//import useMediaQuery from '@material-ui/core/useMediaQuery';

export const useStyles = ( props = {} ) => {
    //const matches = useMediaQuery('(min-width:450px)');
    const styles = makeStyles( ( theme: any ) =>
        createStyles( {
            root: {
                position: 'absolute',
                width: '100%',
                padding: theme.spacing( 2 ),

            },
            container: {
                color: theme.palette.secondary[ 'contrastText' ],
                backgroundColor: theme.palette.secondary.main,
                borderRadius: '8px',
                padding: theme.spacing( 1 ),
                '& button': {
                    color: 'white',
                    align: 'center',
                    marginRight: '8px',
                    padding: '8px'
                },
            }
        } )
    );
    return styles();
};
