import { makeStyles } from '@material-ui/core';

export const useStyles = ( image: string = '' ) =>
    makeStyles( ( theme: any ) => ( {
        root: {
            margin: theme.spacing( 0.5 ),
            overflowWrap: 'break-word',
            maxWidth: '95px',

        },
        text: {
            position: 'relative',

        },
        image: {
            width: '95px',
            height: '85px',
            borderRadius: theme.spacing( 2 ),
            backgroundColor: '#fff',
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/activities/${image})`,
            backgroundPosition: '0 0, center',
            backgroundSize: '95px 95px',
            marginBottom: '4px',
            '&.selected': {
                border: '3px solid ' + theme.palette.primary.main,
            }
        }
    } ) );
