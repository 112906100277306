import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(  {
    root:{

    },
    logo: {
        height: '109px',
        
    },
    input: {
        marginTop: '16px',
        width: '100%'
    },
    loginButton: {
        
    },
    error: {
         color: 'white'  
    },
    errorIcon: {
         color: 'red'  
    }
} );
