import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
    background: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        backdropFilter: 'blur(10px)',
        position: 'absolute',
        boxShadow: '0px 0px 16px -2px ' + theme.palette.secondary.main,
        height: '100%',
        width: ' 100vw',
        left: 0,
        bottom: 0,
    },
}));