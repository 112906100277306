import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { BiodataEntry, BiodataTitles } from "../../../models/biodataEntry.model";
type Props = {
    index: number;
    biodataEntry: BiodataEntry;
    deleteEntry: (biodataEntry: BiodataEntry) => void;
    className?: string;
};

export const BiodataCard = ({
    index,
    biodataEntry,
    deleteEntry,
    className,
}: Props) => {
    const deleteActivity = async () => {
        deleteEntry(biodataEntry);
    };

    return (
        <Card
            className={clsx("curved mt-2", className)}
            style={{ maxHeight: "110px" }}
        >
            <Grid
                item
                container
                direction="row"
                justify={"space-between"}
                alignContent="flex-start"
                alignItems="flex-start"
            >
                <Grid
                    item
                    xs={10}
                    container
                    direction="column"
                    alignItems="flex-start"
                    justify="flex-start"
                >
                    <Grid container direction="row">
                        <Typography
                            variant={"subtitle1"}
                            align={"left"}
                        >
                            {moment(biodataEntry.date).format(
                                "ddd, MMM DD YY"
                            )}
                        </Typography>
                    </Grid>

                    <Typography variant="body1" align={"left"} className="mt-1">
                        {BiodataTitles[biodataEntry.type]}
                    </Typography>
                    <Typography variant="body2" align={"left"} className="mt-1">
                    {biodataEntry.biodata?.value1}
                    {biodataEntry.biodata?.value2 ? `/${biodataEntry.biodata?.value2} ` : ' '}
                    {biodataEntry.biodata?.units}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={2}
                    container
                    direction="column"
                    justify="center"
                    className={"mt-1 p-2"}
                >
                    <Box position="relative" top={-24} left={16}>
                        <Typography
                            variant="body2"
                            onClick={() => {
                                deleteActivity();
                            }}
                        >
                            {<FontAwesomeIcon icon={faTrash} />}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};
