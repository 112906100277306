import React from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { UserAvatar } from "../../../components/UserAvatar/UserAvatar";
import { User } from "../../../models/user.model";
import { useAppSelector } from "../../../redux/store/store";

interface Props {
    user: User;
}
export const ProfileCard = ({ user }: Props) => {
    const history = useHistory();
    const currentUser = useAppSelector((s) => s.auth.user);

    const goToEditProfile = () => {
        history.push("/profile/edit");
    };

    return (
        <Card className={"mt-2"}>
            <Grid container direction="column">
                <Grid container direction="row" alignItems="center">
                    <Grid item container direction="row" className={"mb-1"}>
                        <Grid
                            item
                            xs={7}
                            container
                            direction="row"
                            alignItems="center"
                        >
                            <UserAvatar user={user} />
                            <Grid item container direction="column">
                                <Typography variant="body2" className={"ml-1"}>
                                    {user.firstName} {user.lastName}
                                </Typography>
                                <Typography
                                    variant={"subtitle1"}
                                    className={"ml-1"}
                                >
                                    @{user.username}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            onClick={goToEditProfile}
                            item
                            xs={5}
                            container
                            direction="row"
                            justify={"flex-end"}
                            alignItems="center"
                            className={"pr-2"}
                        >
                            <FontAwesomeIcon icon={faPen} />
                            <Typography variant="body2" className={"ml-1"}>
                                edit
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        className="mt-1"
                        container
                        direction="column"
                        alignItems="flex-start"
                    >
                        <Typography variant={"caption"} className={"ml-1"}>
                            Day of Birth
                        </Typography>
                        <Typography variant="body2" className={"ml-1"}>
                            {moment(user.birthday)
                                .format("MMMM DD yy")
                                .toString()}
                        </Typography>
                    </Grid>
                    <Grid
                        className="mt-1"
                        container
                        direction="column"
                        alignItems="flex-start"
                    >
                        <Typography variant={"caption"} className={"ml-1"}>
                            Pronouns
                        </Typography>
                        <Typography variant="body2" className={"ml-1"}>
                            {user.pronouns}
                        </Typography>
                    </Grid>
                    {user.id === currentUser.id && (
                        <Grid
                            className="mt-1"
                            container
                            direction="column"
                            alignItems="flex-start"
                        >
                            <Typography variant={"caption"} className={"ml-1"}>
                                Email
                            </Typography>
                            <Typography variant="body2" className={"ml-1"}>
                                {user.email}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Card>
    );
};
