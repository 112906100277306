import { Timer } from "../models/timer.model";
import store from "../redux/store/store";
import { logError } from "../utils/error";
import { A3Server } from "../utils/fetch";

export class TimersApi {

    static createTimer = async (timer: Partial<Timer>): Promise<Timer> => {

        if (!timer) {
            console.error("No timer...", timer);
            throw new Error("No timer...");
        }
        timer.userId = store.getState().auth.user.id

        try {
            const a3Server = new A3Server();

            const timerDB = await a3Server.post<Timer>(
                `v1/timers`,
                { timer }
            );

            return timerDB

        } catch (e) {
            logError('createTimer', {timer}, e);
            throw e;
        }
    }

    /**
     * Fetches the current active timer, the server will respond with an array but 
     * this function should respond with a single timer
     * @returns 
     */
    static getCurrentTimer = async (): Promise<Timer> => {
        try {
            
            const a3Server = new A3Server()
            const timers = await a3Server.get<Timer[]>(`v1/timers`)

            if(!timers?.length) return null

            return timers[0]

        } catch (error) {
            throw error
        }

    }
    static removeTimer = async (timerId: string): Promise<void> => {
        try {
            
            const a3Server = new A3Server()
            await a3Server.delete<Timer[]>(`v1/timers/${timerId}`)

        } catch (error) {
            throw error
        }

    }

}