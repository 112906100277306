import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonBase, Card, Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { BiodataInput } from "../../models/biodataEntry.model";
import { useAppDispatch } from "../../redux/store/store";
import { routes } from "../../routers/routes";
import {
    clearEntryInput,
    setEntryInput,
} from "../../redux/slices/biodata/biodata.slice";
import { useEffect } from "react";
import { A3Button } from "../../components/A3Button/A3Button";
import { A3AppBar } from "../../components/A3AppBar/A3AppBar";

export const ChooseBiodataType = () => {
    const history = useHistory();
    const { state } = useLocation();
    console.log(state)
    const dispatch = useAppDispatch();
    const goToLatestEntries = () => {
        history.push(routes.LIST_BIODATA)
    }
    const entryTypes: BiodataInput[] = [
        {
            title: "Height",
            label: "Height",
            biodataType: "height",
            prop: "height",
            units: ["ft", "cm"],
            type: "messure-date",
        },
        {
            title: "Weight",
            label: "Weight",
            biodataType: "weight",
            prop: "weight",
            units: ["lbs", "kgs"],
            type: "messure-date",
        },
        {
            title: "Cholesterol ",
            label: "Cholesterol ",
            biodataType: "cholesterol",
            prop: "cholesterol",
            units: ["mg/dl"],
            type: "messure-date",
        },
        {
            title: "Blood Pressure",
            label: "Systolic Blood Pressure",
            biodataType: "bloodPressure",
            prop: "bloodPressureSystolic",
            label2: "Diastolic Blood Pressure",
            prop2: "bloodPressureDiastolic",
            units: ["mm Hg"],
            type: "messure-date",
        },
        {
            title: "Glucose Level",
            label: "Glucose Level",
            biodataType: "glucoseLevel",
            prop: "glucoseLevel",
            units: ["mg/dl"],
            type: "messure-date",
        },
        {
            title: "Waist",
            label: "Waist",
            biodataType: "waist",
            prop: "waist",
            units: ["in", "cm"],
            type: "messure-date",
        },
        {
            title: "Hips",
            label: "Hips",
            biodataType: "hips",
            prop: "hips",
            type: "messure-date",
        },
    ];

    const handleClick = (entry: BiodataInput) => {
        dispatch(setEntryInput(entry));
        history.push(routes.NEW_BIODATA.ENTRY_DATA, state);
    };
    useEffect(() => {
        dispatch(clearEntryInput());
    }, [dispatch]);

    return (
        <>
            <NavigationBar
                title="Choose Data Type"                
                showBackButton
            />
            <Grid
                container
                direction="column"
                justify="flex-start"
                className="mb-2"
            >
                {entryTypes.map((et, i) => (
                    <Card key={i} className="p-1 pt-2">
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={8}>
                                {et.title}
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                container
                                direction="row"
                                justify="flex-end"
                            >
                                <ButtonBase
                                    className="p-1"
                                    onClick={() => handleClick(et)}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    <Box ml={1}> {et.title === 'Height' ? 'Set Value' : 'Add Entry'}</Box>
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Card>
                ))}
            </Grid>
            <A3AppBar>
                
                    <A3Button
                        fullWidth
                        onClick={goToLatestEntries}
                        className={"secondary-button mb-5"}
                    >
                        See list of latest Entries
                    </A3Button>
            </A3AppBar>
        </>
    );
};
