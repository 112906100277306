import React from 'react';
import { useStyles } from './DashboardScreen.styles';
import { Button, Grid } from '@material-ui/core';
import { DashboardCard } from '../../components/DashboardCard/DashboardCard';
import { NotificationBar } from '../../components/NotificationBar/NotificationBar';
import { FabButton } from '../../components/FabButton/FabButton';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChallenge } from '../../redux/slices/challenges/challenges.slice';
import { Challenge } from '../../models/challenge.model';
import { modal } from '../../components/modal/modal';
import { motion } from 'framer-motion';
import { routes } from '../../routers/routes';
import { pageTransitions, pageVariants } from '../../animations/transitions';
import { RootState } from '../../redux/store/store';
import { TimerCard } from '../../components/TimerCard/TimerCard';


export const DashboardScreen = () => {

    const currentUser = useSelector( (s: RootState )=> s.auth.user)

    useStyles();
    const history = useHistory()
    const dispatch = useDispatch()
    

    const handleInviteFriends = async () => {
        if (!navigator?.share) {
            modal('alert', 'Error', 'Looks that this device is not supported to share, we just copy a link so you can paste it any where: social media, email, sms 😎')
            return
        }
        await navigator.share({
            title: 'A3 Challenges',
            text: 'Hey! Checkout this awesome app, it lets you do challenges with your friends!, fitness, reading, yoga, and more!',
            url: 'https://a3-challenges.web.app/'
        })

        modal('alert', 'Awesome !', 'Thank you for sharing and being part of the amazing A3 Community!')
    };
    const goToMyCommunity = () => {
        history.push('/my-community')
    };
    const goToMyTask = () => {
        history.push(routes.TASKS)
    };
    const goToMyAchievements = () => {
        history.push('/achievements')
    };
    const goToCoopChallenges = () => {
        history.push(routes.COOP_CHALLENGES)
    };

    

    useEffect(() => {
        dispatch(setChallenge(new Challenge()))
        if(!currentUser?.username){
            history.replace(routes.ONBOARDING)
            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <motion.div
            initial="initial"
            exit="out"
            animate="in"
            variants={pageVariants}
            transition={pageTransitions}
        >
            <Grid container>

                <NotificationBar />

                <DashboardCard
                    className="ml-2 mr-2"
                    title="My Tasks"
                    footerActionText="See ongoing challenges"
                    cardIndicatorText=""
                    onClickHandler={goToMyTask}
                />
                <DashboardCard
                    className="ml-2 mr-2"
                    title="My Community"
                    footerActionText="Go To My Community"
                    cardIndicatorText=""
                    onClickHandler={goToMyCommunity}
                />
                <DashboardCard
                    className="ml-2 mr-2"
                    title="My Achievements"
                    footerActionText="View all"
                    cardIndicatorText=""
                    onClickHandler={goToMyAchievements}
                />
                {currentUser?.companyId &&
                    <DashboardCard
                        className="ml-2 mr-2"
                        title="Team Challenges"
                        footerActionText="View all"
                        cardIndicatorText=""
                        onClickHandler={goToCoopChallenges}
                    />
                }
                <TimerCard 
                    title={'Ongoing Timer'}
                />
                <Grid item container direction="row" justify="space-between" className="mt-3 ml-2">

                    <Button className="secondary-button pl-3 pr-3" onClick={handleInviteFriends}>
                        Invite Friends
                    </Button>


                </Grid>
                <FabButton />
            </Grid>
        </motion.div>
    );
};
