import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
          padding: theme.spacing(0,1),
          color: theme.palette.primary.main
        }
    })
);
