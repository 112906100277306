import React, { MouseEvent } from 'react'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyles } from './SocialMediaButton.style'
import { faStar } from '@fortawesome/free-solid-svg-icons'

interface propsType {
    icon?: typeof faGoogle
    handleClick: (e: MouseEvent<HTMLButtonElement, any>) => void
}

export const SocialMediaButton = (props: propsType) => {
    const { icon, handleClick } = props;
    const classes = useStyles()
    return (
        <>
            <button className={classes.circle} onClick={(e) => { handleClick(e) }}>

                <FontAwesomeIcon className={classes.icon} icon={icon || faStar} />


            </button>
        </>
    )
}
