import React from 'react'
import { useStyles } from './SelectUserCards.style'
import { Card, Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { User } from '../../models/user.model';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { useHistory } from 'react-router-dom';
import { Challenge } from '../../models/challenge.model';
import { modal } from '../modal/modal';


interface CardOptions { user: User, selected: boolean }
interface Props {
    users: User[],
    handleChange: Function
    challenge?: Challenge
    validateChallengeParticipant?: boolean
    disableSelection?: boolean
    sendToProfileOnClick?: boolean
}

export const SelectUserCards = ({
    users,
    handleChange,
    validateChallengeParticipant = false,
    challenge,
    disableSelection = false,
    sendToProfileOnClick = false
}: Props) => {

    const classes = useStyles()
    const [currentCards, setCurrentCards] = useState<CardOptions[]>([])
    const history = useHistory()

    const handleClick = (index: number) => {
        if (sendToProfileOnClick) history.push('/profile/' + currentCards[index].user.id)
        if (disableSelection) return
        if (validateChallengeParticipant) {
            if (!challenge?.id) {
                throw new Error('If you want to validate participants you must send the challenge')
            }
            const user = currentCards[index].user
            if (challenge.isParticipant(user.id)) {
                modal('alert', '', 'This user is already part of the challenge or already has been invited.')
                return
            }
        }
        setCurrentCards(state => {
            state[index].selected = !state[index].selected
            return [...state]
        })
    }

    useEffect(() => {

        handleChange(currentCards)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCards])

    useEffect(() => {

        const initialCards = users.map(u => ({ user: u, selected: false }))
        setCurrentCards(initialCards)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container direction="column">
            {currentCards.map((c: CardOptions, i: number) => {
                return (
                    <Card key={i} onClick={() => { handleClick(i) }} className={classes.card + (  currentCards.length-1 === i ? ' mb-10' : '')}>
                        <Grid container direction="row" justify={'space-between'} alignItems="center">
                            <Grid item xs={9} container direction="row" justify="flex-start" alignItems="center">
                                <Grid item>
                                    <UserAvatar user={c.user} />
                                </Grid>
                                <Grid item>
                                    <Typography variant={'body2'} className={'ml-1'}>
                                        {c.user.firstName} {c.user.lastName}
                                    </Typography>
                                    <Typography variant={'overline'} className={'ml-1'}>
                                        {'@'}{c.user.username}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container direction="row" justify={'flex-end'} alignItems="center">
                                {c.selected && <FontAwesomeIcon className={classes.cardIcon} icon={faCheckCircle} />}
                            </Grid>
                        </Grid>
                    </Card >
                )
            }
            )}
        </Grid>
    )
}
