import { useState } from "react";
import {  Grid } from "@material-ui/core";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { AchievementsList } from "./components/AchievementsList";
import { BadgesList } from "./components/BadgesList";
import { AchievmentsMenu } from "./AchievmentsMenu";

export const AchievementsScreen = () => {
   
    const [currentTab, setCurrentTab] = useState('Achievements');
    return (
        <>
            <NavigationBar title={currentTab} backArrowRoute='/' />

            <Grid
                container
                direction="column"
                alignContent="center"
                alignItems="center"
                className="mt-1"
            >
                
                {currentTab === 'Achievements' && <AchievementsList />}
                {currentTab === 'Badges' && <BadgesList />

                }
            </Grid>
            <AchievmentsMenu currentOption={currentTab} onClick={setCurrentTab}  />
        </>
    );
};
