/**
 * Used for the input
 */
export type BiodataPropType =
    'height' |
    'weight' |
    'cholesterol' |
    'bloodPressureSystolic' |
    'bloodPressureDiastolic' |
    'glucoseLevel' |
    'waist' |
    'hips'
/**
 * This is the type that will be use to query stuff, 
 */
export type BiodataType =
    'height' |
    'weight' |
    'cholesterol' |
    'bloodPressure' |
    'glucoseLevel' |
    'waist' |
    'bmi' |
    'hips'
export const BiodataTitles = {
    height: 'Height',
    weight: 'Weight',
    cholesterol: 'Cholesterol',
    bloodPressure: 'Blood Pressure',
    glucoseLevel: 'GlucoseLevel',
    waist: 'Waist',
    hips: 'Hips',
    bmi: 'BMI',
}
/**
 * Biodata itself, what the user inputs
 */
export type BiodataInput = {
    title: string
    biodataType: BiodataType
    prop: BiodataPropType
    label: string
    prop2?: BiodataPropType
    label2?: string
    units?: string[]
    type?: 'messure-only' | 'messure-date'

}
export type Biodata = {
    value1: string,
    value2?: string,
    units: string

}

/**
 * the biodata log Entry, this is the data that will go into the database
 */
export type BiodataEntry = {
    id?: string
    userId?: string
    companyId?: string
    biodata: Biodata,
    type: BiodataType
    refType?: 'challenge' | null
    refId?: string
    date?: number

}

export  type BiodataQueryType = {
    userId?: string
    type?: BiodataType
    companyId?: BiodataType
}

