import { createMuiTheme } from "@material-ui/core";
import type {} from "@material-ui/lab/themeAugmentation";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";

type overridesNameToClassKey = {
    [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

type CustomType = {
    MuiPickersBasePicker: {
        pickerView: {
            maxWidth?: string;
        };
    };
};

declare module "@material-ui/core/styles/overrides" {
    interface ComponentNameToClassKey extends overridesNameToClassKey {}
    export interface ComponentNameToClassKey extends CustomType {}
}

declare module "@material-ui/core/styles/createMuiTheme" {
    interface Theme {
        sidebarWidth: number;
        sidebarMobileHeight: number;
        brand: string;
        borderRadius: string;
        footerHeight: number;
        mobileTopBarHeight: number;
        mobileFooterHeight: number;
        sidebarMobilePadding: number;
        participantBorderWidth: number;
    }

    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        sidebarWidth?: number;
        sidebarMobileHeight?: number;
        brand: string;
        borderRadius: string;
        footerHeight: number;
        mobileTopBarHeight: number;
        mobileFooterHeight: number;
        sidebarMobilePadding: number;
        participantBorderWidth: number;
    }
}

export const defaultTheme = createMuiTheme();
const defaultBorder = "1px solid rgba(0, 0, 0, 0.12)";
const spacing = 8;

export const palette = {
    primary: {
        main: "#78D008",
        light: "#86DB25",
        dark: "#419E00",
        contrastText: "#FBFBFB",
    },
    secondary: {
        main: "#303030",
        light: "#707070",
        dark: "#070707",
        contrastText: "#FBFBFB",
    },
    success: {
        main: "rgba(3, 119, 116, 1)",
        light: "rgba(158, 200, 199, 1)",
    },
    background: {
        default: "#FFFDFA",
    },
};

const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
};

const defaultSpacing = 8;

type PropertyName =
    | "margin"
    | "marginTop"
    | "marginRight"
    | "marginBottom"
    | "marginLeft"
    | "padding"
    | "paddingTop"
    | "paddingRight"
    | "paddingBottom"
    | "paddingLeft";

const spacings = (propertyName: PropertyName) => {
    let obj = {};
    for (let i = 0; i <= 20; i++) {
        obj = {
            ...obj,
            [`&-${i}`]: {
                [propertyName]: `${defaultSpacing * i}px`,
            },
        };
    }
    return obj;
};

export default createMuiTheme({
    spacing: (factor) => `${defaultSpacing * factor}px`,
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "html, body, #root": {
                    minHeight: "100vh",
                    color: "red",
                    background: "linear-gradient(#272727, #070707) no-repeat",
                    // background: 'linear-gradient(180deg, rgba(35,28,144,1) 0%, rgba(18,34,148,1) 13%, rgba(0,212,255,1) 100%)',

                    "& .m": {
                        "&t": spacings("marginTop"),
                        "&r": spacings("marginRight"),
                        "&b": spacings("marginBottom"),
                        "&l": spacings("marginLeft"),
                        ...spacings("margin"),
                    },
                    "& .p": {
                        "&t": spacings("paddingTop"),
                        "&r": spacings("paddingRight"),
                        "&b": spacings("paddingBottom"),
                        "&l": spacings("paddingLeft"),
                        ...spacings("padding"),
                    },
                },
                ".hidden": {
                    display: "none !important",
                },
            },
        },
        MuiButton: {
            root: {
                "&.social-media": {
                    height: "12px",
                    fontWeight: 500,
                    padding: 0,
                    fontSize: "16px",
                    lineHeight: "16px",
                    background: "transparent",
                    color: "#fff",
                    "& svg": {
                        marginRight: "6px",
                    },
                    "&.active": {
                        color: palette.primary.main,
                    },
                },
                "&.main-button": {
                    height: "48px",
                    borderRadius: "24px",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                    background: palette.primary.main,
                    color: "#fff",
                    "&:disabled": {
                        background: palette.secondary.main,
                        color: palette.secondary.light,
                    },
                    "&:hover": {
                        background: palette.primary.main,
                        color: "#fff",
                    },
                },
                "&.secondary-button": {
                    height: "48px",
                    borderRadius: "24px",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                    background: palette.secondary.dark,
                    color: "#fff",
                    border: "solid " + palette.secondary.main + " 2px",
                    "&:disabled": {
                        background: palette.secondary.dark,
                        color: palette.secondary.light,
                    },
                    "&:hover": {
                        background: palette.secondary.dark,
                        color: "#fff",
                    },
                },
                "&.loading-button": {
                    height: "48px",
                    borderRadius: "24px",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                    background: "rgba(0,0,0,0)",
                    color: "#fff",
                    border: "solid " + palette.secondary.main + " 2px",
                    "&:disabled": {
                        background: "rgba(0,0,0,0)",
                        color: palette.secondary.light,
                    },
                    "&:hover": {
                        background: "rgba(0,0,0,0)",
                        color: "#fff",
                    },
                },
                "&.text-button-subtitle": {
                    height: "12px",
                    fontWeight: 500,
                    padding: 0,
                    fontSize: "10px",
                    lineHeight: "16px",
                    background: "transparent",
                    color: "#d4d4d4",
                    textTransform: 'capitalize',
                    "& svg": {
                        marginLeft: "6px",
                    },
                    "&.active": {
                        color: palette.primary.main,
                    },
                },
            },
            text: {
                padding: "20px 15px",
            },
        },
        MuiTypography: {
            root: {
                color: palette.primary.contrastText,
            },
            h1: {
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "30px",
                lineHeight: "30px",
            },
            h2: {
                fontFamily: "Poppins",
                fontWeight: 200,
                fontSize: "20px",
                lineHeight: "28px",
            },
            h3: {
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "18px",
            },
            h4: {
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "14px",
            },
            h5: {
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "16px",
                color: palette.secondary.light,
            },
            h6: {
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "12px",
                color: palette.secondary.light,
            },
            subtitle1: {
                fontFamily: "Poppins",
                fontWeight: 100,
                fontSize: "12px",
                lineHeight: "12px",
            },
            subtitle2: {
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "12px",
            },
            overline: {
                fontFamily: "Poppins",
                fontSize: "10px",
                lineHeight: "10px",
                fontStyle: "italic",
            },
            body1: {
                fontFamily: "Poppins",
            },
            body2: {
                fontFamily: "Poppins",
            },
            button: {
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "16px",
            },
            caption: {
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "10px",
                lineHeight: "10px",
            },
            colorError: {
                color: palette.secondary.light,
            },
        },
        MuiAppBar: {
            root: {
                "&.bottom": {
                    color: "white",
                    backgroundColor: palette.secondary.dark + "00",
                    padding: 1 * defaultSpacing + "px",
                    top: "auto",
                    bottom: 0,
                },
            },
        },
        MuiInputBase: {
            root: {
                fontSize: "16px",
                "&.Mui-disabled": {
                    color: palette.secondary.light,
                },
            },
        },
        MuiCard: {
            root: {
                backgroundColor: palette.secondary.main,
                boxShadow: "none",
                borderRadius: "0px",
                padding: spacing * 2,
                marginTop: spacing * 1,
                color: palette.secondary.contrastText,
                "&.curved": {
                    borderRadius: "16px",
                },
            },
        },
        /* STARTS - Date Picker */
        MuiPickersClockNumber: {
            clockNumber: {
                color: palette.secondary.light,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                color: palette.secondary.contrastText,
                backgroundColor: palette.secondary.main,
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "transparent",
                color: palette.secondary.contrastText,
                "&:disabled": {
                    color: palette.secondary.light,
                },
            },
            dayLabel: {
                color: palette.secondary.contrastText,
                "&.button": {
                    color: palette.secondary.contrastText,
                },
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: palette.secondary.light,
                border: "white 1px solid",
                "&:hover": {
                    backgroundColor: palette.secondary.light,
                    border: "white 1px solid",
                },
            },
            dayDisabled: {
                color: palette.secondary.light,
            },
            day: {
                color: palette.secondary.contrastText,
            },
        },
        MuiPickersYear: {
            root: {
                color: palette.secondary.contrastText,
            },
            yearSelected: {
                color: palette.secondary.contrastText,
            },
            yearDisabled: {
                color: palette.secondary.light,
            },
        },
        /* ENDS - Date Picker */
        MuiSelect: {
            root: {
                // padding: '0.85em',
                "&.Mui-disabled": {
                    color: palette.secondary.light,
                },
            },
            icon: {
                color: palette.secondary.contrastText,
                "&.Mui-disabled": {
                    color: palette.secondary.light,
                },
            },
        },
        MuiTouchRipple: {
            root: {
                color: palette.secondary.light,
            },
        },
        MuiMenu: {
            list: {
                backgroundColor: palette.secondary.main,
                color: palette.secondary.contrastText,
            },
        },
        // Applied to the <li> elements
        MuiMenuItem: {
            root: {
                fontSize: "16px",
            },
        },
        MuiDialogActions: {
            root: {
                padding: "16px",
                backgroundColor: palette.secondary.main,
                ".MuiButtonBase": {
                    root: {
                        backgroundColor: palette.secondary.main,
                    },
                },
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: "14px",
                marginBottom: "0.2em",
                fontWeight: 500,
            },
            outlined: {
                color: palette.secondary.light,
                "&.MuiInputLabel-shrink": {
                    color: palette.secondary.contrastText + " !important",
                },
            },
            filled: {
                color: palette.secondary.contrastText + " !important",
            },
        },
        MuiOutlinedInput: {
            root: {
                color: palette.secondary.contrastText,
                borderColor: palette.secondary.light,
            },
            notchedOutline: {
                borderWidth: "2px",
                color: palette.secondary.contrastText + " !important",
                borderColor: palette.secondary.light + " !important",
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: "#FFF0",
            },
            outlined: {
                border: defaultBorder,
            },
            rounded: {
                borderRadius: "16px",
            },
        },
        MuiStepLabel: {
            iconContainer: {
                paddingRight: "16px",
            },
        },
        MuiStepIcon: {
            root: {
                height: "40px",
                width: "40px",
                "&$active": {
                    color: "transparent",
                },
            },
            text: {
                fontFamily: "Fira Sans",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "18px",
                color: "rgba(0, 0, 0, 0.6)",
                fill: "",
            },
        },
        MuiStepConnector: {
            vertical: {
                padding: "0",
                marginLeft: "20px",
            },
            line: {
                borderColor: "rgba(0,0,0,0.12)",
            },
            lineVertical: {
                borderLeftStyle: "dashed",
                minHeight: `${spacing * 4}px`,
            },
        },
    },
    typography: {
        fontFamily: "Poppins, Inter, sans-serif",
    },
    breakpoints,
    palette,
    borderRadius: "16px",
    brand: "#017874",
    footerHeight: 64,
    mobileFooterHeight: 56,
    sidebarWidth: 230,
    sidebarMobileHeight: 90,
    sidebarMobilePadding: 8,
    participantBorderWidth: 0,
    mobileTopBarHeight: 52,
});
