import { UserCredential } from "firebase/auth";

export class User {

    id: string = '';
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    imgUrl: string = '';
    firebaseUserId: string = '';
    username: string = ''
    employeeCode: string = ''
    companyId: string = ''
    birthday: Date = new Date()
    height: number = 0
    heightUnit: string = ''
    pronouns: string[] = []
    userPreferences: string[] = []
    friends: Partial<User>[] = []
    constructor(obj?: Partial<User>) {
        this.id = obj?.id || this.id
        this.email = obj?.email || this.email
        this.firstName = obj?.firstName || this.firstName
        this.lastName = obj?.lastName || this.lastName
        this.imgUrl = obj?.imgUrl || this.imgUrl
        this.firebaseUserId = obj?.firebaseUserId || this.firebaseUserId
        this.username = obj?.username?.trim().toLowerCase() || this.username?.trim().toLowerCase()
        this.employeeCode = obj?.employeeCode || this.employeeCode
        this.companyId = obj?.companyId || this.companyId
        this.birthday = obj?.birthday || this.birthday
        this.height = obj?.height || this.height
        this.heightUnit = obj?.heightUnit || this.heightUnit
        this.pronouns = obj?.pronouns || this.pronouns
        this.userPreferences = obj?.userPreferences || this.userPreferences
    }

    getPublicData() {
        return new User({
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            imgUrl: this.imgUrl,
            username: this.username.trim().toLowerCase(),
        })
    }

    addFriend(user: Partial<User>) {
        this.friends.push({
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            imgUrl: user.imgUrl,
            username: user?.username?.trim().toLowerCase(),
        })
    }
    setUserWithFirebaseData(userData: UserCredential) {
        if (!userData.user)
            throw new Error(
                'No google user data found, cant do setUserWithFirebaseData'
            );

        this.firstName = userData?.user?.displayName || this.firstName;
        this.email = userData?.user?.email || this.email;
        this.id = userData?.user?.uid;
        this.firebaseUserId = userData?.user?.uid;
        this.imgUrl = userData?.user?.photoURL || '';
    }

    toPlainObject(): any {
        const plainObj: any = JSON.parse(JSON.stringify(this));
        return plainObj;
    }
}