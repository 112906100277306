import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = ( props = {} ) => {
    //const matches = useMediaQuery('(min-width:450px)');
    const styles = makeStyles( ( theme: Theme ) =>
        createStyles( {
            container: {
                margin: theme.spacing(0),
                padding: theme.spacing(2, 1),
                backgroundColor: '#3f3f3f',
                borderRadius: theme.spacing(2),
                color: '#fff'
            },
            card: {
                width: '100%',
                height: '100px',
                margin: theme.spacing(2, 0),
                padding: theme.spacing(1),
                borderRadius: theme.spacing(1),
                backgroundColor: '#4f4f4f',
                color: '#fff'
            },
            icon: {
                color: '#fff',
                fontSize: '18px'
            }
        } )
    );
    return styles();
};
