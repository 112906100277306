import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faPhotoVideo, faCalendarCheck, faPenSquare, faRunning } from '@fortawesome/free-solid-svg-icons';
import { useStyles } from './FabButton.style';
import { useHistory } from 'react-router-dom';
import { routes } from '../../routers/routes';
import clsx  from 'clsx'
const actions = [
    { icon: <FontAwesomeIcon icon={faCheckDouble} />, name: 'Challenge', route: '/challenges/new/1' },
    { icon: <FontAwesomeIcon icon={faPhotoVideo} />, name: 'Post', route: '/my-community/post/new' },
    { icon: <FontAwesomeIcon icon={faCalendarCheck} />, name: 'Add My Data', route: routes.NEW_BIODATA.CHOOSE_TYPE },
    { icon: <FontAwesomeIcon icon={faRunning} />, name: 'Add Activity', route: routes.ADD_ACTIVITY },
    { icon: <FontAwesomeIcon icon={faPenSquare} />, name: 'Journal', route: routes.JOURNAL.VIEW },
];

export const FabButton = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const history = useHistory()

    const handleOpen = () => {

        setOpen(true);
    };

    const handleToggle = (e?: React.SyntheticEvent, route?: string) => {
        e && e.stopPropagation()
        e && e.preventDefault()

        setOpen(!open);
    };
    const handleClose = (e?: React.SyntheticEvent, route?: string) => {
        e && e.stopPropagation()
        e && e.preventDefault()

        setOpen(false);
        route && history.push(route)
    };

    return (
        <>
            {/* <div className={classes.root}> */}
            {open &&
                <div
                    className={classes.backdrop}
                    onClick={handleClose}
                ></div>
            }
            <SpeedDial
                ariaLabel="Options"
                className={clsx(classes.speedDial)}
                icon={<SpeedDialIcon />}
                onClick={handleToggle}
                onOpen={handleOpen}
                open={open}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        className={clsx(classes.action, 'pepe')}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={(e) => handleClose(e, action.route)}
                    />
                ))}
            </SpeedDial>
            {/* </div> */}
        </>
    );
}
