export const pageVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,

    }

}
export const pageTransitions = {
    duration: 0.5,
    type: 'tween',
    ease: 'anticipate'
}