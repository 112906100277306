import { Card, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useStyles } from "./StopwatchCounter.styles";

interface Props {
    seconds: number
}

export const StopwatchCounter = ({seconds}: Props) => {
    const classes = useStyles();
    const [h1, setH1] = useState('0');
    const [h2, setH2] = useState('0');
    const [m1, setM1] = useState('0');
    const [m2, setM2] = useState('0');
    const [s1, setS1] = useState('0');
    const [s2, setS2] = useState('0');
    useEffect(() => {
        const tempHours = ("00"+  Math.floor(seconds/60/60).toFixed(0)).slice(-2)
        const tempMinutes = ("00"+ Math.floor((seconds - (Number(tempHours)*60*60))/60).toFixed(0)).slice(-2)
        const tempSeconds = ("00"+  Math.floor(seconds - (Number(tempHours)*60*60) - (Number(tempMinutes)*60)).toFixed(0)).slice(-2)
        setH1(tempHours.substring(0,1))
        setH2(tempHours.substring(1))
        setM1(tempMinutes.substring(0,1))
        setM2(tempMinutes.substring(1))
        setS1(tempSeconds.substring(0,1))
        setS2(tempSeconds.substring(1))
    }, [seconds]);

    return (
        <>
            <Grid
                className={classes.root}
                container
                direction="row"
                justify="center"
                alignContent="stretch"
                wrap="nowrap"
            >
                <Grid item xs={4} container justify="center">
                    <Typography variant="subtitle1">Hours</Typography>
                </Grid>
                <Grid item xs={4} container justify="center">
                    <Typography variant="subtitle1">Minutes</Typography>
                </Grid>
                <Grid item xs={4} container justify="center">
                    <Typography variant="subtitle1">Seconds</Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="stretch"
                wrap="nowrap"
            >
                <Grid item xs={4}>
                    <Card className={clsx(classes.card, classes.h1)}>{h1}{' '}{h2}</Card>
                </Grid>
                {/* <Grid item xs={2}>
                    <Card className={clsx(classes.card, classes.h2)}>{h2}</Card>
                </Grid> */}
                <Grid item xs={4}>
                <Card className={clsx(classes.card, classes.h1)}>{m1}{' '}{m2}</Card>
                </Grid>
                {/* <Grid item xs={2}>
                    <Card className={clsx(classes.card, classes.m2)}>{m2}</Card>
                </Grid> */}
                <Grid item xs={4}>
                    <Card className={clsx(classes.card, classes.h1)}>{s1}{' '}{s2}</Card>
                </Grid>
                {/* <Grid item xs={2}>
                    <Card className={clsx(classes.card, classes.s2)}>{s2}</Card>
                </Grid> */}
            </Grid>
        </>
    );
};
