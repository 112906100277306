import React, { useCallback } from "react";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import { useForm } from "../../../../hooks/useForm";
import { A3DatePicker } from "../../../../components/DatePicker/DatePicker";

import { User } from "../../../../models/user.model";
import {  useAppDispatch } from "../../../../redux/store/store";
import { updateUserData } from "../../../../redux/slices/auth/auth.slice";

interface Props {
    initialState: Partial<User>
}

export const MoreAboutYourself = ({initialState}: Props) => {
    
    const dispatch = useAppDispatch();
    
    const [
        { username, firstName, lastName, birthday, pronouns },
        handleInputChange,
    ] = useForm(initialState);

    const handleSaveData = (e: any) => {
        handleInputChange(e);
        if(e.target.name === 'birthday') {
            dispatch(updateUserData({birthday: e.target.value}))
        }
        
    };

    const storeUser = useCallback(() => {
        dispatch(
            updateUserData({
                username,
                firstName,
                lastName,
                birthday,
                pronouns,
            })
        );
    }, [username, firstName, lastName, birthday, pronouns, dispatch]);

    return (
        <Grid container justify="center" direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h1" align="center" className="mt-2">
                    Welcome!
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h2" align="left">
                    Tell us a little bit about yourself
                </Typography>
            </Grid>
            <Grid item>
                <TextField
                    type="text"
                    fullWidth
                    name="username"
                    value={username}
                    label="User Name"
                    onChange={handleSaveData}
                    onBlur={storeUser}
                    placeholder="Write your user name"
                    variant="outlined"
                    className="mt-2"
                    tabIndex={1}
                />
                <TextField
                    type="text"
                    fullWidth
                    name="firstName"
                    value={firstName}
                    label="First Name"
                    onChange={handleSaveData}
                    onBlur={storeUser}
                    placeholder="Write your first name"
                    variant="outlined"
                    className="mt-2"
                    tabIndex={2}
                />
                <TextField
                    type="text"
                    fullWidth
                    name="lastName"
                    value={lastName}
                    label="Last Name"
                    onChange={handleSaveData}
                    onBlur={storeUser}
                    placeholder="Write your last name"
                    variant="outlined"
                    className="mt-2"
                    tabIndex={3}
                />
                <A3DatePicker
                    name="birthday"
                    openTo="year"
                    label="Date of Birth"
                    date={birthday}
                    dateChange={handleSaveData}
                    
                />
                <FormControl variant="outlined" fullWidth className="mt-2">
                    <InputLabel id="for-pronouns">Your pronouns</InputLabel>
                    <Select
                        labelId="for-pronouns"
                        label="Pronouns"
                        value={pronouns}
                        name="pronouns"
                        onChange={handleSaveData}
                        onBlur={storeUser}
                        tabIndex={5}
                    >
                        <MenuItem value="" selected={pronouns === ""}>
                            <em>I prefer not to say</em>
                        </MenuItem>
                        <MenuItem
                            value="She/Her/Hers"
                            selected={pronouns === "She/Her/Hers"}
                        >
                            She/Her/Hers
                        </MenuItem>
                        <MenuItem
                            value="He/Him/His"
                            selected={pronouns === "He/Him/His"}
                        >
                            He/Him/His
                        </MenuItem>
                        <MenuItem
                            value="They/Them/Their"
                            selected={pronouns === "They/Them/Their"}
                        >
                            They/Them/Their
                        </MenuItem>
                        <MenuItem
                            value="Ze/Hir/Hirs"
                            selected={pronouns === "Ze/Hir/Hirs"}
                        >
                            Ze/Hir/Hirs
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};
