import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP__FB_APIKEY,
    authDomain: process.env.REACT_APP__FB_AUTHDOMAIN,
    projectId: process.env.REACT_APP__FB_PROJECTID,
    messagingSenderId: process.env.REACT_APP__FB_MESSAGINGSENDERID,
    appId: process.env.REACT_APP__FB_APPID,
    storageBucket: process.env.REACT_APP__FB_STORAGE_BUCKET,
};

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)
auth.languageCode = 'en';
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

// Bucket
const firebaseStorage = getStorage(app);

//Realtime database
const rtdb = getDatabase(app);
/**
 * Stores data in the realtime database
 * @param {string} path path in the rtdb json object where the data should be stored
 * @param data an object with the data to be stored
 * @returns 
 */
const addDataRTDB = (path: string, data: Object): Promise<void> => set(ref(rtdb, path), { ...data });

export {
    addDataRTDB,
    auth,
    rtdb,
    firebaseStorage,
    googleAuthProvider,
    facebookAuthProvider,
};
