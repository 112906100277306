import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
          padding: theme.spacing(2, 0)
        },
        card: {
          height: theme.spacing(12),
          margin: theme.spacing(0, 0.5),
          textAlign: 'center',
          borderRadius: theme.spacing(1),
          fontSize: '44px',
          
        },
        h1: {},
        h2: {},
        m1: {},
        m2: {},
        s1: {},
        s2: {},
    })
);
