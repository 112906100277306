import { Typography } from "@material-ui/core";
import React from "react";
import { ChallengeCardList } from "../../../components/ChallengeCardList/ChallengeCardList";
import { MenuBar } from "../../../components/MenuBar/MenuBar";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";

export const CoopChallengesScreen = () => {
    
    return (
        <>
            <NavigationBar title={"Cooperative Challenges"} />

            <Typography variant={"h3"} className={"mt-2"}>
                Team Challenges
            </Typography>
            <ChallengeCardList type="cooperative" className="mt-1" />

            <MenuBar />
        </>
    );
};
