import { useState } from 'react'
import { useStyles } from './AchievmentsMenu.style'
import AppBar from '@material-ui/core/AppBar';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal, faRunning } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
interface Props {
    onClick: (route: string) => void
    currentOption: string

}
export const AchievmentsMenu = ({onClick, currentOption}: Props) => {
    const classes = useStyles()
    const [currentPath, setCurrentPath] = useState(currentOption)

    const goTo = (route: string) => {
        onClick(route)
        setCurrentPath(route)
    }
 

    return (
        <>
            <AppBar position="fixed" color="secondary" className={classes.appBarBottom}>

                <Grid container justify="space-around" alignContent="center" alignItems="center">
                <Grid item>
                        <Grid container direction="column" justify="center" alignItems="center" alignContent="center">
                            <Grid item>
                                <IconButton
                                    onClick={() => { goTo('Achievements') }}
                                    className={clsx(classes.button,currentPath === 'Achievements' ? 'active' : '')}
                                    color="inherit"
                                >
                                    <FontAwesomeIcon icon={faRunning} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography variant={'subtitle1'}>
                                    Achievements
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center" alignContent="center">
                            <Grid item>
                                <IconButton
                                    onClick={() => { goTo('Badges') }}
                                    className={clsx(classes.button,currentPath === 'Badges' ? 'active' : '')}
                                    color="inherit"
                                >
                                    <FontAwesomeIcon icon={faMedal} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography variant={'subtitle1'}>
                                    Badges
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                   

                </Grid>

            </AppBar>
            <div className={classes.spacer}></div>
        </>
    )
}
