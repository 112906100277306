import React from "react";
import {
    faFistRaised,
    faHeart,
    faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@material-ui/core";
import { useStyles } from "./PostActions.style";
import { useState } from "react";
import { PostReactions } from "../../models/post-reactions.model";
import { useEffect } from "react";
import { Post } from "../../models/post.model";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";
import { User } from "../../models/user.model";
import { PostReactionActionTypes, PostReactionTypes } from "../../models/types";
import { PostApi } from "../../apis/postApi";
import clsx from 'clsx'
interface Props {
    post: Post;
}

export const PostActions = ({ post }: Props) => {
    const classes = useStyles();
    const currentUser: User = useSelector(
        (state: RootState) => state.auth.user
    );
    const [reactions, setReactions] = useState<PostReactions>(
        new PostReactions()
    );

    const createReaction = async (reactionType: PostReactionTypes) => {
        let action: PostReactionActionTypes = reactions.hasUserReactedWith(
            reactionType
        )
            ? "decrement"
            : "increment";

        setReactions((currentReaction) => {
            const newReaction = new PostReactions({ ...currentReaction });
            if (action === "increment") {
                newReaction.addUserReaction(currentUser.id, reactionType);
            } else {
                newReaction.removeUserReaction(currentUser.id, reactionType);
            }
            return newReaction;
        });

        await PostApi.postPostReaction(
            post.id,
            reactionType,
            action,
            currentUser.id
        );
    };
    const fetchReactions = async () => {
        if (post.id) {
            let reactionsDB = await PostApi.getPostReactions(post.id);
            reactionsDB.checkCurrentUserReactions(currentUser.id);
            setReactions(reactionsDB);
        }
    };

    useEffect(() => {
        fetchReactions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post.id]);

    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.root}
            spacing={2}
        >
            <Grid item container direction="row">
                <Grid item xs={3}>
                    <Button
                        onClick={() => {
                            createReaction("hearts");
                        }}
                        className={clsx('social-media', 'a', {'active': reactions.heartsCurrentUserReactedWith} )}
                    >
                        <FontAwesomeIcon icon={faHeart} />
                        {reactions.hearts}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        onClick={() => {
                            createReaction("likes");
                        }}
                        className={clsx('social-media', 'a', {'active': reactions.likesCurrentUserReactedWith} )}
                    >
                        <FontAwesomeIcon icon={faThumbsUp} />
                        {reactions.likes}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        onClick={() => {
                            createReaction("fists");
                        }}
                        className={
                            "social-media " +
                            (reactions.fistsCurrentUserReactedWith
                                ? "active"
                                : "")
                        }
                    >
                        <FontAwesomeIcon icon={faFistRaised} />
                        {reactions.fists}
                    </Button>
                </Grid>
               
            </Grid>
        </Grid>
    );
};
