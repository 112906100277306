import { Grid, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { PostApi } from "../../../apis/postApi";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { PostCard } from "../../../components/PostCard/PostCard";
import { Post } from "../../../models/post.model";
import { User } from "../../../models/user.model";
import { RootState } from "../../../redux/store/store";
import { routes } from "../../../routers/routes";
import { LoadingScreen } from "../../LoadingScreen/LoadingScreen";
import { MyCommunityMenu } from "./components/MyCommunityMenu";

export const MyCommunityScreen = () => {
    const currentUser: User = useSelector(
        (state: RootState) => state.auth.user
    );
    const [postsDB, setPostsDB] = useState<Post[]>([]);
    const [filteredPosts, setFilteredPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentView, setCurrentView] = useState(
        localStorage.getItem("community-view") || "personal"
    );
    const notifyPostDelete = async () => {
        setLoading(true);
        fetchPosts();
    };
    const fetchPosts = async () => {
        setLoading(true);
        const poststemp = await PostApi.getPosts(currentUser.id);
        setPostsDB(poststemp);
        filterPosts(poststemp);
    };
    const filterPosts = async (posts: Post[] = []) => {
        setLoading(true);
        let filteredPostsTemp: Post[] = [];
        if (currentView === "company") {
            filteredPostsTemp = posts.filter(
                (p) => p.challenge.companyId === currentUser.companyId
            );
        }
        if (currentView === "personal") {
            filteredPostsTemp = posts.filter(
                (p) => p.challenge.companyId !== currentUser.companyId
            );
        }
        setFilteredPosts(filteredPostsTemp);
        setLoading(false);
    };
    const changeView = (route: "personal" | "company") => {
        localStorage.setItem("community-view", route);
        setCurrentView(route);
    };

    useEffect(() => {
        fetchPosts();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (postsDB.length === 0) return;
        filterPosts(postsDB);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentView]);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <NavigationBar
                title={"My Community"}
                backArrowRoute={routes.MAIN.DASHBOARD}
            />
            {filteredPosts.map((p: Post, i: number) => (
                <PostCard
                    className={"mt-4"}
                    key={i}
                    post={p}
                    notifyPostDelete={notifyPostDelete}
                />
            ))}
            {filteredPosts.length === 0 && (
                <Grid
                    style={{ minWidth: "100%", minHeight: "400px" }}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                >
                    <Typography
                        variant={"body1"}
                        style={{
                            textAlign: "center",
                            minWidth: "100%",
                            minHeight: "100%",
                        }}
                    >
                        No post yet, try to enroll in some challenges and start
                        posting. <br />
                        <br />
                        A3 community is the core for success.
                    </Typography>
                </Grid>
            )}

            <MyCommunityMenu onClick={changeView} currentOption={currentView} />
        </>
    );
};
