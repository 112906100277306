import React from 'react';
import { Logo } from '../../components/Logo/Logo';
import { useStyles } from './LoadingScreen.styles';

export const LoadingScreen = () => {

    const classes = useStyles();
    let slideDirection = '';


    return (
        <div className={`${classes.root} ${slideDirection}`}>
            <Logo className={classes.logo} />
        </div>
    );
};
