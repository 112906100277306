import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useState } from "react";
import { StepDots } from "../../components/StepDots/StepDots";
import { MoreAboutYourself } from "./components/MoreAboutYourself/MoreAboutYourself";
import { useStyles } from "./OnboardingScreen.styles";
import { UserPreferences } from "./components/UserPreferences/UserPreferences";
import { MotivationalPhrase } from "./components/MotivationalPhrase/MotivationalPhrase";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";
import { User } from "../../models/user.model";
import { useHistory } from "react-router-dom";
import { modal } from "../../components/modal/modal";
import { routes } from "../../routers/routes";
import { UsersApi } from "../../apis/userApi";
import { startLoading, stopLoading } from "../../redux/slices/ui/ui.slice";
import { EmployeeCode } from "./components/EmployeeCode/EmployeeCode";
import { updateUserData } from "../../redux/slices/auth/auth.slice";
import { NextButton } from "./components/NextButton";
import { CompanyApi } from "../../apis/companiesApi";

export const OnboardingScreen = () => {
    const classes = useStyles();
    const user: User = useSelector((state: RootState) => state.auth.user);

    const history = useHistory();
    const dispatch = useDispatch();

    const [step, setStep] = useState<number>(0);

    const handleBack = () => {
        setStep((s) => s - 1);
    };

    const isValidUser = async () => {
        dispatch(startLoading());
        if (!user.username) {
            modal("error", "", "Write something as your username, this is how the community will identify you.");
            return false;
        }

        if (!user.username.match(/^[0-9a-zA-Z]+$/)) {
            modal(
                "error",
                "",
                "Username can only contain characters and numbers."
            );
            return false;
        }

        const userDB = await UsersApi.getUserByUsername(
            user.username.trim().toLowerCase()
        );

        if (userDB?.id && userDB.id !== user.id) {
            modal(
                "error",
                "",
                "Username already exist, please select another one."
            );
            return false;
        }

        return true;
    };
    const isCompanyValid = async () => {
        if (!user.companyId) return true; // No company then no verification
        const company = await CompanyApi.getCompanyByCode(user.companyId);
        if (!company?.id) {
            modal(
                "error",
                "Company not found",
                `We couldn't find any company with the code ${user.companyId}, make sure it is correct or validate it with your employer.`
            );
            return false;
        }
        return true;
    };

    const handleNext = async () => {
        if (step === 3) return history.push(routes.MAIN.DASHBOARD);

        if (step === 2 && ! await isCompanyValid()) return;

        if (step === 0) {
            const valid = await isValidUser();
            dispatch(stopLoading());
            if (!valid) return;
        }
        const updatedUser = new User(user);
        UsersApi.updateUser(user.id, updatedUser);
        dispatch(updateUserData({ ...updatedUser }));

        setStep((s) => s + 1);
    };

    return (
        <>
            {step === 0 && <MoreAboutYourself initialState={user} />}
            {step === 1 && <UserPreferences />}
            {step === 2 && <EmployeeCode />}
            {step === 3 && <MotivationalPhrase />}
            <div className={classes.createAccount}>
                <Grid
                    container
                    justify="center"
                    direction="row"
                    className={"mb-4"}
                >
                    <StepDots amount={4} current={step} />
                </Grid>
                <Grid container justify="center" direction="row">
                    <Grid item xs={6} className={"pr-1"}>
                        {step > 0 && (
                            <Button
                                fullWidth
                                className="secondary-button"
                                onClick={handleBack}
                            >
                                back
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={6} className={"pl-1"}>
                        <NextButton handleNext={handleNext} step={step} />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
