import { PostComment } from "../models/post-comment.model";
import { PostReactions } from "../models/post-reactions.model";
import { Post } from "../models/post.model";
import { PostReactionActionTypes, PostReactionTypes } from "../models/types";
import { User } from "../models/user.model";
import { uploadFileFirebase } from "../providers/firebase";
import store from "../redux/store/store";
import { logError } from "../utils/error";
import { A3Server } from "../utils/fetch";


export class PostApi {
    static getPosts = async (userId: string): Promise<Post[]> => {
        try {
            const a3Server = new A3Server();
            const posts = await a3Server.get<Post[]>(
                `v1/posts/?forUserId=${userId}`
            );
            return posts;
        } catch (e) {
            console.log(e);
            throw e;
        }
    };
    static deletePost = async ({
        id: postId,
        user: owner,
    }: Post): Promise<void> => {
        const currentUser: User = store.getState().auth.user;

        if (currentUser.id !== owner.id) return;

        try {
            const a3Server = new A3Server();
            await a3Server.delete<void>(`v1/posts/${postId}`);
        } catch (e) {
            console.log(e);
            throw e;
        }
    };
    static getPost = async (postId: string): Promise<Post> => {
        if (!postId) {
            throw new Error("To get a post you must pass a post id");
        }
        try {
            const a3Server = new A3Server();
            const post = await a3Server.get<Post>(`v1/posts/${postId}`);
            return post;
        } catch (e) {
            console.log(e);
            throw e;
        }
    };

    static createPost = async (post: Partial<Post>, file: File): Promise<Post> => {
        if (!post) {
            console.error("No post...", post);
            throw new Error("No post...");
        }


        let newPost = new Post()

        const a3Server = new A3Server();
        newPost = await a3Server.post<Post>(`v1/posts`, post);

        let url = ''
        if (file) {
            url = await PostApi.uploadPostMedia(file, newPost.id)
            const mediaFileURL = url
            const mediaFile = file.name

            newPost = await a3Server.put<Post>(`v1/posts/${newPost.id}`, { mediaFileURL, mediaFile });
        }

        return newPost

    };

    static uploadPostMedia = async (media: File, postId: string) => {
        if (!postId) throw new Error("No post Id...");
        if (!media) throw new Error("No post Media...");

        try {
            // UPLOAD
            // const formdata = new FormData();
            // formdata.append("postMedia", media, media.name);

            // const a3Server = new A3Server()
            // const url = await a3Server.upload<string>(`v1/posts/${postId}/postMedia`, formdata)

            const url = await uploadFileFirebase(`posts/${postId}`, media)

            return url;

        } catch (e) {
            console.log(e);
            throw e
        }
    };
    static postComment = async (postId: string, comment: PostComment) => {

        if (!postId) throw new Error("No post Id...");
        if (!comment.comment) throw new Error("Comment has no comment...");

        try {

            const a3Server = new A3Server();
            const newPost = await a3Server.post<void>(`v1/posts/${postId}/comments`, comment);
            return newPost;

        } catch (e) {
            console.log(e);
            throw e;
        }
    };

    static deleteComment = async (comment: PostComment): Promise<void> => {
        if (!comment?.postId) throw new Error("No post Id...");
        try {

            const a3Server = new A3Server();
            await a3Server.post<void>(`v1/posts/${comment.postId}/comments-remove`, comment);

        } catch (e) {
            logError('deleteComment', e, e)
            throw e;
        }

    };

    static getComments = async (postId: string): Promise<PostComment[]> => {
        if (!postId) throw new Error("No post Id...");

        try {
            const a3Server = new A3Server();
            const postComments = await a3Server.get<PostComment[]>(`v1/posts/${postId}/comments`);

            return postComments.map(c => new PostComment(c));
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
    static postPostReaction = async (
        postId: string,
        reactionType: PostReactionTypes,
        action: PostReactionActionTypes,
        userId: string
    ) => {
        if (!postId) throw new Error("No post Id...");
        if (!reactionType) throw new Error("no reactionType...");

        try {

            const a3Server = new A3Server();
            await a3Server.post<void>(`v1/posts/${postId}/reactions`, { reactionType, action });

        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    static getPostReactions = async (
        postId: string
    ): Promise<PostReactions> => {
        if (!postId) throw new Error("No post Id...");

        try {

            const a3Server = new A3Server();

            const postReactions = await a3Server.get<PostReactions>(`v1/posts/${postId}/reactions`);

            return new PostReactions(postReactions);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
}