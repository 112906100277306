import confetti from "canvas-confetti";

export const confettiAnimation = (text: string ) => {
    var myCanvas = document.createElement("canvas");
    var htmlText = document.createElement("div");
    var textContainer = document.createElement("div");
    myCanvas.style.position = "absolute";
    myCanvas.style.top = "0";
    myCanvas.style.left = "0";
    myCanvas.style.height = "100%";
    myCanvas.style.width = "100%";
    myCanvas.style.zIndex = "10000";
    
    htmlText.style.position = "absolute";
    htmlText.style.top = "50vh";
    htmlText.style.left = "50vw";
    htmlText.style.width = "90%";
    htmlText.style.fontSize = "50px";
    htmlText.style.color = "#FFF";
    htmlText.style.transform = "translate(-50%, -15%)";
    htmlText.style.zIndex = "10001";
    htmlText.style.textAlign = "center";
    htmlText.style.textShadow = "0 0 10px #FFF";
    htmlText.style.lineHeight = "58px";
    htmlText.innerHTML = text
    
    textContainer.style.position = "absolute";
    textContainer.style.top = "0";
    textContainer.style.left = "0";
    textContainer.style.width = "100vw";
    textContainer.style.height = "100vh";
    textContainer.style.backgroundColor = "rgba(0,0,0,0.8)";
    textContainer.style.fontSize = "50px";
    textContainer.style.color = "#FFF";
    textContainer.style.zIndex = "9999";
    document.body.appendChild(htmlText);
    document.body.appendChild(myCanvas);
    document.body.appendChild(textContainer);

    var myConfetti = confetti.create(myCanvas, {
        resize: true,
        useWorker: true,
    });
    myConfetti({
        particleCount: 300,
        spread: 100,
        origin: { x: 0.5, y: 1 },
        // any other options from the global
        // confetti function
    }).then(() => {
        document.body.removeChild(myCanvas);
        document.body.removeChild(htmlText);
        document.body.removeChild(textContainer);
    });
}