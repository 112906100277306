import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Challenge } from '../../../../models/challenge.model'
import { RootState } from '../../../../redux/store/store'


export const NewChallengeSummary = () => {

    const challenge: Challenge = useSelector( ( state: RootState ) => new Challenge(state.challenge.challenge) )

    return (
        <Grid container direction="column" className={'mt-5'}>
            <Grid item>
                <Typography variant={'caption'} >
                    Name
                </Typography>
                <Typography variant={'body2'} >
                    {challenge.name}
                </Typography>
            </Grid>
            <Grid item className={'mt-2'}>
                <Typography variant={'caption'} >
                    Category
                </Typography>
                <Typography variant={'body2'} >
                    {challenge.challengeCategory.name}
                    {
                        challenge?.challengeActivity?.name
                            ? ' - ' + challenge?.challengeActivity?.name
                            : ''
                    }
                </Typography>
            </Grid>
            <Grid item className={'mt-2'}>
                <Typography variant={'caption'} >
                    Challenge
                </Typography>
                <Typography variant={'body2'} >
                    {`
                        ${challenge.quantity}
                        ${challenge.unit !== 'none' && challenge.unit ? challenge.unit : ' '}
                        ${challenge.interval}
                    `}
                </Typography>
            </Grid>
            <Grid item className={'mt-2'}>
                <Typography variant={'caption'} >
                    Duration
                </Typography>
                <Typography variant={'body2'} >
                    {challenge.getChallangeTime( true )}
                </Typography>
            </Grid>
            <Grid container direction="row" justify="center" className={'mt-5 p-3'}>
                <Typography variant={'h6'} align="center">
                    Stick to it: always try and finish what you started
                </Typography>
            </Grid>
        </Grid>

    )
}
