import { CategoryIntervalsTypes } from "../models/types"

export const useUtils = () => {

    const trasformInterval2unit = (interval: CategoryIntervalsTypes) => {
        switch (interval) {
            case 'daily': return 'days';
            case 'monthly': return 'months';
            case 'weekly': return 'weeks';
            default:
                console.error('Error trasformInterval2unit: this interval doesn\t have a equivalent unit' + interval);
                return '';
        }
    }

    const deleteDuplicates = (array: any[], idField = 'id') => {
        let ids = array.map(o => o.id)
        return array.filter(({ id }, index) => !ids.includes(id, index + 1))
    }

    const cutLongText = (text?: string, maxLength: number = 50): string => {
        if (!text) return ''

        if (text.length <= maxLength) return text;

        return text.slice(0, maxLength - 3) + '...'
    }

    return {
        trasformInterval2unit,
        cutLongText,
        deleteDuplicates
    }

}

//export for common non-react modules
// eslint-disable-next-line react-hooks/rules-of-hooks
export const utils = useUtils()