import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useForm } from '../../../../hooks/useForm'
import { A3DatePicker } from '../../../../components/DatePicker/DatePicker'
import { Challenge } from '../../../../models/challenge.model'
import { RootState } from '../../../../redux/store/store'
import { useDispatch, useSelector } from 'react-redux'
import { setChallenge } from '../../../../redux/slices/challenges/challenges.slice'


export const NewChallengeDates = () => {

    const challenge: Challenge = useSelector( ( state: RootState ) => new Challenge(state.challenge.challenge) )


    const [ { startsAt, endsAt }, handleInputChange ]: any = useForm( {
        startsAt: challenge.startsAt,
        endsAt: challenge.endsAt
    } )
    const dispatch = useDispatch()

    useEffect( () => {

        dispatch( setChallenge( { startsAt, endsAt } ) )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [  startsAt, endsAt  ] )

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignContent="center"
            alignItems="center"
            className={'mt-1'}
        >
            <Grid container direction="column">
                <A3DatePicker
                    name='startsAt'
                    openTo={'date'}
                    label={'Challenge Starts'}
                    disablePast
                    className={'mt-2'}
                    date={startsAt}
                    dateChange={handleInputChange}
                />
                <A3DatePicker
                    name='endsAt'
                    openTo={'date'}
                    disablePast
                    className={'mt-3'}
                    label={'Challenge Ends'}
                    date={endsAt}
                    dateChange={handleInputChange}
                />
            </Grid>
            <Grid container direction="row" justify="center" className={'mt-5 p-3'}>
                <Typography variant={'h6'} align="center">
                    Set short-term targets: breakdown the overall challenge in smaller daily tasks and goals
                </Typography>
            </Grid>
        </Grid>
    )
}
