import { createStyles, makeStyles } from '@material-ui/core';


export const useStyles = ( props = {} ) => {
    //const matches = useMediaQuery('(min-width:450px)');
    const styles = makeStyles( ( theme: any ) =>
        createStyles( {
            appBarBottom: {
                height: '80px',
                top: 'auto',
                bottom: 0,
            },
            button: {
                color: theme.palette.secondary.light,
                '&.active': {
                    color: theme.palette.secondary[ 'contrastText' ],
                }
            },
            spacer: {
                width: '100%',
                height: '90px',
            }
        } )
    );
    return styles();
};
