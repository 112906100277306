import { createStyles, makeStyles, Theme } from '@material-ui/core';


export const useStyles = ( props = {} ) => {
    const styles = makeStyles( ( theme: Theme ) =>
        createStyles( {
            appbar: {
                paddingTop: theme.spacing( 1 ),
                paddingBottom: theme.spacing( 2 ),
                backgroundColor: '#262626',
                boxShadow: 'none',
            },
            iconContainer: {
                zIndex: 100,
            },
            icon: {
                zIndex: 100,
                color: theme.palette.secondary.contrastText
            },
            title: {
                position: 'absolute',
                width: '100%',
                textAlign: 'center',
                zIndex: 10

            },
            separator: {

                height: '55px'

            },
        } )
    );
    return styles();
};
