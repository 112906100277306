import { Challenge } from "./challenge.model";
import { User } from "./user.model";

export class Post {

    public id: string = '';
    public challenge: Partial<Challenge> = { id: '', name: '', startsAt: new Date().getTime(), type: 'Public' };
    public user: Partial<User> = { firstName: '', lastName: '', username: '', email: '' };
    public message: string = '';
    public createdAt: Date = new Date();
    public mediaFile: string = '';
    public mediaFileURL: string = '';
    public likes: number = 0;
    public likesUsers: string[] = [];
    public hearts: number = 0;
    public heartsUsers: string[] = [];
    public fists: number = 0;
    public fistsUsers: string[] = [];
    constructor( obj?: Partial<Post> ) {
        this.id = obj?.id || this.id
        this.challenge = obj?.challenge || this.challenge
        this.message = obj?.message || this.message
        this.createdAt = obj?.createdAt || this.createdAt
        if ( typeof this.createdAt === 'string' ) this.createdAt = new Date( this.createdAt )
        this.mediaFile = obj?.mediaFile || this.mediaFile
        this.mediaFileURL = obj?.mediaFileURL || this.mediaFileURL
        this.user = obj?.user || this.user
        this.likes = obj?.likes || this.likes
        this.likesUsers = obj?.likesUsers || this.likesUsers
        this.hearts = obj?.hearts || this.hearts
        this.heartsUsers = obj?.heartsUsers || this.heartsUsers
        this.fists = obj?.fists || this.fists
        this.fistsUsers = obj?.fistsUsers || this.fistsUsers
    }

    toPlainObject(): any {
        const plainObj: any = JSON.parse( JSON.stringify( this ) );
        return plainObj;
    }

    static createEmpty(): Post {
        return new Post().toPlainObject()
    }
}