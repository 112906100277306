import {  makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( ( theme: any ) => ( {
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        transform: 'translateZ(0px)',
        flexGrow: 1,

    },
    speedDial: {
        zIndex: 1101,
        position: 'fixed',
        bottom: theme.spacing( 15 ),
        right: theme.spacing( 2 ),
        '& .MuiSpeedDialAction-staticTooltipLabel': {
            width: 'max-content',

        }
    },
    action: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary[ 'contrastText' ],
        '& span': {
            width: 'max-content !important',

        }
    },
    backdrop: {
        zIndex: 1100,
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',

    },
} ) );