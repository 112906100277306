import { Grid, TextField } from "@material-ui/core";
import moment from "moment";
import { useLayoutEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { A3AppBar } from "../../components/A3AppBar/A3AppBar";
import { A3Button } from "../../components/A3Button/A3Button";
import { A3Select } from "../../components/A3Select/A3Select";
import { A3DatePicker } from "../../components/DatePicker/DatePicker";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { useAppSelector } from "../../redux/store/store";
import { BiodataEntry, Biodata } from "../../models/biodataEntry.model";
import { BiodataApi } from "../../apis/biodataApi";
import { routes } from "../../routers/routes";

export const EntryBiodata = () => {
    const history = useHistory();
    const { state: routeState }: { state: { challangeId?: string } } =
        useLocation();

    const currentEntry = useAppSelector((s) => s.biodata.entryInput);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState("");
    const [value2, setValue2] = useState("");
    const [date, setDate] = useState<Date>(new Date());
    const [unit, setUnit] = useState(
        currentEntry?.units?.length ? currentEntry?.units[0] : ""
    );

    const handleChange = (e: any) => {
        setValue(e.target.value);
    };
    const handleChange2 = (e: any) => {
        setValue2(e.target.value);
    };
    const handleUnitChange = (e: any) => {
        setUnit(e.target.value);
    };
    const saveEntry = async (e: any) => {
        setIsLoading(true);
        try {
            const entry: Biodata = {
                value1: value,
                units: unit,
            };

            if (currentEntry?.prop2) entry.value2 = value2;

            const entryToBeSaved: BiodataEntry = {
                biodata: { ...entry },
                type: currentEntry.biodataType,
            };
            if (routeState?.challangeId) {
                entryToBeSaved.refId = routeState?.challangeId;
                entryToBeSaved.refType = "challenge";
            }
            await BiodataApi.saveBiodata(entryToBeSaved);

            history.goBack();
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    const handleDateChange = (data: any) => {
        const date = moment(data.target.value).toDate();
        setDate(date);
    };

    useLayoutEffect(() => {
        if (!currentEntry.label)
            history.replace(routes.NEW_BIODATA.CHOOSE_TYPE);
    }, [currentEntry.label, history]);

    return (
        <>
            <NavigationBar title="Enter Data" showBackButton />
            {currentEntry.label !== "Height" && (
                <A3DatePicker
                    date={date}
                    disableFuture
                    dateChange={handleDateChange}
                    name="date"
                    label="Date"
                    views={["date"]}
                />
            )}
            <Grid
                container
                direction="row"
                justify="flex-start"
                className="mb-2"
            >
                <TextField
                    type="number"
                    fullWidth
                    name={currentEntry.prop}
                    value={value}
                    label={currentEntry.label}
                    onChange={handleChange}
                    onBlur={handleChange}
                    placeholder={currentEntry.label}
                    variant="outlined"
                    className="mt-2"
                    tabIndex={3}
                />
                {currentEntry.prop2 && (
                    <TextField
                        type="number"
                        fullWidth
                        name={currentEntry.prop2}
                        value={value2}
                        label={currentEntry.label2}
                        onChange={handleChange2}
                        onBlur={handleChange2}
                        placeholder={currentEntry.label2}
                        variant="outlined"
                        className="mt-2"
                        tabIndex={3}
                    />
                )}
                {currentEntry?.units && (
                    <A3Select
                        name="unit"
                        fullWidth
                        label={"Unit"}
                        classes="mt-3"
                        objectArray={currentEntry.units}
                        defaultSelectedObject={unit}
                        handleChange={handleUnitChange}
                    />
                )}
            </Grid>
            <A3AppBar>
                <>
                    <A3Button
                        fullWidth
                        isLoading={isLoading}
                        onClick={saveEntry}
                        className={"secondary-button mb-5"}
                    >
                        Save
                    </A3Button>
                </>
            </A3AppBar>
        </>
    );
};
