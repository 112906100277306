import React from 'react'
import { useStyles } from './StepDots.style'
import clsx from 'clsx';

interface props { amount: number, current: number }
export const StepDots = ( { amount, current }: props ) => {

    const classes = useStyles()

    return (

        <>
            {( Array( amount ) ).fill( 0 ).map( ( x, i: number ) => {
                return (
                    <div key={i} className={clsx( classes.dot, i === current && classes.activeDot )}>
                    </div>
                )
            } )}
        </>
    )
}
