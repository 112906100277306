import React from "react";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { Challenge } from "../../../models/challenge.model";
import { RootState } from "../../../redux/store/store";
import { SelectUserCards } from "../../../components/SelectUserCards/SelectUserCards";
import { useEffect } from "react";
import { useState } from "react";
import { User } from "../../../models/user.model";
import { LoadingWrapper } from "../../../components/LoadingWrapper/LoadingWrapper";
import { Notification } from "../../../models/notification.model";
import { NotificationType } from "../../../models/types";
import { modal } from "../../../components/modal/modal";
import { A3Button } from "../../../components/A3Button/A3Button";
import { useHistory } from "react-router-dom";
import { A3AppBar } from "../../../components/A3AppBar/A3AppBar";
import { pageTransitions, pageVariants } from "../../../animations/transitions";
import { motion } from "framer-motion";
import { UsersApi } from "../../../apis/userApi";
import { NotificationsApi } from "../../../apis/notificationsApi";
import { ChallengesApi } from "../../../apis/challengesApi";


export const MyFriendsScreen = () => {
    const challenge: Challenge = useSelector(
        (state: RootState) => new Challenge(state.challenge.challenge)
    );
    const currenUser: User = useSelector(
        (state: RootState) => new User(state.auth.user)
    );
    const history = useHistory();
    const [friends, setFriends] = useState<User[]>([]);
    const [selectedFriends, setSelectedFriends] = useState<
        { user: User; selected: boolean }[]
    >([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [loadingInvitations, setLoadingInvitations] =
        useState<boolean>(false);

    const handleAddFriends = async () => {
        const username = await modal('modal-add-friend', '', 'Add friend') as string
        if (!username || username === 'cancel') return
        if (username.split('')[0] !== '@') { return modal('alert', '', 'Usernames start with @') }

        setLoading(true)
        const friend = await UsersApi.getUserByUsername(username)
        if (!friend?.id) {
            setLoading(false)
            return modal('alert', '', `Can't find any ${username}.`)
        }
        await UsersApi.followUser(currenUser.id, friend.id)
        await fetchFriends()
       
    };
    const handleSendInvitations = async () => {
        // prevent inviting when challenge already started
        if (challenge.getHasStarted()) {
            const answer = await modal(
                "question",
                "",
                `This challenge has already started. The new participants can still join in, however the duration of their challenge will be shorter.`
            );
            if (answer !== "ok") return;
        }
        if (selectedFriends.length === 0) return;
        setLoadingInvitations(true);
        setLoading(true);
        await Promise.all(
            selectedFriends.map((f) => {
                return (async () => {
                    await NotificationsApi.createNotification(
                        new Notification({
                            to: f.user.getPublicData(),
                            type: NotificationType.CHALLENGE_INVITATION,
                            data: {
                                challengeId: challenge.id,
                                challengeName: challenge.name,
                            },
                        })
                    );
                    await ChallengesApi.addParticipantToChallenge(
                        challenge.id,
                        f.user.id
                    );
                })();
            })
        );
        setLoading(false);
        setLoadingInvitations(false);

        history.replace("/challenge/" + challenge.id);
    };
    const changeSelectedFriends = (
        firends: { user: User; selected: boolean }[]
    ) => {
        setSelectedFriends(firends.filter((sf) => sf.selected));
    };
    const fetchFriends = async () => {
        const friendsDB: User[] = await UsersApi.getUserFriends(currenUser.id);
        setFriends(friendsDB);
        setLoading(false);
    };

    useEffect(() => {
        fetchFriends();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <motion.div
            className={"pepe"}
            initial="initial"
            exit="out"
            animate="in"
            transition={pageTransitions}
            variants={pageVariants}
        >
            <NavigationBar title={"My Friends"} />
            <LoadingWrapper isLoading={isLoading}>
                <>
                    {friends.length > 0 ? (
                        <SelectUserCards
                            challenge={challenge}
                            users={friends}
                            handleChange={changeSelectedFriends}
                            sendToProfileOnClick={!challenge?.id}
                        />
                    ) : (
                        <Typography
                            variant={"body1"}
                            align="center"
                            className={"mt-3"}
                        >
                            No one in here, try to join a challenge to meet
                            people...
                        </Typography>
                    )}
                </>
            </LoadingWrapper>

            <A3AppBar className="mb-5">
                <>
                    {challenge?.id && friends.length > 0 && (
                        <A3Button
                            isLoading={!!loadingInvitations}
                            disabled={selectedFriends.length === 0}
                            fullWidth
                            className="main-button mt-1 mb-2"
                            onClick={handleSendInvitations}
                        >
                            {selectedFriends.length === 0
                                ? "Select some friends"
                                : "Invite to Challenge"}
                        </A3Button>
                    )}
                    <A3Button
                        isLoading={!!loadingInvitations}
                        fullWidth
                        className={"secondary-button mb-5"}
                        onClick={handleAddFriends}
                    >
                        Add a Friend
                    </A3Button>
                </>
            </A3AppBar>
        </motion.div>
    );
};
