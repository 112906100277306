import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ChallengesApi } from '../../apis/challengesApi'
import { Challenge } from '../../models/challenge.model'
import { RootState } from '../../redux/store/store'
import { ImageCardFullWidth } from '../ImageCardFullWidth/ImageCardFullWidth'
import { LoadingWrapper } from '../LoadingWrapper/LoadingWrapper'


interface Props {
    className: string
    type: 'public' | 'joined' | 'invited' | 'cooperative'
}
export const ChallengeCardList = ( { type, className }: Props ) => {

    const currentUser = useSelector( ( state: RootState ) => state.auth.user )
    const history = useHistory()

    const [ challenges, setChallenges ] = useState<Challenge[]>( [] )
    const [isLoading, setIsLoading] = useState(false)

    const fetchChallenges = async () => {
        setIsLoading(true)
        let tempChallenges = await ChallengesApi.getChallengesList( currentUser.id, type )
        if(type === 'public') {
            const joinedChallenges = await ChallengesApi.getChallengesList( currentUser.id, 'joined' )
            tempChallenges = tempChallenges.filter( (challenge:Challenge) => {
                for (let i = 0; i < joinedChallenges.length; i++) {
                    const joinedChallenge = joinedChallenges[i];
                    if(joinedChallenge.id === challenge.id) return false
                }
                return true
            })
        }
        setChallenges( tempChallenges )
        setIsLoading(false)
    }
    const handleGoToChallenge = ( challengeId: string ) => {
        history.push( '/challenge/' + challengeId )
    }
    
    useEffect( () => {

        fetchChallenges()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )

    return (
        <Grid container direction="column" className={className}>
            <LoadingWrapper isLoading={isLoading} >
                <>
                    {
                        !!challenges.length && challenges.map( ( c: Challenge, i: number ) => {
                            return <Box width='100%' key={i} mt={2} ><ImageCardFullWidth challenge={c} onclick={handleGoToChallenge} /></Box>
                        } )
                    }
                    {
                        (!challenges.length) && <Typography variant="body1" align="center" className={'mt-3 mb-1'} style={{'color':'#afafaf'}}>
                            ... No challenges here ...
                        </Typography>
                    }
                </>
            </LoadingWrapper>

        </Grid>
    )
}
