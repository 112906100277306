import { Goal } from "../models/goals.model";
import { Reward } from "../models/rewards.model";
import { A3Server } from "../utils/fetch";


export class RewardsApi {

    static getRewards = async (userId: string): Promise<Reward[]> => {
        try {
            const a3Server = new A3Server()
            const rewards = await a3Server.get<Reward[]>(`v1/users/${userId}/rewards`)
            return rewards

        } catch (error) {
            throw error
        }

    }
    static getGoals = async () => {
        try {
            const a3Server = new A3Server()
            const goals = await a3Server.get<Goal[]>(`v1/goals`)
            return goals

        } catch (error) {
            throw error
        }

    }



}