import { Challenge } from "../models/challenge.model";
import { ChallengeActivity } from "../models/challengeActivity.model";
import { ChallengeCategory } from "../models/challengeCategory.model";
import { Contribution, Contributor } from "../models/contributor";
import {
    CategoryIntervals,
    ChallengeCategoryTypes,
} from "../models/types";
import { A3Server } from "../utils/fetch";

export class ChallengesApi {
    static addContribution = async (
        challengeId: string,
        contribution: Contribution
    ) => {
        if (!challengeId) {
            console.error("No challengeId...", challengeId);
            throw new Error("No challengeId...");
        }

        try {

            const a3Server = new A3Server();

            await a3Server.put<void>(
                `v1/challenges/${challengeId}/contributors`,
                { contribution }
            );

        } catch (e) {
            console.log(e);
            throw e;
        }
    };
    static removeContribution = async (
        challengeId: string,
        contributorId: string,
        contribution: Contribution
    ) => {
        if (!challengeId) {
            console.error("No challengeId...", challengeId);
            throw new Error("No challengeId...");
        }

        try {

            const a3Server = new A3Server();

            await a3Server.delete<void>(
                `v1/challenges/${challengeId}/contributors/${contributorId}`,
                { contribution }
            );

        } catch (e) {
            console.log(e);
            throw e;
        }
    };
    static addParticipantToChallenge = async (
        challengeId: string,
        participantId: string,
        directJoin: boolean = false
    ): Promise<Challenge> => {
        if (!challengeId) {
            console.error("No challengeId...", challengeId);
            throw new Error("No challengeId...");
        }
        if (!participantId) {
            console.error("No participantId...", participantId);
            throw new Error("No participantId...");
        }

        try {
            const a3Server = new A3Server();

            const challenge = await a3Server.post<Challenge>(
                `v1/challenges/${challengeId}/participants`,
                { participantId, directJoin }
            );

            return challenge

        } catch (e) {
            console.log(e);
            throw e;
        }
    };
    static removeParticipantFromChallenge = async (
        challengeId: string,
        userId: string,
    ): Promise<Challenge> => {
        if (!challengeId) {
            console.error("No challenge...", challengeId);
            throw new Error("No challenge...");
        }
        if (!userId) {
            console.error("No user...", userId);
            throw new Error("No user...");
        }

        const a3Server = new A3Server();

        const newChallenge = await a3Server.delete<Challenge>(
            `v1/challenges/${challengeId}/participants/${userId}`
        );

        return newChallenge;

    };
    static updateChallenge = async (
        challenge: Partial<Challenge>
    ): Promise<Challenge> => {
        if (!challenge || !challenge.id) {
            console.error("No challenge or no challenge ID...", challenge);
            throw new Error("No challenge...");
        }

        const a3Server = new A3Server();

        const newChallenge = await a3Server.put<Challenge>(`v1/challenges`, challenge);

        return newChallenge;

    };
    static deleteChallenge = async (challengeId: string): Promise<void> => {
        if (!challengeId) {
            console.error("No challenge id...", challengeId);
            throw new Error("No challenge...");
        }

        const a3Server = new A3Server();

        await a3Server.delete<Challenge>(`v1/challenges/${challengeId}`);
    };
    static createChallenge = async (
        challenge: Partial<Challenge>
    ): Promise<Challenge> => {
        if (!challenge) {
            console.error("No challenge...", challenge);
            throw new Error("No challenge...");
        }

        try {
            const a3Server = new A3Server();

            const newChallenge = await a3Server.post<Challenge>(
                `v1/challenges`,
                challenge
            );

            return newChallenge;
        } catch (e) {
            console.log(e);
            throw e;
        }
    };
    static getChallenge = async (challengeId: string): Promise<Challenge> => {
        if (!challengeId || challengeId.length < 10) {
            throw new Error("To get challenges you must pass the challengeId");
        }

        try {
            const a3Server = new A3Server();

            const challenge = await a3Server.get<Challenge>(
                `v1/challenges/${challengeId}`
            );

            if (!challenge)
                return new Challenge({ name: "Challenge Not Found" });

            return new Challenge(challenge);
        } catch (e) {
            console.log(e);
            throw e;
        }
    };
    static getChallenges = async (userId: string): Promise<Challenge[]> => {
        if (!userId) {
            throw new Error("To get challenges you must pass the userId");
        }

        const a3Server = new A3Server();

        const challenges = await a3Server.get<Challenge[]>(
            `v1/challenges/?userId=${userId}`
        );

        return challenges.map((c) => new Challenge(c));
    };
    static getChallengesList = async (
        userId: string,
        type?: "ongoing" | "public" | "joined" | "invited" | 'cooperative'
    ): Promise<Challenge[]> => {
        if (!userId) {
            throw new Error("To get challenges you must pass the userId");
        }

        try {
            const a3Server = new A3Server();

            const challenges = await a3Server.get<Challenge[]>(
                `v1/challenges-list/?userId=${userId}&type=${type}`
            );

            return challenges.map((c) => new Challenge(c));
        } catch (e) {
            console.log(e);
            throw e;
        }
    };
    static getContributor = async (
        challengeId: string,
        contributorId: string
    ): Promise<Contributor> => {
        if (!challengeId) {
            throw new Error("To get contributions you must pass the challengeId");
        }
        if (!contributorId) {
            throw new Error("To get contributions you must pass the contributorId (userId)");
        }

        try {

            const a3Server = new A3Server();

            const contributor = await a3Server.get<Contributor>(
                `v1/challenges/${challengeId}/contributors/${contributorId}`
            );

            return contributor

        } catch (e) {
            console.log(e);
            throw e;
        }
    };



    static getChallengesCatregories = (): ChallengeCategory[] => {
        return [
            new ChallengeCategory("fitness.jpg", "fitness", "Fitness"),
            new ChallengeCategory("diet.svg", "diet", "Diet"),
            new ChallengeCategory("if.png", "fasting", "Intermittent Fasting"),
            new ChallengeCategory("yoga.png", "yoga", "Yoga"),
            new ChallengeCategory("meditate.png", "meditation", "Meditation"),
            new ChallengeCategory("reading.png", "reading", "Reading"),
            new ChallengeCategory(
                "stop-smoking.jpg",
                "stop-smoking",
                "Stop Smoking"
            ),
            new ChallengeCategory(
                "stop-drinking.svg",
                "stop-drinking",
                "Stop Drinking"
            ),
        ];
    };
    static getActivity = (categoryId: string, activityId: string) => {
        const activities = ChallengesApi.getChallengesCategoriesActivities(categoryId as ChallengeCategoryTypes)
        return activities.filter(a => a.id === activityId)[0]
    }
    static getChallengesCategoriesActivities = (
        categoryId: ChallengeCategoryTypes
    ) => {
        switch (categoryId) {
            case "fitness":
                return [
                    new ChallengeActivity(
                        "general-workout.svg",
                        "general-workout",
                        "General Workout",
                        CategoryIntervals,
                        ["times"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "pushups.png",
                        "pushups",
                        "Pushups",
                        CategoryIntervals,
                        ["reps"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "running.svg",
                        "running",
                        "Running",
                        CategoryIntervals,
                        ["kms", "miles"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "pullups.svg",
                        "pullups",
                        "Pullups",
                        CategoryIntervals,
                        ["reps"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "squats.jpg",
                        "squats",
                        "Squats",
                        CategoryIntervals,
                        ["reps"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "abs.svg",
                        "abs",
                        "Abs",
                        CategoryIntervals,
                        ["reps"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "bike.svg",
                        "bike",
                        "Bike",
                        CategoryIntervals,
                        ["kms", "miles"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "swim.jpg",
                        "swim",
                        "Swim",
                        CategoryIntervals,
                        ["laps", "kms", "miles"],
                        categoryId
                    ),
                ];
            case "yoga":
                return [
                    new ChallengeActivity(
                        "yoga.png",
                        "yoga",
                        "Yoga",
                        CategoryIntervals,
                        ["sessions"],
                        categoryId
                    ),
                ];
            case "fasting":
                return [
                    new ChallengeActivity(
                        "if.png",
                        "24-0",
                        "24/0",
                        ["daily"],
                        ["hours"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "if.png",
                        "20-4",
                        "20/4",
                        ["daily"],
                        ["hours"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "if.png",
                        "16-8",
                        "16/8",
                        ["daily"],
                        ["hours"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "if.png",
                        "14-10",
                        "14/10",
                        ["daily"],
                        ["hours"],
                        categoryId
                    ),
                ];
            case "diet":
                return [
                    new ChallengeActivity(
                        "vegan.png",
                        "vegan",
                        "Vegan",
                        ["daily"],
                        ["none"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "vegetarian.png",
                        "vegetarian",
                        "Vegetarian",
                        ["daily"],
                        ["none"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "keto.jpg",
                        "keto",
                        "Keto",
                        ["daily"],
                        ["none"],
                        categoryId
                    ),
                    new ChallengeActivity(
                        "carnivor.svg",
                        "carnivor",
                        "Carnivor",
                        ["daily"],
                        ["none"],
                        categoryId
                    ),
                ];
            case "reading":
                return [
                    new ChallengeActivity(
                        "reading.png",
                        "reading",
                        "Reading",
                        ["daily", "monthly"],
                        ["pages", "minutes", "books"],
                        categoryId
                    ),
                ];
            case "stop-drinking":
                return [
                    new ChallengeActivity(
                        "stop-drinking.svg",
                        "stop-drinking",
                        "Stop Drinking",
                        ["daily"],
                        ["none"],
                        categoryId
                    ),
                ];
            case "stop-smoking":
                return [
                    new ChallengeActivity(
                        "stop-smoking.jpg",
                        "stop-smoking",
                        "Stop Smoking",
                        ["daily"],
                        ["none"],
                        categoryId
                    ),
                ];
            case "meditation":
                return [
                    new ChallengeActivity(
                        "meditate.png",
                        "meditate",
                        "Meditate",
                        CategoryIntervals,
                        ["Minutes"],
                        categoryId
                    ),
                ];
            default:
                return [];
        }
    };
}
