import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification } from './../../../models/notification.model';


const initialState: { notifications: Notification[] } = {
    notifications: []
};

//Reducer
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotifications(state, action: PayloadAction<Notification[]>) {

            state.notifications = action.payload || []
        },
    }
})


export const {
    addNotifications,
} = notificationsSlice.actions

export default notificationsSlice.reducer