import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { NavigationBar } from '../../../components/NavigationBar/NavigationBar'
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar'
import { Post } from '../../../models/post.model'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { PostActions } from '../../../components/PostActions/PostActions'
import { PostCommentsComponent } from '../../../components/PostCommentsComponent/PostCommentsComponent'
import { PostMedia } from '../../../components/PostMedia/PostMedia'
import { LoadingScreen } from '../../LoadingScreen/LoadingScreen'
import { useUtils } from '../../../utils/useUtils'
import { PostApi } from '../../../apis/postApi'

export const ViewPostScreen = () => {

    const history = useHistory()
    const { cutLongText } = useUtils()
    const { id: postId } = useParams<any>();

    const [post, setPost] = useState<Post>(new Post())

    const fetchPost = async () => {
        const p = await PostApi.getPost(postId)
        console.log('got post: ', p);
        setPost(p)
    }
    useEffect(() => {

        if (!postId) {
            history.replace('/my-community')
            return
        }
        fetchPost()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goToChallenge = () => {
        history.push(`/challenge/${post.challenge.id}`)
    }

    if (!post.id) {
        return <LoadingScreen />
    }

    return (
        <>
            <NavigationBar title={'Post'} />

            <Grid container direction="row" className={'mt-2'}>
                <Grid item>
                    <UserAvatar user={post.user} />


                </Grid>
                <Grid item className={'ml-1'}>
                    <Grid container direction="column">
                        <Typography variant={'body2'}>{post?.user?.firstName} {post?.user?.lastName}</Typography>
                        <Typography variant={'overline'}>@{post?.user?.username} - {moment(post?.createdAt).format('DD MMM yyyy HH:mm')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" className={'mt-2'}>
                <Typography variant={'subtitle2'}>
                    {post?.message}
                </Typography>
            </Grid>
            <Grid container direction="row" className={'mt-2'}>
                {post.mediaFileURL && <PostMedia post={post} />}
            </Grid>
            <Grid container direction="row" className={'mt-2'}>
                <PostActions post={post} />
            </Grid>
            <Grid item container justify={'flex-end'}>
                <Typography variant={'subtitle2'} className={'mt-3'} onClick={goToChallenge} >
                    From: {cutLongText(post.challenge.name, 40)}
                </Typography>
            </Grid>
            <Grid container direction="row" className={'mt-0'}>
                <PostCommentsComponent post={post} />
            </Grid>


        </>
    )
}
