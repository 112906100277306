import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(  {
    root:{

    },
    logo: {
        height: '109px',
        
    }, 
    createAccount: {
        padding: '8px',
        position: 'absolute',
        left: '0px',
        bottom: '0px',
        width: '100vw',
        minWidth: '100vw',

    }
} );
