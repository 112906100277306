import { NotificationStatusType, NotificationType } from "./types";
import { User } from "./user.model";

export class Notification {

    public id: string = '';
    public from: User = new User();
    public to: User = new User();
    public createdAt: Date = new Date();
    public type: NotificationType = NotificationType.CHALLENGE_INVITATION
    public status: NotificationStatusType = NotificationStatusType.NOT_READ;
    public data: any = {};
    
    constructor( obj?: Partial<Notification> ) {
        this.id = obj?.id || this.id
        this.from = obj?.from || this.from
        this.to = obj?.to || this.to
        this.createdAt = obj?.createdAt || this.createdAt
        this.type = obj?.type || this.type
        this.status = obj?.status || this.status
        this.data = obj?.data || this.data
    }

    getInvitationText(){
        switch (this.type) {
            case NotificationType.CHALLENGE_INVITATION:
                return `@${this.from.username} is inviting you to "${this.data.challengeName || ''}"`
        
            default:
                return 'This is a Notification'
        }
    }
    toPlainObject(): any {
        const plainObj: any = JSON.parse( JSON.stringify( this ) );
        return plainObj;
    }


}