import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { Challenge } from "../../models/challenge.model";
import { useStyles } from "./ImageCardFullWidth.style";
import { useHistory } from "react-router-dom";
import {ReactComponent as MidDifficultyIcon} from '../../assets/difficulty-mid.svg'
import {ReactComponent as HighDifficultyIcon} from '../../assets/difficulty-high.svg'
import {ReactComponent as LowDifficultyIcon} from '../../assets/difficulty-low.svg'
import { useUtils } from "../../utils/useUtils";

interface Props {
    challenge: Challenge;
    onclick?: Function;
}

export const ImageCardFullWidth = ({ challenge, onclick }: Props) => {
    const { cutLongText  } = useUtils()
    const classes = useStyles(challenge.challengeActivity.image)();
    const history = useHistory();
    const handleClick = () => {
        onclick && onclick(challenge.id);
    };
    const goToCreatorProfile = () => {
        history.push("/profile/" + challenge.creatorId);
    };

    return (
        <div className={classes.root}>
            <Grid container direction="row" justify="flex-start">
                <Grid item onClick={handleClick}>
                    <div className={classes.image}></div>
                </Grid>
                <Grid item xs={8} container direction="column">
                    <Grid item container justify="space-between">
                        <Typography
                            variant={"h4"}
                            className={"ml-1 mt-1"}
                            onClick={handleClick}
                        >
                            {cutLongText(challenge.name,20)}
                        </Typography>
                        
                        <Typography
                            variant={"subtitle1"}
                            className={"ml-1 mt-1"}
                        >
                            {challenge.difficulty && `Difficulty`}
                            {challenge.difficulty === 'Entry' && 
                            <LowDifficultyIcon fill="#d4d4d4"/>
                            }
                            {challenge.difficulty === 'Intermediate' && 
                            <MidDifficultyIcon fill="#d4d4d4"/>
                            }
                            {challenge.difficulty === 'Challenging' && 
                            <HighDifficultyIcon fill="#d4d4d4"/>
                            }
                        </Typography>
                    </Grid>
                    <Typography
                        variant={"subtitle1"}
                        className={"ml-1 mt-1"}
                        onClick={handleClick}
                    >
                        {challenge.getChallengeType()}
                    </Typography>

                    <Grid container justify="space-between">
                        <Typography
                            variant={"subtitle1"}
                            className={"ml-1 mt-1"}
                            onClick={handleClick}
                        >
                            {challenge.getEndStartRelativeTime(true)}
                        </Typography>
                        {!challenge.isCooperative ? (
                            <Typography
                                align="right"
                                variant={"subtitle1"}
                                className={"ml-1 mt-1"}
                                onClick={goToCreatorProfile}
                            >
                                by @{challenge.getCreator().username || ""}
                            </Typography>
                        ) : (
                            <Typography
                                align="right"
                                variant={"subtitle1"}
                                className={"ml-1 mt-1"}
                            >
                                Team Challenge
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
