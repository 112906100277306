import React, { useState } from 'react'
import { Avatar } from '@material-ui/core'
import { User } from '../../models/user.model'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface UserAvatarProps {
    onClick?: Function,
    className?: string,
    user: Partial<User>
}

export const UserAvatar = ( {
    onClick,
    className,
    user

}: UserAvatarProps ) => {

    const [ img, setImg ] = useState<string>( '' )
    const [ currentUser, SetCurrentUser ] = useState<Partial<User>>( new User() )
    const history = useHistory()

    const handleFetchImageUrl = async () => {

        let avatarUser: Partial<User> = new User()
        let imgUrl: string = ''

        if ( user?.id ) {
            avatarUser = user
            imgUrl = user.imgUrl
        }


        setImg( imgUrl )
        SetCurrentUser( avatarUser )
    }

    const handleClick = () => {
        if ( onClick ) {
            onClick()
        } else {
            if(window.location.pathname.indexOf('/profile/' + user.id) === -1 ) history.push( '/profile/' + user.id )
        }
    }

    useEffect( () => {
        handleFetchImageUrl()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )


    return (
        <Avatar onClick={handleClick} className={className || ''} alt={currentUser.firstName + " Profile Picture"} src={img} />
    )
}

