import { BiodataEntry, BiodataQueryType } from "../models/biodataEntry.model";
import { A3Server } from "../utils/fetch";


export class BiodataApi {

    static saveBiodata = async (biodata: BiodataEntry) => {
        try {
            const a3Server = new A3Server()
            await a3Server.post<void>(`v1/biodata`, biodata)


        } catch (error) {
            throw error
        }

    }
    static get = async (query: BiodataQueryType) => {
        try {
            const a3Server = new A3Server()
            let urlQuery = '?'
            Object.keys(query).forEach((k: string)=>{
                urlQuery += `${k}=${query[k as keyof BiodataQueryType]}`
            })
            const goals = await a3Server.get<BiodataEntry[]>(`v1/biodata${urlQuery}`)
            return goals

        } catch (error) {
            throw error
        }

    }
    static remove = async (biodataId: string) => {
        try {
            const a3Server = new A3Server()
            const goals = await a3Server.delete<BiodataEntry[]>(`v1/biodata/${biodataId}`)
            return goals

        } catch (error) {
            throw error
        }

    }



}