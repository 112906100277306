import React, { useEffect, useRef } from "react";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { Button, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";
import { User } from "../../models/user.model";
import { useHistory, useParams } from "react-router-dom";
import { ImageCardFullWidth } from "../../components/ImageCardFullWidth/ImageCardFullWidth";
import { Challenge } from "../../models/challenge.model";
import { useState } from "react";
import {
    setChallenge,
    clearChallenge,
} from "../../redux/slices/challenges/challenges.slice";
import { LoadingWrapper } from "../../components/LoadingWrapper/LoadingWrapper";
import { ProfileCard } from "./components/ProfileCard";
import { UserCard } from "../../components/UserCard/UserCard";
import { LoadingScreen } from "../LoadingScreen/LoadingScreen";
import { modal } from "../../components/modal/modal";
import { FloatingCloseButton } from "../../components/FloatingCloseButton/FloatingCloseButton";
import { routes } from "../../routers/routes";
import { UsersApi } from "../../apis/userApi";
import { AuthUpdateProfilePicture } from "../../redux/slices/auth/auth.slice";
import { ChallengesApi } from "../../apis/challengesApi";

export const ProfileScreen = () => {
    const history = useHistory();
    const currentUser: User = useSelector(
        (state: RootState) => state.auth.user
    );
    const dispatch = useDispatch();
    const params: { id?: string } = useParams();

    const [challenges, setChallenges] = useState<Challenge[]>([]);
    const [isEditable, setIsEditable] = useState(false);
    const [isfollowing, setIsfollowing] = useState(false);
    const [disablePushSubscription, setDisablePushSubscription] =
        useState(false);

    const [user, setUser] = useState<User>(currentUser);
    const [loading, setLoading] = useState(true);

    const fileRef = useRef<any>();

    const fileChangeHandler = async (event: any) => {
        if (event?.target?.files?.length) {
            const imgUrl = await UsersApi.uploadUserMedia(
                event.target.files[0],
                currentUser.id
            );
            dispatch(AuthUpdateProfilePicture(imgUrl));
        }
    };

    const handleChangeProfilePicture = async () => {
        fileRef.current.click();
        return;
    };

    const fetchUser = async () => {
        if (!params?.id) return;
        let userDB = await UsersApi.getUser(params?.id);
        setUser(userDB);

        const userFollowing = await UsersApi.isUserFollowing(
            currentUser.id,
            userDB.id
        );

        setIsfollowing(!!userFollowing);

        await fetchChallenges(userDB.id);
        setLoading(false);
    };
    const fetchChallenges = async (userId: string) => {
        const challengesDB = await ChallengesApi.getChallengesList(
            userId,
            "ongoing"
        );
        setChallenges(challengesDB);
        setLoading(false);
    };

    const goToNewChallenge = () => {
        dispatch(clearChallenge());
        history.push("/challenges/new/1");
    };

    const handleFollow = async () => {
        if (isfollowing) {
            await UsersApi.unfollowUser(currentUser.id, user.id);
            setIsfollowing(false);
        } else {
            await UsersApi.followUser(currentUser.id, user.id);
            setIsfollowing(true);
        }
    };
    const handleGoToChallenge = (challengeId: string) => {
        history.push("/challenge/" + challengeId);
    };

    const handleLogout = async () => {
        const answer = await modal(
            "question",
            "Logout",
            "You're about to close your current session, have in mind that to login again you need to have your credentials."
        );

        if (answer !== "ok") return;

        history.push("/lo");
    };

    const goToFriends = () => {
        history.push(routes.MAIN.FRIENDS);
    };
    const setPushNotifications = () => {
        if (!("showNotification" in ServiceWorkerRegistration.prototype)) {
            console.log("Notifications aren't supported.");
            return;
        }

        if (Notification.permission === "denied") {
            console.log("The user has blocked notifications.");
            return;
        }

        if (!("PushManager" in window)) {
            console.log("Push messaging isn't supported.");
            return;
        }

        navigator.serviceWorker.ready.then(function (
            serviceWorkerRegistration
        ) {
            // Do we already have a push message subscription?

            serviceWorkerRegistration.pushManager
                .getSubscription()
                .then(function (subscription) {
                    setDisablePushSubscription(true);

                    if (!subscription) {
                        setDisablePushSubscription(false);
                        return;
                    }

                    // TODO: Send sub to server
                    console.log(subscription);
                })
                .catch(function (err) {
                    console.log("Error during getSubscription()", err);
                });
        });
    };

    useEffect(() => {
        if (params?.id && currentUser.id !== params?.id) {
            fetchUser();
        } else {
            setIsEditable(true);
            fetchChallenges(currentUser.id);
        }
        dispatch(setChallenge(new Challenge()));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //TODO: Show only challenges that I have joined, if pending it shouldn;t be shown here

    if (loading) return <LoadingScreen />;

    if (isEditable) {
        // Own profile
        return (
            <>
                <FloatingCloseButton />

                <NavigationBar title={"Profile"} />
                <ProfileCard user={user} />
                <input
                    ref={fileRef}
                    type="file"
                    name="file"
                    onChange={fileChangeHandler}
                    accept="image/*"
                    hidden
                />
                <Button
                    fullWidth
                    className="secondary-button mt-4"
                    onClick={handleChangeProfilePicture}
                >
                    Change Profile Picture
                </Button>
                <Button
                    fullWidth
                    className="secondary-button mt-2"
                    onClick={goToFriends}
                >
                    Following
                </Button>
                <Button
                    fullWidth
                    className="secondary-button mt-2"
                    onClick={handleLogout}
                >
                    Logout
                </Button>
                <Button
                    fullWidth
                    className="secondary-button mt-2"
                    onClick={setPushNotifications}
                    disabled={disablePushSubscription}
                >
                    Enable Notifications
                </Button>
                <Grid container direction="column">
                    <Typography variant={"body1"} className={"mt-5 mb-2"}>
                        My Challenges
                    </Typography>
                </Grid>
                <Grid container direction="column" className="mb-9">
                    <LoadingWrapper isLoading={loading}>
                        <>
                            {challenges?.length ? (
                                challenges.map((c: Challenge, i: number) => {
                                    return (
                                        <ImageCardFullWidth
                                            key={i}
                                            challenge={c}
                                            onclick={handleGoToChallenge}
                                        />
                                    );
                                })
                            ) : (
                                <>
                                    <Typography
                                        variant={"h4"}
                                        className={"mt-2"}
                                        align="center"
                                    >
                                        You are not participating in any
                                        challenge, try to participate in one,
                                        it's fun!
                                    </Typography>
                                    <Button
                                        className="secondary-button mt-3"
                                        onClick={goToNewChallenge}
                                    >
                                        Create a new challenge
                                    </Button>
                                </>
                            )}
                        </>
                    </LoadingWrapper>
                </Grid>
            </>
        );
    } else {
        // Other Users
        return (
            <>
                <FloatingCloseButton />

                <NavigationBar title={"Profile"} />
                <UserCard user={user} actionVisible={false} />

                <Button
                    fullWidth
                    className="secondary-button mt-2"
                    onClick={handleFollow}
                >
                    {isfollowing ? "unfollow" : "Follow"}
                </Button>
                <Grid container direction="column">
                    <Typography variant={"body1"} className={"mt-5 mb-2"}>
                        Challenges
                    </Typography>
                </Grid>
                <Grid container direction="column">
                    <LoadingWrapper isLoading={loading}>
                        <>
                            {challenges?.length ? (
                                challenges.map((c: Challenge, i: number) => {
                                    return (
                                        <ImageCardFullWidth
                                            key={c.id + i}
                                            challenge={c}
                                            onclick={handleGoToChallenge}
                                        />
                                    );
                                })
                            ) : (
                                <>
                                    <Typography
                                        variant={"h4"}
                                        className={"mt-2"}
                                        align="center"
                                    >
                                        Looks like @{user.username} is not in
                                        any public challenges
                                    </Typography>
                                </>
                            )}
                        </>
                    </LoadingWrapper>
                </Grid>
            </>
        );
    }
};
