import { Card, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { NotificationsApi } from '../../apis/notificationsApi'
import { LoadingWrapper } from '../../components/LoadingWrapper/LoadingWrapper'
import { modal } from '../../components/modal/modal'
import { NavigationBar } from '../../components/NavigationBar/NavigationBar'
import { Notification } from '../../models/notification.model'
import { NotificationType } from '../../models/types'
import { RootState } from '../../redux/store/store'

export const NotificationsScreen = () => {
    const notifications = useSelector( ( state: RootState ) => state.notifications.notifications.map(n=>new Notification(n)) )

    const [isLoading, setIsLoading] = useState(false)

    const history = useHistory()
    const handleDelete = async ( notification: Notification ) => {
        let answer = await modal( 'question', 'Are you sure?', `If you delete this notification you won't be able to recover it.` )
        if ( answer !== 'ok' ) return;
        setIsLoading(true)
        await NotificationsApi.deleteNotification( notification )
        setIsLoading(false)

    }
    const seeDetails = ( notification: Notification ) => {

        switch ( notification.type ) {
            case NotificationType.CHALLENGE_INVITATION:
                history.push( '/challenge/' + notification.data.challengeId )
                break;
            default:
                throw new Error( 'Dont know whats going on with this one ... ' )
        }
    }

    return (
        <>
            <NavigationBar title={'Notifications'} />
            <LoadingWrapper isLoading={isLoading}>
                <>
                    {notifications && notifications.map( ( n: Notification, index: number ) => {
                        return (
                            <Card key={index} variant={'outlined'}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant="body2" >
                                            {n.getInvitationText()}
                                        </Typography>
                                    </Grid>
                                    <Grid item container direction="row" justify={'flex-end'} className={'mt-3'}>
                                        <Typography className={'ml-3'} variant="subtitle1" onClick={() => { handleDelete( n ) }}>
                                            Delete
                                        </Typography>
                                        <Typography className={'ml-3'} variant="subtitle1" onClick={() => { seeDetails( n ) }}>
                                            See Details
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        )
                    } )}
                </>
            </LoadingWrapper>


        </>
    )
}
