import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = () => {
    const styles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                backgroundColor: theme.palette.grey[900] ,
                borderRadius: theme.spacing(1),
                padding: theme.spacing(1),
                

            },
        })
    );
    return styles();
};
