import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import store from './redux/store/store';

ReactDOM.render(
  <>
    <Provider store={store}>
      <App />
      <div id="modal" />
    </Provider>
  </>
  ,
  document.getElementById( 'root' )
);

