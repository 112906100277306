import moment from "moment";
import { Challenge } from "./challenge.model";

export class Task {

    public id: string = '';
    public challengeId: string = '';
    public challenge: Partial<Challenge> = new Challenge()
    public date: Date = moment().add( 1, 'day' ).toDate();
    public unit: string = '';
    public done: boolean = false;
    public isProgressive: boolean = false;
    public excpectedQuantity: number = 0
    public actualQuantity: number = 0
    public timerId: string = '';
    

    constructor( obj?: Partial<Task> ) {
        this.id = obj?.id || this.id
        this.challengeId = obj?.challengeId || this.challengeId
        this.challenge = obj?.challenge || this.challenge
        this.date = obj?.date || this.date
        if ( typeof this.date === 'string' ) this.date = new Date( this.date )
        this.unit = obj?.unit || this.unit
        this.done = obj?.done || this.done
        this.isProgressive = obj?.isProgressive || this.isProgressive
        this.excpectedQuantity = obj?.excpectedQuantity || this.excpectedQuantity
        this.actualQuantity = obj?.actualQuantity || this.actualQuantity
        this.timerId = obj?.timerId || this.timerId
    }


}