import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { BaseSyntheticEvent } from "react";
import { useState } from "react";
import { useEffect } from "react";

interface A3SelectProps {
    fullWidth?: boolean;
    disabled?: boolean;
    name: string;
    label: string;
    classes: string;
    objectArray: any[];
    objectIdProp?: string;
    objectValueProp?: string;
    emptyLabel?: string;
    defaultSelectedObject: any;
    handleChange: Function;
}

export const A3Select = ({
    fullWidth = false,
    disabled = false,
    name,
    label,
    classes,
    objectArray,
    objectIdProp,
    objectValueProp,
    defaultSelectedObject,
    handleChange,
}: A3SelectProps) => {
    const [value, setValue] = useState(defaultSelectedObject);

    const handleInternalChange = (event: BaseSyntheticEvent<any>) => {
        if (!objectArray.length) return;

        let newSelected = null;
        if (objectIdProp && objectArray[0][objectIdProp]) {
            // Is an array of objects

            for (let i = 0; i < objectArray.length; i++) {
                const e = objectArray[i];
                if (e[objectIdProp] === event?.target?.value) {
                    newSelected = e;
                }
            }
        } else {
            // Is a simple array
            newSelected = event?.target?.value;
        }

        setValue(newSelected);
        const eventObject = {
            target: {
                name,
                value: newSelected,
            },
        };
        handleChange(eventObject);
    };

    useEffect(() => {
        if (
            defaultSelectedObject &&
            objectIdProp &&
            defaultSelectedObject[objectIdProp]
        ) {
            handleInternalChange({
                target: {
                    name,
                    value: defaultSelectedObject[objectIdProp],
                },
            } as any);
        } else if (defaultSelectedObject) {
            handleInternalChange({
                target: {
                    name,
                    value: defaultSelectedObject,
                },
            } as any);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSelectedObject]);

    return (
        <FormControl
            variant="outlined"
            fullWidth={fullWidth}
            className={classes}
        >
            <InputLabel id={"for-" + name}>{label}</InputLabel>
            <Select
                disabled={disabled}
                labelId={"for-" + name}
                label={label}
                value={objectIdProp ? value[objectIdProp] : value}
                name={name}
                onChange={(e) => {
                    handleInternalChange(e);
                }}
            >
                {objectArray.map((o, index) => (
                    <MenuItem
                        key={index}
                        value={objectIdProp ? o[objectIdProp] : o}
                        selected={
                            (objectIdProp ? o[objectIdProp] : o) ===
                            (objectIdProp ? value[objectIdProp] : value)
                        }
                    >
                        {objectValueProp ? o[objectValueProp] : o}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
