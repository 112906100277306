import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Challenge } from "../../../../models/challenge.model";
import { LoadingWrapper } from "../../../../components/LoadingWrapper/LoadingWrapper";
import { ChallengeData } from "./ChallengeData";
import {
    pageTransitions,
    pageVariants,
} from "../../../../animations/transitions";
import { motion } from "framer-motion";
import { ChallengeParticipant } from "../../../../models/challengeParticipant.model";
import { ParticipantStatusType } from "../../../../models/types";

export type Props = {
    loading: boolean;
    challenge: Challenge;
    isParticipant: boolean;
    currentParticipant: ChallengeParticipant;
    goToProgress: () => void;
    goToParticipants: () => void;
    entryBioData: () => void;
    handleJoin: () => void;
    handleQuit: () => void;
    contribute: () => void;
};

export const ParticipantOptions = ({
    loading,
    challenge,
    isParticipant,
    currentParticipant,
    goToProgress,
    goToParticipants,
    entryBioData,
    handleJoin,
    handleQuit,
    contribute,
}: Props) => {
    return (
        <motion.div
            initial="initial"
            exit="out"
            animate="in"
            transition={pageTransitions}
            variants={pageVariants}
        >
            <LoadingWrapper isLoading={loading} type="fullscreen">
                <Grid container direction="column" className={"mt-2"}>
                    <ChallengeData challenge={challenge} />

                    {currentParticipant.status ===
                    ParticipantStatusType.joined ? (
                        <Grid
                            item
                            className={"mt-2"}
                            container
                            spacing={2}
                            direction="column"
                        >
                            {challenge.companyId && <Grid item>
                                <Button
                                    fullWidth
                                    className="main-button"
                                    onClick={contribute}
                                >
                                    Contribute
                                </Button>
                            </Grid>}
                            <Grid item>
                                <Button
                                    fullWidth
                                    className="main-button"
                                    onClick={goToProgress}
                                >
                                    PROGRESS
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    className="secondary-button"
                                    onClick={goToParticipants}
                                >
                                    See Participants
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    className="secondary-button"
                                    onClick={entryBioData}
                                >
                                    Entry Data
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    className="secondary-button"
                                    onClick={handleQuit}
                                >
                                    QUIT Challenge
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            className={"mt-2"}
                            container
                            spacing={2}
                            direction="column"
                        >
                            <Grid item>
                                <Button
                                    fullWidth
                                    className="secondary-button"
                                    onClick={goToParticipants}
                                >
                                    SEE PARTICIPANTS
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    className="main-button"
                                    onClick={handleJoin}
                                >
                                    join
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </LoadingWrapper>
        </motion.div>
    );
};
