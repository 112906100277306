/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField } from "@material-ui/core";
import { LoadingWrapper } from "../../components/LoadingWrapper/LoadingWrapper";
import { pageTransitions, pageVariants } from "../../animations/transitions";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { A3DatePicker } from "../../components/DatePicker/DatePicker";
import { useForm } from "../../hooks/useForm";
import moment from "moment";
import { A3Select } from "../../components/A3Select/A3Select";
import { ChallengesApi } from "../../apis/challengesApi";
import { ChallengeCategory } from "../../models/challengeCategory.model";
import { ChallengeActivity } from "../../models/challengeActivity.model";
import { ChallengeCategoryTypes } from "../../models/types";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { A3Button } from "../../components/A3Button/A3Button";
import { modal } from "../../components/modal/modal";
import { IndividualActivity } from "../../models/individualActivity";
import { UsersApi } from "../../apis/userApi";
import store from "../../redux/store/store";
import { useHistory } from "react-router-dom";
import { routes } from "../../routers/routes";
import { A3AppBar } from "../../components/A3AppBar/A3AppBar";

export const AddActivityScreen = () => {
    const history = useHistory();
    const [isSaving, setIsSaving] = useState(false);
    const [categories, setCategories] = useState([]);
    const [activities, setActivities] = useState([]);
    const [units, setUnits] = useState([]);
    const initialValues = {
        date: moment().startOf("day").toDate(),
        category: new ChallengeCategory(),
        activity: new ChallengeActivity(),
        quantity: "",
        unit: "",
    };
    const goToLatestActivities = () => {
        history.push(routes.LIST_ACTIVITY)
    }
    const [values, handleInputChange, _, setValue] =
        useForm<typeof initialValues>(initialValues);
    const loadCategories = () => {
        const tempCats = ChallengesApi.getChallengesCatregories();
        const allowedCategories = ["fitness", "meditation", "reading", "yoga"]; //the ones we can
        const filteredCategories = tempCats.filter((c) =>
            allowedCategories.includes(c.id)
        );
        setCategories(filteredCategories);
    };
    const loadActivities = (categoryId: string) => {
        const tempActivities = ChallengesApi.getChallengesCategoriesActivities(
            categoryId as ChallengeCategoryTypes
        );

        setActivities(tempActivities);

        setValue("activity", tempActivities[0]);
    };

    const isFormValid = () => {
        console.log("checking: ", values);
        if (!values.date || !moment(values.date).isValid()) {
            modal("error", "Error", "Please select a valid date.");
            return false;
        }
        if (!values.category?.id) {
            modal("error", "Error", "Please select a category.");
            return false;
        }
        if (!values.activity?.id) {
            modal("error", "Error", "Please select a activity.");
            return false;
        }
        if (!values.quantity || Number(values.quantity) === 0) {
            modal("error", "Error", "Please input a valid quantity.");
            return false;
        }
        if (!values.unit) {
            modal("error", "Error", "Please select a valid unit.");
            return false;
        }

        return true;
    };

    const saveActivity = async () => {
        if (!isFormValid()) return;
        setIsSaving(true);

        const individualActivity = new IndividualActivity({
            date: new Date().getTime(),
            categoryId: values.category.id,
            activityId: values.activity.id,
            quantity: Number(Number(values.quantity).toFixed(2)),
            unit: values.unit,
        });
        const currentUser = store.getState().auth.user;
        await UsersApi.addIndividualActivity(
            currentUser.id,
            individualActivity
        );

        setIsSaving(false);

        history.replace(routes.MAIN.DASHBOARD);
    };

    useEffect(() => {
        if (!categories.length) loadCategories();
    }, []);

    useEffect(() => {
        if (values?.category?.id) {
            loadActivities(values?.category?.id);
        }
    }, [values.category?.id]);

    useEffect(() => {
        console.log("change on activity: ", values);
        setUnits(values.activity.units);
    }, [values.activity?.id]);

    return (
        <motion.div
            initial="initial"
            exit="out"
            animate="in"
            transition={pageTransitions}
            variants={pageVariants}
        >
            <LoadingWrapper type="fullscreen">
                <Grid container direction="column">
                    <NavigationBar title="Add Activity" />
                    <A3DatePicker
                        name="date"
                        openTo="date"
                        className="mt-3"
                        label="Date of the Activity"
                        date={values.date}
                        dateChange={handleInputChange}
                    />
                    <A3Select
                        fullWidth
                        name="category"
                        label="Select a Category"
                        classes="mt-2"
                        objectArray={categories}
                        objectIdProp="id"
                        objectValueProp="name"
                        defaultSelectedObject={values.category}
                        handleChange={handleInputChange}
                    />
                    <A3Select
                        fullWidth
                        name="activity"
                        label="Select a Activity"
                        classes="mt-2"
                        objectArray={activities}
                        objectIdProp="id"
                        objectValueProp="name"
                        defaultSelectedObject={values.activity}
                        handleChange={handleInputChange}
                    />
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={7}>
                            <TextField
                                type="number"
                                name={"quantity"}
                                value={values.quantity}
                                onChange={handleInputChange}
                                className={"mt-3"}
                                variant={"outlined"}
                                placeholder={"How many"}
                                label={"How Many"}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            {values.activity?.units?.length >= 1 && (
                                <A3Select
                                    name="unit"
                                    fullWidth
                                    label={"Unit"}
                                    classes="mt-3"
                                    objectArray={units}
                                    defaultSelectedObject={values.unit}
                                    handleChange={handleInputChange}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <A3Button
                        className="main-button mt-3"
                        isLoading={isSaving}
                        onClick={saveActivity}
                    >
                        Save
                    </A3Button>
                    <A3AppBar>
                        <>
                            <A3Button
                                fullWidth
                                onClick={goToLatestActivities}
                                className={"secondary-button mb-5"}
                            >
                                See list of latest Entries
                            </A3Button>
                        </>
                    </A3AppBar>
                </Grid>
            </LoadingWrapper>
        </motion.div>
    );
};
