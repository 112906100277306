import React, { MouseEventHandler } from 'react'
import { useStyles } from './DashboardCard.style'
import { Card, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import clsx from 'clsx'
interface Props {
    className?: string,
    title: string,
    footerActionText: string,
    cardIndicatorText: string,
    onClickHandler?: MouseEventHandler<HTMLDivElement>,

}

export const DashboardCard = ( { title, footerActionText, cardIndicatorText, onClickHandler, className }: Props ) => {
    const classes = useStyles()
    return (
        <Card className={clsx(classes.root, className)} onClick={( e ) => {
            onClickHandler && onClickHandler( e )
        }
        }>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                {cardIndicatorText ? (
                    <>
                        <Grid container item xs={8}>
                            <Typography className={classes.cardTitle}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={4}
                            justify="flex-end"
                            alignItems="flex-start"
                        >
                            <Typography className={classes.cardIndicator}>
                                {cardIndicatorText}
                            </Typography>
                        </Grid>
                    </>
                ) : (
                    <Grid container item xs={12} >
                        <Typography className={classes.cardTitle}>
                            {title}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.footer}

            >
                {footerActionText}
            </Grid>
        </Card>
    )
}
