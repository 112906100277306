import { Box, Grid, TextField, Typography } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { A3AppBar } from "../../components/A3AppBar/A3AppBar";
import { A3Button } from "../../components/A3Button/A3Button";
import { A3DatePicker } from "../../components/DatePicker/DatePicker";

import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { Emotion, JournalEntry } from "../../models/journalEntry.model";
import { useSaveJournalEntryMutation } from "../../redux/slices/journal/journal.slice";
import { useAppSelector } from "../../redux/store/store";
import { routes } from "../../routers/routes";
import { EmotionSelector } from "./components/EmotionSelector";

export const JournalNewEntry = () => {
    const history = useHistory();
    const currentEntry = useAppSelector((s) => s.biodata.entryInput);
    const [entryText, setTextEntry] = useState("");
    const [date, setDate] = useState<Date>(new Date());
    const [emotion, setEmotion] = useState(Emotion.normal);
    const [isSaving, setIsSaving] = useState(false);

    const [saveJournalEntry] = useSaveJournalEntryMutation()

    const handleChange = (e: any) => {
        setTextEntry(e.target.value);
    };
    const saveEntry = async (e: any) => {
        setIsSaving(true)
        const entry: Partial<JournalEntry> = {
            date: date.getTime(),
            emotion,
            entry: entryText,
        };
        await saveJournalEntry({entry}).unwrap()
        history.replace(routes.JOURNAL.VIEW);
        setIsSaving(false)
    };
    const handleDateChange = (data: any) => {
        const date = moment(data.target.value).toDate();
        setDate(date);
    };

    return (
        <Box pt={3}>
            <NavigationBar title="New Journal Entry" showBackButton />

            <Grid
                container
                direction="column"
                justify="flex-start"
                alignContent="stretch"
                className="mb-2"
            >
                <Typography variant="body2" align="center">
                    How are you?
                </Typography>
                <EmotionSelector
                    setEmotion={setEmotion}
                    emotion={emotion}
                    className={"mt-3"}
                />
                <A3DatePicker
                    date={date}
                    disableFuture
                    className="mt-3"
                    dateChange={handleDateChange}
                    name="date"
                    label="Entry Date"
                    views={["date"]}
                />
                <TextField
                    fullWidth
                    name={currentEntry.prop}
                    value={entryText}
                    label="Write something..."
                    onChange={handleChange}
                    onBlur={handleChange}
                    placeholder="Write something..."
                    variant="outlined"
                    className="mt-3 mb-14"
                    multiline
                    tabIndex={3}
                />
            </Grid>
            <A3AppBar>
                <>
                    <A3Button
                        isLoading={isSaving}
                        fullWidth
                        onClick={saveEntry}
                        className={"secondary-button mb-5"}
                    >
                        Save
                    </A3Button>
                </>
            </A3AppBar>
        </Box>
    );
};
