import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { IndividualActivity } from "../../../models/individualActivity";
import { ChallengesApi } from "../../../apis/challengesApi";
type Props = {
    index: number;
    individualActivity: IndividualActivity;
    deleteIndividualActivity: (individualActivity: IndividualActivity) => void;
    className?: string;
};

export const ActivityCard = ({
    index,
    individualActivity,
    deleteIndividualActivity,
    className,
}: Props) => {
    const deleteActivity = async () => {
        deleteIndividualActivity(individualActivity);
    };

    const activity = ChallengesApi.getActivity(
        individualActivity.categoryId,
        individualActivity.activityId
    );

    return (
        <Card
            className={clsx("curved mt-2", className)}
            style={{ maxHeight: "110px" }}
        >
            <Grid
                item
                container
                direction="row"
                justify={"space-between"}
                alignContent="flex-start"
                alignItems="flex-start"
            >
                <Grid
                    item
                    xs={10}
                    container
                    direction="column"
                    alignItems="flex-start"
                    justify="flex-start"
                >
                    <Grid container direction="row">
                        <Typography
                            variant={"subtitle1"}
                            align={"left"}
                        >
                            {moment(individualActivity.date).format(
                                "ddd, MMM DD YY"
                            )}
                        </Typography>
                    </Grid>

                    <Typography variant="body1" align={"left"} className="mt-1">
                        {activity.name}
                    </Typography>
                    <Typography variant="body2" align={"left"} className="mt-1">
                        {`${
                            individualActivity.quantity
                        } ${individualActivity.unit.replace("none", "days")}`}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={2}
                    container
                    direction="column"
                    justify="center"
                    className={"mt-1 p-2"}
                >
                    <Box position="relative" top={-24} left={16}>
                        <Typography
                            variant="body2"
                            onClick={() => {
                                deleteActivity();
                            }}
                        >
                            {<FontAwesomeIcon icon={faTrash} />}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};
