import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles( ( theme: Theme ) => ( {
    '@global': {
        '.MuiContainer-root ': {
            padding: theme.spacing(0)
        }
    },
   
} ) );
