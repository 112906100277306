import { User } from "./user.model";

export class PostComment {

    public id: string = '';
    public postId: string = '';
    public user: User = new User();
    public comment: string = '';
    public createdAt: Date = new Date();

    constructor( obj?: Partial<PostComment> ) {
        this.id = obj?.id || this.id
        this.postId = obj?.postId || this.postId
        this.user = obj?.user || this.user
        this.comment = obj?.comment || this.comment
        this.createdAt = obj?.createdAt || this.createdAt
        if ( typeof this.createdAt === 'string' ) this.createdAt = new Date( this.createdAt )
        
        
    }

    toPlainObject(): any {
        const plainObj: any = JSON.parse( JSON.stringify( this ) );
        return plainObj;
    }
}