import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './MotivationalPhrase.styles'
import { Logo } from '../../../../components/Logo/Logo';

export const MotivationalPhrase = () => {
    const classes = useStyles();



    return (
        <Grid
            container
            direction="column"
            justify="center"
            spacing={3}
        >
            <Grid container justify="center" direction="row">
                <Logo className={classes.logo + ' mt-8 mb-4'} />
            </Grid>
            <Typography variant={'h2'} align="center" className={classes.phrase} >
                Self-esteem comes from achieving something important, when it’s hard to do.
            </Typography>



        </Grid>
    )
}
